import { ArrowDownToLine, Calendar, ShoppingCart, Users2 } from "lucide-react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { dateFr, money } from "../../../global/global";

function ItemsBookingConfirmation({ items }) {
  const { t } = useTranslation();

  return (
    <>
      {items.map((item, key) => (
        <Form as="div" className="border rounded-3 p-3 my-3" key={key}>
          <Row className="align-items-start">
            <Col   xl="8">
              <Form as="div" className="text-dark">
          {item.bookingNumber==''
          ?    
          <Alert   variant={'danger'}>
          <Form as="p" className="fw-bold text-danger">
          {t('basket_not_confirme_pleas_try_again_later')}
          </Form>
          </Alert>            



          : <Form as="button" type="button" className="btn btn-success mx-3 cursor-pointer" onClick={() => alert(item.bookingNumber)}>
          <ArrowDownToLine size={20} /> {t("voucher")}
          </Form>
          }



                <Form as="div" className="fs-6 fw-bold mt-2">
                  {item.shoppingcart.service_name}
                </Form>
                <Form as="div" className="d-flex align-items-center">
                  <Form as="span"></Form>
                  <Form as="span" className="mx-2 d-flex">
                    {t(item.shoppingcart.service)}
                  </Form>
                  <Form as="span"></Form>
                </Form>

                <Form as="div" className="mt-3 fw-medium">
                  

                  <Form as="div" className="d-flex align-items-center my-2">
                    {item.bookingNumber==''
                    ? 
                    <>
                    <ShoppingCart size={15} />
                    <Form as="span" className="mx-2 fw-bold">
                      {item.shoppingcart.shoppingcartNumber}
                    </Form>
                    </>
                    : <>
                    N° confirmation:
                    <Form as="span" className="mx-2 fw-bold">
                      {item.bookingNumber}
                    </Form>
                    </>
                    }



                    
                  </Form>
                  <Form as="div" className="d-flex align-items-center my-2">
                    <Users2 size={15} />
                    <Form as="span" className="mx-2">
                      {" "}
                      {item.shoppingcart.holder}
                    </Form>
                  </Form>
                  <Form as="div" className="d-flex align-items-center my-2">
                    <Calendar size={15} />
                    <Form as="span" className="mx-2">
                      {dateFr(item.shoppingcart.checkin)} - {dateFr(item.shoppingcart.checkout)}
                    </Form>
                  </Form>
                </Form>
                <Form as="div" className="d-flex justify-content-between align-items-center mt-3">
                  
                  <Form as="div" className="fs-6">
                    {t("price")} : {money(item.shoppingcart.amount)} {item.shoppingcart.currency}
                  </Form>
                </Form>
              </Form>
            </Col>
          </Row>
        </Form>
      ))}
    </>
  );
}

export default ItemsBookingConfirmation;
 