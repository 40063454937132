import React from "react";
import { Col, Row,Form } from "react-bootstrap";


const ImageItem = () => {

      
   
  return (
    <Row>
    <Col lg="6">
      <Row>
        <Col lg="12" className="my-3">
          <Form as="img"
            width="100%"
            height="100%"
            src="https://travila.netlify.app/assets/imgs/page/homepage5/media6.png"
            alt=""
          />
        </Col>
        <Col lg="12" className="my-3">
          <Form as="img"
            width="100%"
            height="100%"
            src="https://travila.netlify.app/assets/imgs/page/homepage5/media3.png"
            alt=""
          />
        </Col>
      </Row>
    </Col>
    <Col lg="6" className="my-3">
      <Form as="img"
        width="100%"
        height="100%"
        src="https://travila.netlify.app/assets/imgs/page/homepage1/pay1.png"
        alt=""
      />
    </Col>
  </Row>
  );
};

export default ImageItem;
