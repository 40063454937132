import React, { useState } from "react";
import { LayoutGrid, } from "lucide-react";
import { Col, Row, Form } from "react-bootstrap";
import ImagesCarousel from "../../../components/ImagesCarousel";
import { useTranslation } from "react-i18next";
import MainInfos from "../../../components/MainInfos";

const addDefaultImg = ev => {
  ev.target.src = process.env.REACT_APP_IMAGE_DEFAULT
}
var list = [];
function Album(props) {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  list = props.images;
  var im1 = Math.floor(Math.random() * list.length);
  var im2 = Math.floor(Math.random() * list.length);
  var im3 = Math.floor(Math.random() * list.length);
  var im4 = Math.floor(Math.random() * list.length);
  var im5 = Math.floor(Math.random() * list.length);
  var im6 = Math.floor(Math.random() * list.length);
  var im7 = Math.floor(Math.random() * list.length);
  var im8 = Math.floor(Math.random() * list.length);
  var im9 = Math.floor(Math.random() * list.length);
  var im10 = Math.floor(Math.random() * list.length);
  var im11 = Math.floor(Math.random() * list.length);

  const { t } = useTranslation();

  return (
    <>
      <ImagesCarousel showModal={showModal} closeModal={closeModal} images={list} />

      <Row>
        <Col lg="9" className="my-3">
          <Form as="div" role="button" className="position-relative rounded-5 h-100" onClick={openModal}>
            <Row className="justify-content-between" style={{ rowGap: "10px" }}>
              <Col md="3" className="px-1 d-none d-md-block">
                <Row style={{ rowGap: "10px" }}>
                  <Col md="12">
                    <img alt="Illustration d'un hôtel 1" width="100%" height="100%" style={{ minHeight: "175px", maxHeight: "175px", objectFit: "cover" }} src={list[im1]} onError={addDefaultImg} />
                  </Col>
                  <Col md="12">
                    <img alt="Illustration d'un hôtel 1" width="100%" height="100%" style={{ minHeight: "175px", maxHeight: "175px", objectFit: "cover" }} src={list[im2]} onError={addDefaultImg} />
                  </Col>
                </Row>
              </Col>


              <Col md="3" className="px-1 d-none d-md-block">
                <Row style={{ rowGap: "10px" }}>
                  <Col md="12">
                    <img alt="Illustration d'un hôtel 1" width="100%" height="100%" style={{ minHeight: "175px", maxHeight: "175px", objectFit: "cover" }} src={list[im4]} onError={addDefaultImg} />
                  </Col>
                  <Col md="12">
                    <img alt="Illustration d'un hôtel 1" width="100%" height="100%" style={{ minHeight: "175px", maxHeight: "175px", objectFit: "cover" }} src={list[im5]} onError={addDefaultImg} />
                  </Col>
                </Row>
              </Col>

              <Col md="6" className="px-1">
                <img width="100%" height="100%" alt={props.name} src={list[im3]} style={{ minHeight: "360px", maxHeight: "360px" }} />
              </Col>


              <Col md="12" className="d-none d-md-block">
                <Row style={{ rowGap: "10px" }}>
                  <Col md="2" className="px-1">
                    <img alt="Illustration d'un hôtel 1" width="100%" height="100%" style={{ minHeight: "110px", maxHeight: "110px", objectFit: "cover" }} src={list[im6]} onError={addDefaultImg} />
                  </Col>
                  <Col md="2" className="px-1">
                    <img alt="Illustration d'un hôtel 1" width="100%" height="100%" style={{ minHeight: "110px", maxHeight: "110px", objectFit: "cover" }} src={list[im7]} onError={addDefaultImg} />
                  </Col>
                  <Col md="2" className="px-1">
                    <img alt="Illustration d'un hôtel 1" width="100%" height="100%" style={{ minHeight: "110px", maxHeight: "110px", objectFit: "cover" }} src={list[im8]} onError={addDefaultImg} />
                  </Col>
                  <Col md="2" className="px-1">
                    <img alt="Illustration d'un hôtel 1" width="100%" height="100%" style={{ minHeight: "110px", maxHeight: "110px", objectFit: "cover" }} src={list[im9]} onError={addDefaultImg} />
                  </Col>

                  <Col md="2" className="px-1">
                    <img alt="Illustration d'un hôtel 1" width="100%" height="100%" style={{ minHeight: "105px", maxHeight: "105px", objectFit: "cover" }} src={list[im10]} onError={addDefaultImg} />
                  </Col>
                  <Col md="2" className="px-1">
                    <img alt="Illustration d'un hôtel 1" width="100%" height="100%" style={{ minHeight: "110px", maxHeight: "110px", objectFit: "cover" }} src={list[im11]} onError={addDefaultImg} />
                  </Col>
                </Row>
              </Col>

            </Row>
            <Form as="div" role="button" className="d-flex align-items-center position-absolute text-white rounded-5 py-3 px-3 border border-white" style={{ bottom: "30px", right: "30px", background: "#00000036" }}>
              <LayoutGrid strokeWidth={1} size={20} />
              <Form as="span" className="mx-1">
                {t("all_the")} {list.length} {t("photos")}
              </Form>
            </Form>
          </Form>
        </Col>
        <Col lg="3" className="my-3">
         <MainInfos city={props.activity.City} country={props.activity.Country}/>
        </Col>
      </Row>

    </>
  );
}

export default Album;
