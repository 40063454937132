import { useTranslation } from "react-i18next";
 
import { Alert, Form, Placeholder } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Error } from "../../../global/global";
import IController from "../../../components/forms/IController";
import { getFindBooking } from "../Actions";
import { expEmail } from "../../../configs/constants";
 ;
 

const FindBooking = () => {
    const { t } = useTranslation();
    const [errorCheck, setErrorCheck] = useState('');
    const [loading, setLoading] = useState(false);
    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
      } = useForm({});
    
      const onFindBooking = async (data) => {
        setLoading(true)
        const res = await getFindBooking(data)
       
       if (res?.data?.status == true  ) {
          var variant ='danger'
          if(res?.data?.result.found>0){
            variant ='success'
          }

          setErrorCheck( <Alert   variant={variant}>
        <Form as="p" className="m-2">
           {res?.data?.result.message }
        </Form>
      </Alert>)
          
        } else {
          setErrorCheck(
            <Alert   variant={"danger"}>
              <Form as="p" className="m-2">
              server error 500
              </Form>
            </Alert>
          )
        } 

        setLoading(false)
      
    };


    var item = {
        type: "text",
        placeholder:t('number_of_reservation'),
        className: "input-blue w-100 input-passenger border rounded",
        size: "md",
        rules: {
          required: t("mandatory"),
        },
      };

      var itemEmail = {
        type: "text",
        placeholder:t('email'),
        className: "input-blue w-100 input-passenger border rounded",
        size: "md",
        rules: {
            required: t('mandatory'),
            pattern: {
                value: expEmail,
                message: t('invalid_format'),
            }
        },
      };

      
    return (

        <Form onSubmit={handleSubmit(onFindBooking)}>
            <Form as="fieldset" className="p-3 d-flex flex-column">
            <Form as="ul" className="nav nav-pills m-3"  ></Form>
                <Form as="legend" className="fw-bold text-dark">{t('go_to_my_reservation')}</Form>
                <Form as="div" className="mt-3">
                    <Form as="div" className="my-3">
                       

                        <IController elment={`bookingNumber`} item={item} control={control} errors={errors} register={register} />
                        <Error index={'bookingNumber'} attr="bookingNumber" title='number_of_reservation' errors={errors}/>

                    </Form>
                    <Form as="div" className="my-3">
                        <IController elment={`email`} item={itemEmail} control={control} errors={errors} register={register} />
                        <Error index={'email'} attr="email" title='email' errors={errors}/>

                    </Form>
                    <Form as="div" className="my-3">
                    <Form as="small">{t('the_number_provided_in_your_confirmation_email')}</Form>
                    </Form>
                </Form>
                <Form as="button" type="submit" className="btn-blue p-4 rounded-5 fw-bold">
                {loading ? <Form as="span" className="spinner-border spinner-border-sm m-1"></Form> : t('search')  }  
                   
                    
                    
                    </Form>
                <Form as="div" className="m-3">
          {errorCheck}
            </Form>
            </Form>
        </Form>
    )
}

export default FindBooking