import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
 
import profileReducer from "./reducers/profileReducer"
import hotelReducer from "./reducers/hotelReducer"
import activitiesReducer from "./reducers/activitiesReducer"
import packageReducer from "./reducers/packageReducer"
import flightReducer from "./reducers/flightReducer"
import transferReducer from "./reducers/transferReducer"
import flightandaccommodationsReducer from "./reducers/flightandaccommodationsReducer"
const persistConfig = {
    key: "root",
    whitelist: [
            'profileIdhtours',
            'hotelIdhtours28062024',
            'activityIdhtours28062024',
            'packagesIdhtours28062024',
            'flightsIreducer28062024',
            'transferIdhtours28062024',
            'flightandaccommodationsReducer28062024'
        ],
    storage,
};
//REACT_APP_VERSION
const rootReducer = combineReducers({
        profileIdhtours: profileReducer,
        hotelIdhtours28062024: hotelReducer, 
        activityIdhtours28062024: activitiesReducer,
        packagesIdhtours28062024: packageReducer,
        flightsIreducer28062024: flightReducer,
        transferIdhtours28062024: transferReducer,
        flightandaccommodationsReducer28062024: flightandaccommodationsReducer
});
 
const appReducer = (state, action) => {
    if (action.type === "LOGOUT") {
        storage.removeItem(process.env.REACT_APP_API_BASE_URL + ":state");
        return rootReducer(undefined, action);
    }
    return rootReducer(state, action);
};   
const persistedReducer = persistReducer(persistConfig, appReducer);
export default persistedReducer;
