import { CalendarCheck2, MapPin, Trash2, Users2 } from "lucide-react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { dateFr, money } from "../../../global/global";
import { getShoppingCarDeleted } from "../Actions";
import { Popconfirm } from 'antd';

function ItemShoppingCart({ items }) {

  const { t } = useTranslation();

  return (
    <>

      {items.map((item, key) => (
        <Form as="div" className="border p-3 my-3 bg-white" key={key}>
          <Row className="align-items-start">
            <Col sm="3">
              <img width="100%" height="100%" alt={`Illustration du produit - ${item.service_name}`} src="https://placehold.co/400" />
            </Col>
            <Col sm="9">
              <Form as="div" className="text-dark p-3">
                <div className="d-flex justify-content-between">
                  <span>
                    <Form as="div" className="fs-4 fw-medium text-capitalize">
                      {item.service_name}
                    </Form>
                    <Form as="span" className="text-green fw-light">
                      {t(item.service)}
                    </Form>
                  </span>
                  <span>
                    <Popconfirm
                      title="Delete the task"
                      description="Are you sure to delete this task?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => getShoppingCarDeleted(item.id)}
                    >
                      <Form as="div" className="text-danger cursor-pointer">
                        <Trash2 size={20} strokeWidth={1.5} />
                      </Form>
                    </Popconfirm>

                  </span>
                </div>

                <div className="d-flex justify-content-between align-items-end mt-3">
                  <Form as="span" className="fw-medium">
                    <Form as="div" className="d-flex align-items-center my-2">
                      <Form as="span" className="text-blue">
                        <MapPin size={15} />
                      </Form>

                      <Form as="span" className="mx-2 fw-light">
                        {item.country}
                      </Form>
                    </Form>



                    <Form as="div" className="d-flex align-items-center my-2">
                      <Form as="span" className="text-blue">
                        <Users2 size={15} />
                      </Form>

                      <Form as="span" className="mx-2 fw-light">
                        {item.holder}
                      </Form>
                    </Form>

                    <Form as="div" className="d-flex align-items-center my-2">
                      <Form as="span" className="text-blue">
                        <CalendarCheck2 size={15} />
                      </Form>

                      <Form as="span" className="mx-2 fw-light">
                        {dateFr(item.checkin)} - {dateFr(item.checkout)}
                      </Form>
                    </Form>
                  </Form>

                  <Form as="span" className="fs-4">
                    {money(item.amount_total)} {item.currency}
                  </Form>
                </div>
              </Form>
            </Col>
          </Row>
        </Form>
      ))}
    </>
  );
}

export default ItemShoppingCart;
