import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Descriptif(props) {

  const { t } = useTranslation();
  
  var items = [];
  Object.keys(props.descriptions).forEach((key) => {
    items.push(
      <Form as="div" key={key} className="my-3">
        <Form as="p" dangerouslySetInnerHTML={{ __html: props.descriptions[key] }}></Form>
      </Form>
    );
  });

  return (
    <Form as="div" className="my-3">
      <div className="text-dark fs-3 fw-medium">{t('about_this_hotel')}</div>
      <Form as="div" className="my-3 fs-6 fw-light">
        {items}
      </Form>
    </Form>
  );
}

export default Descriptif;
