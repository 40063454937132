import React, { useState } from 'react'
import TopBanner from '../../components/TopBanner'
import { useParams } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import { Panel, PanelGroup } from 'rsuite'; 
import Loader from "../../components/Loader";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { getdetail } from "./Actions";
import OrderSummary from './views/OrderSummary';
import Trip from './views/Trip';
import CancelPolicy from '../../components/model/CancelPolicy';
import { useTranslation } from 'react-i18next';
const queryClient = new QueryClient();

const Details = () => {

  return (
    <QueryClientProvider client={queryClient}>
      <LoadDetails  />
      </QueryClientProvider>
 )

}

const LoadDetails = () => {
  const searchToken = useParams().searchToken;
  const rateKey = useParams().rateKey;
  const [isBook, setIsBook] = useState(false);  
  const { t } = useTranslation();
  const { isLoading, error, data } = useQuery("repoData", () => getdetail(searchToken,rateKey)); 
  if (isLoading) return <Loader />;
  if (error) return <LoadError />;
 
  if (data?.data?.status != true) {
  return <LoadError />;
    }
    const onCheckBook  = ({ target: { checked } }) => {
      setIsBook(checked);
    };

    const getFormBooking = () => {
      var url=['/transfers','book',searchToken,rateKey]
      window.location=url.join('/')
   
    }
    const items =  data?.data?.result;

    return(
      <Form as="section" >
        <TopBanner data={items['transfer']['Trip'][0]['From']+' - '+items['transfer']['Trip'][0]['To']} title={''} cover="" />
        <Form as="div" className="container-xl p-3">
          <Row className="my-3">
            <Col lg="8">
            <PanelGroup
              className="bg-white border rounded-0 my-3"
              accordion
              defaultActiveKey={1}
            >
                    {items?.transfer?.Trip?.map((item, index) => (
                          <Form as="div" className="my-3" key={index}>
                          <Panel header={item.Type} eventKey={index + 1}>
                          <Trip item={item}  />
                          </Panel>
                          </Form>


                    ))}

              </PanelGroup>

              <CancelPolicy items={items.cancellationPolicy} />
              <Form as="div"  id="continue" className="rounded-4 p-3"  >
               <input  onChange={onCheckBook} type="checkbox" id="accepte_condition_rate" className="p-3" />
               <label htmlFor="accepte_condition_rate"  className="p-3 fs-6 fw-medium text-dark"> {t('accepte_condition_rate')}</label>
               <br/>
             
               {!isBook && (
                     <button className="btn-gray px-5 p-3 rounded-5" disabled type="button">{t('reserve')}</button>
                
                    )}


               {isBook && (
                        <button className="btn-blue px-5 p-3 rounded-5" onClick={getFormBooking}  type="button">{t('reserve')}</button>
                
                    )}

              </Form>  

            </Col>
            <Col lg="4">
            <OrderSummary items={items}/>
            <CancelPolicy items={items.cancellationPolicy} />
            </Col>
          </Row>

        </Form>
      </Form>
    )
}

function LoadError() {



}
export default Details


