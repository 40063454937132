import React, { useState } from "react";
import { time_hour,dateFr } from "../../../global/global";
import { useTranslation } from "react-i18next";
import { Col, Row, Form } from "react-bootstrap";
import { airline } from "../../../data/airline";
import {
    BaggageClaim,
  Briefcase,
  Circle,
  Clock,
  Luggage,
  Plane,
  PlaneLanding,
  PlaneTakeoff,
} from "lucide-react";
import { Steps } from "rsuite";

function Itinerary({ itinerary, flightPost,index, baggages, cabinBaggages }) {
  const [details, setDEtails] = useState(false);

  const toggleDEtails = () => {
    setDEtails(!details);
  };

  const { t } = useTranslation();

  const styles = {
    display: "inline-table",
    verticalAlign: "top",
  };



  const lastItem = itinerary.Segments[itinerary.Segments.length - 1];

  const [infos, setInfos] = useState({
    departureTime:time_hour(itinerary.Segments[0].DepartureDateTime),
    arrivalTime:itinerary.Segments[1] ? time_hour(lastItem.ArrivalDateTime) : time_hour(itinerary.Segments[0].ArrivalDateTime),
    departCode:itinerary.Segments[0].DepartureAirportCode,
    arrivalCode:itinerary.Segments[1] ? lastItem.ArrivalAirportCode : itinerary.Segments[0].ArrivalAirportCode,
    flightDate:dateFr(itinerary.Segments[0].DepartureDateTime)

  })

  return (
    <>
      <Form as="div" className="border p-3 bg-light">
        <Row>
          <Col lg="4" xl="4" className="my-1">
            <div className="d-flex fw-medium text-capitalize fs-6">
              <Form as="span" className="text-blue">
                <PlaneTakeoff strokeWidth={1.5} />
              </Form>

              <div className="mx-3">
                <Form as="div" className="fs-6">
                  {t("departure")}
                </Form>
                <Form as="p" className="fw-light">
                  {flightPost["destination"][index]["Departure"]}
                </Form>
                <Form as="div" className="fw-light text-blue">
                 {infos.departureTime}
                </Form>
              </div>
            </div>
          </Col>

          <Col lg="4" xl="4" className="my-1">
            <div className="d-flex fw-medium text-capitalize fs-6">
              <Form as="span" className="text-blue">
                <PlaneLanding strokeWidth={1.5} />
              </Form>

              <div className="mx-3">
                <Form as="div" className="fs-6">
                  {t("arrival")}
                </Form>
                <Form as="p" className="fw-light">
                  {flightPost["destination"][index]["Arrival"]}
                </Form>
                <Form as="div" className="fw-light text-blue">
                  {infos.arrivalTime}
                </Form>
              </div>
            </div>
          </Col>

          <Col lg="4" className="my-1">
            <div className="d-flex fw-medium fs-6">
              <Form as="span" className="text-blue">
                <Clock strokeWidth={1.5} />
              </Form>

              <div className="mx-3 d-flex flex-column">
                <Form as="div">
                  {t("travel_time")}
                </Form>
                <Form as="div" className="fw-light">
                  {infos.departCode} -
                  {infos.arrivalCode}
                </Form>
                <Form as="div" className="fw-light">
                  {infos.flightDate}  
                </Form>
                <Form as="div" className="fw-light text-blue">
                  {itinerary.Duration}
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Form>

      <Form as="div" className={`extra-details ${details ? "active" : ""}`}>
        {itinerary.Segments.map((segment, index) => (
          <Form
            className="p-3 py-4 border-bottom"
            as="div"
            key={"segment-" + index}
          >
            <Row>
              <Col xl="8">
                
              </Col>
            </Row>
            <>
              <Steps vertical style={styles}>
                <Steps.Item
                  icon={
                    <div className="text-blue">
                      <Circle size={10} fill="#3B71FE" />
                    </div>
                  }
                  title={
                    <Form as="div" className="fs-5 text-dark">
                      {time_hour(segment.DepartureDateTime)}
                    </Form>
                  }
                  description={
                    <Form
                      as="div"
                      className="fw-light fs-6 text-capitalize text-dark"
                    >
                      {segment.DepartureAirportName} - {segment.DepartureAirportCode}
                    </Form>
                  }
                />

                <Steps.Item
                  className="d-flex"
                  icon={
                    <Form
                          as="span"
                          className={
                            "airline-image airline-" + segment.Operator
                          }
                        ></Form>
                  }
                  title={
                    <Form as="div" className="fs-5 text-dark text-capitalize">
                      {airline(segment.Operator)}
                    </Form>
                  }
                  description={
                    <>
                      <Form
                        as="div"
                        className="d-flex flex-wrap align-items-center fw-light fs-6 text-dark"
                      >
                        <Form as="span" className="d-flex align-items-center">
                            <Form as="span" className="text-blue mx-1"><Plane size={20} strokeWidth={1.5}/></Form> 
                            <Form as="span" className="mx-1">{t('flight_number')} :</Form> 
                            <Form as="span" className="mx-1">{segment.FlightNumber}</Form> 
                        </Form>
                        <span className="mx-2">|</span>
                        <Form as="span" className="d-flex align-items-center">
                            <Form as="span" className="text-blue mx-1"><Briefcase size={20} strokeWidth={1.5}/></Form> 
                            <Form as="span" className="mx-1">{t('booking_class')} :</Form> 
                            <Form as="span" className="mx-1">{segment.BookingClass}</Form> 
                        </Form>
                        <span className="mx-2">|</span>
                        <Form as="span" className="d-flex align-items-center">
                            <Form as="span" className="text-blue mx-1"><Clock size={20} strokeWidth={1.5}/></Form> 
                            <Form as="span" className="mx-1">{t('travel_time')} :</Form> 
                            <Form as="span" className="mx-1">{segment.Duration}</Form> 
                        </Form>
                      </Form>
                      <Form
                        as="div"
                        className="fw-light fs-6 text-dark my-3"
                      >
                        <span className="text-blue"><BaggageClaim size={20} strokeWidth={1.5}/></span><span className="mx-2">{t('checked_luggage')} x {baggages[segment.FlightNumber]["Pax"][0]["Quantity"]}</span>
                      </Form>

                      {cabinBaggages && (<Form
                        as="div"
                        className="fw-light fs-6 text-dark my-3"
                      >
                        <span className="text-blue"><Luggage size={20} strokeWidth={1.5}/></span><span className="mx-2">{t('cabin_luggage')} x {cabinBaggages[segment.FlightNumber]["Pax"][0]["Quantity"]}</span>
                      </Form>)}
                    </>
                  }
                />

                <Steps.Item
                  icon={
                    <div className="text-blue">
                      <Circle size={10} fill="#3B71FE" />
                    </div>
                  }
                  title={
                    <Form as="div" className="fs-5 text-dark">
                      {time_hour(segment.ArrivalDateTime)}
                    </Form>
                  }
                  description={
                    <Form
                      as="div"
                      className="fw-light fs-6 text-capitalize text-dark"
                    >
                      {segment.ArrivalAirportName} -{" "}
                      {segment.ArrivalAirportCode}
                    </Form>
                  }
                />
              </Steps>
            </>
          </Form>
        ))}
      </Form>
      <Form as="div"
        role="button"
        className="p-3 w-100 text-center fs-6 fw-light text-blue bg-secondary bg-opacity-10"
        onClick={toggleDEtails}
      >
        {details ? t("see_less") : t("see_more")}
      </Form>
    </>
  );
}

export default Itinerary;
