import { MapPin } from 'lucide-react'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ItemCity from './ItemCity'
import FilterMap from './FilterMap';

const MainInfos = (props) => {

    return (
        <Row className='justify-content-between h-100' style={{ rowGap: "10px" }}>
            <Col xs="12">
                <div className="border p-3 d-flex align-items-center fs-6 fw-light">
                    <span className="text-blue"><MapPin size={20} strokeWidth={1.5} /></span> <span className="mx-1">{props.city}, {props.country}</span>
                </div>
            </Col>
            <Col lg="12">
                <FilterMap />
            </Col>
            <Col lg="12">
                <ItemCity city={props.city} />
            </Col>
        </Row>
    )
}

export default MainInfos
