import React from "react";
import { Form } from "react-bootstrap";

const Loader = () => {
  return (
    <Form as="div" className="loading-container position-fixed top-0 left-0 w-100 h-100 bg-white d-flex justify-content-center align-items-center" style={{ zIndex: "9999" }}>
    <Form as="span" className="loading-component"></Form>
    </Form> 
  );
};

export default Loader;
