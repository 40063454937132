import Failed from './Failed'
import Success from './Success';

function Main(props) {
    const composite = {
        fail: <Failed />,
        success: <Success />,
    };

    return (
        <>{composite[props.component]}</>

    );

}
export default Main;