import React from 'react';
import GoogleMapReact from 'google-map-react';
import {  MapPin, Star } from 'lucide-react';
import { Form } from 'react-bootstrap';
import { Popover, Whisper  } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { send } from '../../global/axios';
import { stringify } from 'querystring';



const addDefaultImg = ev => {
ev.target.src = process.env.REACT_APP_IMAGE_DEFAULT
}
const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Hotels = ({items,center,config}) => {
    const { t } = useTranslation();
    const defaultProps = {
    center: center,
    zoom: 8.5
    };

//     
        var mapComponent=[]
        for (const [key, item] of Object.entries(items)) {
            mapComponent.push(
            <AnyReactComponent
            lat={item.latitude}
            lng={item.longitude}
            key={key}
            text={
              <Whisper
              trigger="click"
              placement={'auto'}
              controlId={`${item.id}`}
              speaker={
              <Popover   className='p-0'  style={{ borderRadius: "8px" }}>
                <Form as="a"  onClick={() => getRooms(item.id, config.searchToken)} href={`/hotels/${item.country_name}/${item.city_name}/${item.name}/${item.id}/${config.searchToken}?${stringify(config.dataPost)}&note=${item.note}`} className="text-dark text-decoration-none" target="_blank" rel="noreferrer">
                  <Form as="div" className="card-sl">
                    <Form as="div" className="card-image">
                      <Form as="img"
                      src={item.image}
                      className="recommendations-item-img"
                      width="100%"
                      height="190"
                      alt={`${item.name}`}
                      onError={addDefaultImg}
                      />
                    </Form>
                    <Form as="div" className="card-action" href="#">
                      {Array.from({ length: item.category }, (_, index) => (
                      <Star className="me-1" color="#FFB21D" fill="#FFB21D" size={15} key={index} />
                      ))}
                    </Form>
                    <Form as="div" className="card-heading">
                     {item.name}
                    </Form>
                    <Form as="div" className="card-text">
                      <MapPin strokeWidth={1} size={20} />
                      {item.city_name}, {item.country_name}
                    </Form>
                    <Form as="div"  className="d-flex align-items-center p-2">
                        <Form as="span"  className="fw-bold text-blue p-1 rounded border-blue">
                          {item.trip_advisor_rating} / 5
                          </Form> 
                        <Form as="span"  className="mx-2">
                          ( {item.trip_advisor_review_count ? item.trip_advisor_review_count : 20} {t("reviews")})
                        </Form> 
                         <img width={100} height={14} src={item.trip_advisor_rating_image} alt="trip_advisor_rating_image" />
                    </Form> 
                      <Form as="div" href="#" className="card-button"> 
                        <Form as="span"  className="fw-bold text-dark mx-1 text-white" style={{ fontSize: "20px" }}>
                          {item.amount} {item.currency}
                        </Form>
                      </Form>
                    </Form>
                </Form>
              </Popover>
            }
            >
            <MapPin size={30} color="#3B71FE" strokeWidth={3}  style={{ cursor: "pointer" }}/>
            </Whisper>
            }

            /> 
            )
        }

      return (
 
      <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY}}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
     options={
         {
          gestureHandling: "greedy",
          mapTypeControl: !1,
          zoomControl: !1,          
          scaleControl: !1,
          streetViewControl: !1,
          fullscreenControl: 1 
         
         }
      }
       
      >
      {mapComponent}
      </GoogleMapReact>
     
      );

      };

export default Hotels;


export const  getRooms = async (hotelid,searchToken)=>{
return await send({ service: "hotel", action: "rooms", data: {}, urlAppend: [hotelid, searchToken]  });
}
