import { NotificationContainer } from "react-notifications";
import './App.css';
import "rsuite/dist/rsuite.min.css";
import Header from './layout/Header'
import Footer from './layout/Footer'
import BackTop from './layout/BackTop'
import RouteComponents from "./routeComponents";
import { Session } from "./redux/session";

function App() {
  Session();
  return (
    <div className="main">
      <div className="loader" id="loader"></div> 
      <Header />
      <BackTop />
      <div className="app" id="app-route"><RouteComponents/></div>
      <Footer />
      <NotificationContainer />
      <section id="idhmodal"></section>
     
    </div>
  );
}

export default App;
