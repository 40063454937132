import Availability from "./forms/Availability";
import { useForm } from "react-hook-form";
import BookingForm from "./BookingForm";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Recap from "./views/Recap";
import CancelPolicy from "../../components/model/CancelPolicy";
import RateComment from "../../components/model/RateComment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MoveLeft, ShoppingCart } from "lucide-react";
import { addToPanier,getBook } from "./Actions";
import Loader from "../../components/Loader";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
const queryClient = new QueryClient()

function Book() {
  const { t } = useTranslation();
  return(
    <Form as="section">
      <div className="bg-dark-blue py-3">
        <Availability />
      </div>
      <Form as="div"  className="my-5 container">
        <QueryClientProvider client={queryClient}>
           <LoadBook/>
        </QueryClientProvider>
      </Form>
    </Form>
    )


}

function LoadBook() {
  const navigate = useNavigate();
  const { t } = useTranslation(); 
  const param = useSelector((state) => state.hotelIdhtours28062024);
  const hotelId = useParams().hotelId;
  const hotelName = useParams().hotelName;
  const searchToken = useParams().searchToken;
  const rateKey = useParams().rateKey;
 

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({});


  const { isLoading, error, data } = useQuery('repoData', () => getBook(hotelId, searchToken, rateKey))

  if (isLoading)    return <Loader />;
  if (error) return    <ErrorCheckRate  />
  if (data?.data?.status != true)  return <ErrorCheckRate  />
  var item = data.data.result 
  if (item.error)  return <ErrorCheckRate />

 
  const onSubmit = async (data) => {
     await addToPanier(data,hotelId,searchToken,rateKey)
   
};
 
  const ctrl = {
    control: control,
    register: register,
    errors: errors,
  };

  return (
   
        <Row>
          <Col lg="7" xl="8">
            <>
              <fieldset>
              <Form onSubmit={handleSubmit(onSubmit)}>
                  <BookingForm roomPaxes={item.roomPaxes} rooms={item.rooms} ctrl={ctrl} />
                  <RateComment items={item.rateComment} title="property_conditions" />
                  <RateComment items={item.extraExcluded} title={t("taxe_to_be_payed")} />
                  <CancelPolicy items={item.cancellationPolicy} />
                  <Form as="div" className="mb-3">
                    <Button className="btn-blue p-3 px-4 rounded-5 mt-3 fs-6 text-white" type="submit">
                      <ShoppingCart /> {t("add_to_panier")}
                    </Button>
                  </Form>
                </Form>
              </fieldset>
            </>
          </Col>
          <Col lg="5" xl="4">
            <Recap hotel={item.hotel} rooms={item.rooms} price={item.totalAmount} param={param} extraIncluded={item.extraIncluded} />
          </Col>
        </Row>
     
  );
}
export default Book;

function ErrorCheckRate( ) {
  const { t } = useTranslation();

  return (
      <>
      <Form as="a"  href="true" className="btn-blue p-2 rounded-5 mt-3 fs-6 text-white" type="button">
      <MoveLeft /> {t("back")}
      </Form> 
      <hr className="border-secondary" />
      <h2 className="text-dark">Oops !</h2>
      <Form as="p"  className="fs-5 fw-light">Les chambres demandées ne sont plus disponible.</Form> 
      </>
  );
}
