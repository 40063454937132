import React from 'react';
import { isValidUrl } from '../../../global/global'
import { useTranslation } from "react-i18next";
import { Form } from 'react-bootstrap';
import { Frown } from 'lucide-react';
function TripAdvisor(props) {
    const { t } = useTranslation();
    if (isValidUrl(props.url) == false) {

        return (

            <Form as="div" className="container p-5 mt-5">
      <Form as="div" className="hero">
        <Form as="div" className="px-4 py-5 my-5 text-center text-dark">
          <Form as="div" className="fs-1 fw-bold">
            <Frown size={50} strokeWidth={1.5} />
            <Form as="span" className="mx-1">{t("no_results")}</Form>
          </Form>
          
        </Form>
      </Form>
    </Form>
        )

    }
    return (
        <>
            <iframe src={props.url} width="100%" height="600" frameborder="0" ></iframe>
        </>)
}


export default TripAdvisor;