import { PlaneLanding, PlaneTakeoff, Search, Trash, Trash2, X } from "lucide-react";
import { Row, Col, Form } from "react-bootstrap";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DateOneInput from "../../../components/forms/DateOneInput";
import DateInput from "../../../components/forms/DateInput";
import InputAutocomplete from "../../../components/forms/InputAutocomplete";
import { autocomplete } from "../../../global/Autocomplete";
import { dateFr } from "../../../global/global";
const Trip = ({ itinerary, destination, index, handleRemoveAddItinerary, flightTypeActif, setItinerary, dateReturn, loading, setDates }) => {
  const { t } = useTranslation();
  /**
   * utilisé pour l'autocomplete
   */
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  if (itinerary.Ref == 2 && flightTypeActif != "multiple") {
    return;
  }

  /**
   * la fonction qui lance l'autocomplete
   * @param {string} query
   */
  const handleAutocomplte = (query: string) => {
    const req = { service: "autocomplete", action: "airports", data: { q: query } };
    setIsLoading(true);
    autocomplete(req, setOptions, setIsLoading);
  };

  /**
   * autocomplete selected
   * @param {object} items
   */
  const handleAutocomplteSelectDeparture = (items) => {
    let item = items[0];
    itinerary.DepartureCountryId = item.country_id;
    itinerary.DepartureCode = item.code;
    itinerary.DepartureType = item.type;
    destination[index]["Departure"] = item.destination;

    setItinerary(itinerary, index);
  };

  /**
   * autocomplete selected
   * @param {object} items
   */
  const handleAutocomplteSelectReturn = (items) => {
    let item = items[0];

    itinerary.ArrivalCountryId = item.country_id;
    itinerary.ArrivalCode = item.code;
    itinerary.ArrivalType = item.type;
    destination[index]["Arrival"] = item.destination;
    setItinerary(itinerary, index);
  };

  const onDateChange = (dates) => {
    if (!dates) { return }
    setDates(dates, index);

  };

  const filterBy = () => true;
  let button;
  if (flightTypeActif == "round_trip") {
    button = <DateInput onDateChange={onDateChange} id={"flight-date-" + index} leftLabel={t("depart")} rightLabel={t("return")} checkin={dateFr(itinerary.Date)} checkout={dateFr(dateReturn)} />;
  } else {
    button = <DateOneInput onDateChange={onDateChange} id={"flight-date-" + index} leftLabel={t("depart")} checkin={dateFr(itinerary.Date)} />;
  }

  const colTripList = {
    'round_trip': { 'col1': 3, 'col2': 3, 'col3': 4, 'col4': 2 },
    'one_way': { 'col1': 3, 'col2': 3, 'col3': 4, 'col4': 2 },
    'multiple': { 'col1': 4, 'col2': 4, 'col3': 3, 'col4': 1 }
  }
  let colTrip = colTripList[flightTypeActif]
  return (
    <Row className="align-items-center">
      <Col lg={`${colTrip.col1}`} className="my-2">
        <InputAutocomplete id={"location-" + index} label={t("depart_place")} placeholder={t("where_are_you_leaving_from")} icon={<PlaneTakeoff strokeWidth={1} size={20} className="form-icon" />} value={destination[index]["Departure"]} filterBy={filterBy} isLoading={isLoading} handleSearch={handleAutocomplte} handleClick={handleAutocomplteSelectDeparture} options={options} selected={[]} className="rbt-input-no-border border input-blue bg-white" views={'flight'} />
      </Col>
      <Col lg={`${colTrip.col2}`} className="my-2">
        <InputAutocomplete id={"destination-" + index} label={t("destination_place")} placeholder={t("where_are_you_going")} icon={<PlaneLanding strokeWidth={1} size={20} className="form-icon" />} value={destination[index]["Arrival"]} filterBy={filterBy} isLoading={isLoading} handleSearch={handleAutocomplte} handleClick={handleAutocomplteSelectReturn} options={options} selected={[]} className="rbt-input-no-border border input-blue bg-white" views={'flight'} />
      </Col>
      <Col lg={`${colTrip.col3}`} className="my-2">
        {button}
      </Col>

      <Col lg={`${colTrip.col4}`} className={`my-2`}>

        {itinerary.Ref === 1 && flightTypeActif != "multiple" && (
          <Form as="div" className="d-flex align-items-center">
            <Form as="button" className="btn-orange text-dark w-100" style={{ height: "1.5cm" }}>
              {loading ? <Form as="span" className="spinner-border spinner-border-sm  m-1"></Form> : <Search size={20} />}
            </Form>
          </Form>
        )}

        {itinerary.Ref > 2 && (
          <Form role="button" as="button" type="button" className="btn btn-white text-danger rounded-0 w-100" style={{ height: "1.5cm" }} onClick={() => handleRemoveAddItinerary(index)}>
            <Trash2 size={20} strokeWidth={1.5} />
          </Form>
        )}
      </Col>

    </Row>
  );
};

export default Trip;
