import { dateFr } from '../../global/global'
import { format } from "date-fns";
const initialStateActivity = {
    date: dateFr(new Date()),
    textSearch: '',
    dataPost: {
        "destination": "",
        "CountryId": "",
        "DestinationId": "",
        "DateFrom": format(new Date(), 'yyyy-MM-dd'),
        "DateTo": format(new Date(), 'yyyy-MM-dd'),
        "checkin": dateFr(new Date()),
        "checkout": dateFr(new Date()),
        "Nationality": "DZ",
        "CountryOfResidence": "DZ",
        "Adult": 2,
        "Child": 0,
        "ChildAge": []
    }
    ,
    availability: []
};
const activitiesReducer = (state = initialStateActivity, action) => {

    switch (action.type) {
        case "data_post_activity":
            return {
                ...state,
                dataPost: {
                    ...state?.dataPost,
                    ...action.payload,
                }

            };
        case "add_availability_activity":
            return {
                ...state,
                availability: {
                    ...state?.availability,
                    ...action.payload,
                }

            };
        case "set_availability_activity":
            return {
                ...state,
                availability: action.payload,
            };
            case "LOGOUT":
                return initialStateActivity; 
        default:
            return state;
    }



};

export default activitiesReducer;
