import { useState } from 'react';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { createRoot } from 'react-dom/client';
import { innerComponent, removeElements } from './global';
/*true | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down' */
var  rootdCreateModal
export const IModal = (content, title, className, fullscreen = 'lg-down') => {
    const idhmodal = document.getElementById('idhmodal');
    if(!rootdCreateModal){
    rootdCreateModal = createRoot(idhmodal)
    }
 

    rootdCreateModal.render(<BuildModal className={className} title={title} fullscreen={fullscreen} />);
    setTimeout(() => {
        window.handleShowModal();
    }, 200);
    setTimeout(() => {
        const modalModalId = document.getElementById('modalModalId')
        modalModalId.appendChild(innerComponent(content, 'modalModalId-child'))

        
    }, 2000);

}

function BuildModal(props) {
    const [show, setShow] = useState(false);
 
    const handleClose = () => 
    {
        removeElements('modalModalId-child')
        setShow(false)
    }
    window.handleShowModal = () => setShow(true);
    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                id="modalId"
                dialogClassName={props.className}
                fullscreen={props?.fullscreen}
                size="lg"
                scrollable={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="modalTitleId">{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body id="modalModalId" className='p-0' >
                    <Form as="div" className='modalModalId-child'>
                    <Loading />
                    </Form>
                  
                </Modal.Body>

            </Modal>
        </>
    );
}



const Loading = () => {
    return (
        <Form as="div"  className="container p-2">
            <Form as="div"  className="hero">
                <Form as="div"  className="px-4 py-5 my-5 text-center">
                    <h1 className="display-5 fw-bold">Loading...</h1>
                </Form> 
            </Form> 
        </Form> 
    )
}
