import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Reasons() {
  const { t } = useTranslation();

  const reasons = [
    { title: t("comptetetive_prices"), img: "1.svg", description: t("reasons1") },
    { title: t("smooth_booking"), img: "2.svg", description: t("reasons2") },
    { title: t("rich_catalog"), img: "3.svg", description: t("reasons3") },
    { title: t("assistance24h"), img: "4.svg", description: t("reasons4") },
  ];

  return (
    <Form as="section" className="container py-5">
      <Row className="justify-content-between py-5">
        <Col lg="6" className="px-5 py-3 d-flex flex-column align-items-start">
          <Form as="h1" className="fs-1 text-dark fw-medium">
            {t("why_choose_us")}
          </Form>
          <Form as="h2" className="fs-5 fw-medium">
            {t("reasons_sub")}
          </Form>
          <Form as="p" className="pe-5 mt-5 fs-6 fw-light" dangerouslySetInnerHTML={{ __html: t("reasons_text") }}></Form>
          <Form as="a" href="/about" role="button" className="btn-blue mt-5 p-3 px-5 rounded-5 d-flex align-items-center text-decoration-none text-white">
            {t("learn_more")}
          </Form>
        </Col>
        <Col lg="6" className="py-3">
          <Row>
            {reasons.map((reason, index) => (
              <Col md="6" key={index}>
                <Form as="div" className="d-flex flex-column p-3">
                  <Form as="img" width={70} height={70} src={require(`../../../assets/icones/${reason.img}`)} alt={`Illustration pour -${reason.title}`} />
                  <Form as="span" className="fw-medium fs-5 mt-3 text-dark">
                    {reason.title}
                  </Form>
                  <Form as="p" className="mt-3">
                    {reason.description}
                  </Form>
                </Form>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

export default Reasons;
