import { Col, Row } from 'react-bootstrap';

const HotelNav = ({ myRefs, t }) => {

    const views = [
        { title: t('overall'), value: "overview" },
        { title: t('rooms_and_rates'), value: "rooms" },
        { title: t('facilities'), value: "amenties" },
        { title: t('policy'), value: "policy" },
        { title: t('customer_reviews'), value: "reviews" },
        { title: t('faq_hotel'), value: "faq" }
    ];

    const scrollToElement = (id) => {
        if (myRefs[id] && myRefs[id].current) {
            myRefs[id].current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Row className='border-bottom justify-content-between d-none d-lg-flex'>
            {views.map((item, index) => (
                <Col lg="2" key={index} className='px-0'>
                    <div role='button' onClick={() => scrollToElement(item.value)} className={`text-dark text-center py-3 fs-6 fw-lighter ${index === 0 ? "border-bottom border-primary" : ""}`}>{item.title}</div>
                </Col>
            ))}
        </Row>
    );
};

export default HotelNav;
