import { Frown } from "lucide-react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { SideNavAvailability } from "./SideNavAvailability";
 
import { useParams } from "react-router-dom";
import Loader from "../Loader";
const queryClient = new QueryClient();
var params;
function ResultAvailabilityWithMap(props) {
    params = useParams();
    return (
        <QueryClientProvider client={queryClient}>
        <LoadItems configSearch={props.configSearch} getList={props.getList} dataPost={props.dataPost}/>
        </QueryClientProvider>
     )


    return (
        <Form as="section" className="bg-body-tertiary">      
        <Form as="div" className="container-xl py-5" id="container-avail">
          <QueryClientProvider client={queryClient}>
          <LoadItems configSearch={props.configSearch} getList={props.getList} dataPost={props.dataPost}/>
          </QueryClientProvider>
        </Form>
      </Form>
     )
  
  }

  
export default ResultAvailabilityWithMap;



function LoadItems({dataPost,configSearch,getList}) { 
    const { t } = useTranslation();
    const hotel = useSelector((state) => state.hotelIdhtours);
    const { isLoading, error, data } = useQuery("repoData", () => getList(params.searchToken));
   
    if (isLoading) return <Loader />;
    if (error) return <LoadError />;
  
    if (data?.data?.status != true) {
      return <LoadError />;
    }
    return <SideNavAvailability config={configSearch(dataPost, params.searchToken, t)} result={data?.data?.result} />;
  }
  


  function LoadError() {
    const { t } = useTranslation(); 
  
    return(
      <Form as="div" className="px-4 py-5 my-5 text-center text-dark border rounded  bg-white">
          <Form as="div" className="fs-1 fw-bold">
            <Frown size={50} strokeWidth={1.5} />
            <Form as="span" className="mx-1">{t("no_results")}</Form>
          </Form>
          <Form as="div" className="col-lg-6 mx-auto">
            <Form as="p" className="lead mb-4">{t("your_session_has_expired_please_restart_your_search")}</Form>
            <Form as="div" className="d-grid gap-2 d-sm-flex justify-content-sm-center">
               
            </Form>
          </Form>
        </Form>
    )
  }