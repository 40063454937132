import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IModal } from "../../../global/IModal";
import Detaills from "./Detaills";
import TravelNumber from "./TravelNumber";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { ChevronRight } from "lucide-react";
function Availability(props) {

  const composite = {
    flex: <FlexDisplay props={props} />,
    grid: <GridDisplay props={props} />,
  };
  return <>{eval("composite." + props.activeDisplay)} </>;
}
export default Availability;
function FlexDisplay({ props, images }) {

  const currencyData = [
    {
      values: {
        EUR: "€",
        USD: "$",
        GBP: "£",
        TND: "د.ت",
        DZD: "د.ت",
      }
    }
  ];

  const { t } = useTranslation();
  return (
    <div className="justify-content-center">
      {props.items.map((transfer, index) => (
        <div
          key={transfer.RateKey}
          className="bg-white rounded-4 border my-3"
          style={{ overflow: "hidden" }}
        >
          <div className="d-flex align-items-center justify-content-between h-100 p-3">

            <span className="fs-6 fw-light"><span className="text-blue fs-3 fw-bold">{transfer?.Amount}{currencyData[0].values[window.currency]}</span><br />{t('total_price')} {t('tax_included')}</span>

            <span role="button" className="btn-orange text-dark p-3 fw-light px-4 rounded fs-6" onClick={() =>
              IModal(
                <TravelNumber
                  transfer={transfer}
                  searchToken={props.searchToken}
                />,
                transfer['Trip'][0]['From'] + ' -> ' + transfer['Trip'][0]['To'],
                "lg-down",
                0
              )
            }
            >
              {t("reserve")} <ChevronRight size={20} strokeWidth={1.5} />
            </span>
          </div>
          {transfer.Trip.map((trip, it) => (

            <>
              <Form as="hr" className="border-secondary w-100 m-0" />
              <div key={it} className="p-3">
                <Row className="justify-content-between">
                  <Col xl="2" className="my-1">
                    <img
                      src={trip.Image}

                      alt={`${trip.VehicleName}`}
                      width={'120'}
                      height={'58'}
                    />
                  </Col>

                  <Col md="7">
                    <Row className="justify-content-between">
                      <Col lg="3" className="text-secondary my-1 text-end" >
                        {trip.From}
                      </Col>

                      <Col md="6" className="my-1 text-center">
                        <Form as="div" className="text-dark fs-6 fw-bold text-capitalize">
                          {trip.Time.slice(0, 5)}
                        </Form>
                        <div className="d-flex my-2" style={{ height: "3px" }}>
                          <span className="w-25 h-100 bg-blue"></span>
                          <span className="w-50 h-100 bg-primary-subtle"></span>
                          <span className="w-25 h-100 bg-blue"></span>
                        </div>

                        <Form as="div" className="text-capitalize">{format(new Date(trip.Date), "EEE dd MMM yyyy", { locale: fr })}</Form>
                      </Col>

                      <Col lg="3" className="text-secondary my-1 text-end" >
                        {trip.To}
                      </Col>
                    </Row>
                  </Col>
                  <Col md="2" className="my-1 text-end">
                    <Form as="div" className="text-dark fs-6 fw-medium text-capitalize">
                      {trip.VehicleType}
                    </Form>

                    <Form as="div" className="text-capitalize fw-light">
                      {trip.VehicleCategory} - {trip.VehicleName}
                    </Form>

                    <Form as="div" className="text-capitalize fw-light">
                      {trip.Type}
                    </Form>
                  </Col>
                </Row>
              </div>

            </>
          ))}
          <div className="d-flex align-items-center justify-content-center h-100 p-3 border-top">

          <span role="button" className="bg-white text-blue fw-light fs-6" onClick={() => IModal(
              <Detaills transfer={transfer} />, t('details_of_your_trip'), "modal-80w", 0)}>
              {t("view_details")}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

function GridDisplay({ props, images }) {
  const { t } = useTranslation();
  return (
    <Row className="mt-3">
      {props.items.map((transfer, index) => (
        <Col key={index} sm="6" lg="4" className="my-3">
          sssssssssssss
        </Col>
      ))}
    </Row>
  );
}
