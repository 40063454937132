import {Col, Form, Row } from 'react-bootstrap';
import { Check, CircleX, Frown } from 'lucide-react';
import TopBanner from '../../components/TopBanner';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from "react-router-dom";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { getConfirmBooking } from './Actions';
import ItemsBookingConfirmation from './views/ItemsBookingConfirmation';
import ItemsShoppingcartNoAutorizateToConfirm from './views/ItemsShoppingcartNoAutorizateToConfirm';
 
const queryClient = new QueryClient();
    function Success() {

        const { t } = useTranslation();
        const transactionId = useParams().transactionId;

        
        return (
            <QueryClientProvider client={queryClient}>
               <Form as="section">
               <TopBanner data={t("success")}  title={t("payment")} cover="" />
           
              <StartConfirm transactionId={transactionId}/>
              </Form>
            </QueryClientProvider>
          );
    }

    function StartConfirm({transactionId}) { 

        const { isLoading, error, data } = useQuery("repoData", () => getConfirmBooking(transactionId));

        if (isLoading) return <LoaderConfirmation/>;
      
        if (error) return <ConfirmationError />;
 
        if (data?.data?.status != true) {
          return <ConfirmationError />;
        }
        var items = data.data.result 
        var col=8;
        if(items.shoppingcartConfirm.length>0 && items.shoppingcartConfirm.shoppingcartNoAutorizateToConfirm>0){
          col=4
        }
          

        return(
        <Form as="div" className="container">
          <Row className="justify-content-between">
          <Col lg="2" className="my-2"></Col>

          {items.shoppingcartConfirm.length ? 
          <Col lg={col} className="my-2">
          <ItemsBookingConfirmation items={items.shoppingcartConfirm}/>
          </Col> : 
          '' 
          }
         
         {items.shoppingcartNoAutorizateToConfirm.length ? 
          <Col lg={col} className="my-2">
          <ItemsShoppingcartNoAutorizateToConfirm items={items.shoppingcartNoAutorizateToConfirm}/>
          </Col> : 
          '' 
          } 
            <Col lg="2" className="my-2"></Col>
        </Row>
        </Form>
        )
       
    }

    function ConfirmationError() {        
        const { t } = useTranslation();   
      
        return (
     
                
                <Form as="div" className="container">
              <Form as="div" className="hero">
                <Form as="div" className="px-4 py-5 my-5 text-center text-dark">
                  <Form as="div" className="fs-1 fw-bold">
                    <CircleX size={50} strokeWidth={1.5} />
                    <Form as="span" className="mx-1">{t("Error")}</Form>
                  </Form>
                  <Form as="div" className="col-lg-6 mx-auto">
                    <Form as="p" className="lead mb-4">{t("Unable_to_complete_request_Please_try_again_or_contact_support_if_it_happens_more_than_once")}</Form>
                    <Form as="div" className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                      <NavLink className="btn-danger px-4 p-3" to="/shoppingcart">
                       {t('reset')}
                      </NavLink>
                    </Form>
                  </Form>
                </Form>
              </Form>
            </Form>
             
    
        );
    }
    function LoaderConfirmation() {        
        const { t } = useTranslation();   
      
        return (
     
                
                <Form as="div" className="container">     
              <Form as="div" className="hero">
                <Form as="div" className="px-4 py-5 my-5 text-center text-dark">
                   
                  <Form as="div" className="col-lg-6 mx-auto">
                  <Check size={50} strokeWidth={1.5} />
                    <Form as="p" className="lead mb-4">{t("check_payment_and_confirm_your_booking")}</Form>
                    <Form as="div" className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                      <NavLink className="btn-orange px-4 p-3" to="#">
                       Wait.....
                      </NavLink>
                    </Form>
                  </Form>
                </Form>
              </Form>
            </Form>
             
    
        );
    }

    export default Success;

 
