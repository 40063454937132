
const initialState = {
    isloged: false,
    lang: 'fr',
    logedType: 'public',
    currency: 'EUR',
    sessionToken: '',
    logged_at: new Date(Date.now()).toJSON(),
     dataUser:[]
};
const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case "LOGGED_AT":
            return {
                ...state,
                logged_at: new Date(Date.now()).toJSON(),
            };
        

        case "LOGGED":
            return {
                ...state,
                isloged: action.payload,
            };

            case "LOGGEDTYPE":
                return {
                    ...state,
                    logedType: action.payload,
                };
        case "LANG":
            return {
                ...state,
                lang: action.payload,
            };
        case "CURRENCY":
            return {
                ...state,
                currency: action.payload,
            };
        case "TOKEN":
            return {
                ...state,
                sessionToken: action.payload,
            };

        case "LOGOUT":
            return initialState;


        default:
            return state;
    }



};

export default profileReducer;
