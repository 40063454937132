import React, { useState } from "react";
import parse from "html-react-parser";
import { Modal, Row, Col, Form } from "react-bootstrap";
import {country} from "../../../data/country"; 
import { useTranslation } from "react-i18next";
import InputTypeahead from "../../../components/forms/InputTypeahead";
 
function Guests({ showGuests, handleCloseGuests, AdultsCount, handlesetAdultsCount, ChildsCount, handlesetChildsCount, GuestsCount,handleNationalitySelect,ChildAge,handleSetChildAge }) {
  const { t } = useTranslation();
  const [nationalitySelections, setNationalitySelections] = useState(country());
  var optionAdult = "";
  for (let i = 1; i <= 20; i++) {
    optionAdult += '<option value="' + i + '"  >' + i + " " + "</option > ";
  }

  var optionChild = "";
  for (let i = 0; i <= 20; i++) {
    optionChild += '<option value="' + i + '"  >' + i + " " + "</option > ";
  }

  const  AgeChild = () => {
    var html = "";
    for (let i = 1; i <= 11; i++) {
      html += i > 1 ? '<option value="' + i + '">' + i + " ans</option>" : '<option value="' + i + '">' + i + " an</option>";
    }

    return (
      <>
        {ChildAge.map((age, index) => {
          return (
            <Col md="4" className="my-3" key={"div_age_" + index}>
              <select className="w-100 p-3 input-blue border rounded" defaultValue={age}   key={"age_" + index}  data-age-index={index} onChange={handleSetChildAge}>
                {parse(html)}
              </select>
            </Col>
          );
        })}
      </>
    );
  };

  return (
    <>
 
      <Modal show={showGuests} onHide={handleCloseGuests} backdrop="static" keyboard={false}>
        <Form as="div"  className="bg-white text-dark rounded-4">
          <Modal.Header closeButton>
            <Modal.Title>
              <Form as="div" >
                {t("travelers")} : {GuestsCount}
              </Form> 
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form as="div"  className="px-3">
              <Row>
                <Col xs="12" className="my-3">
                  <Form as="div"  className="d-flex flex-column">
                    <label htmlFor="nationality" className="fw-bold fs-6">
                      {t("nationality")}
                    </label>
                    <InputTypeahead id="nationality_id" labelKey="name" placeholder={t("select_your_country")} options={nationalitySelections} handleClick={handleNationalitySelect} size="lg" className="rbt-input-border" />
                
                  </Form> 
                </Col> 
              </Row>
              <Row>
                <Col md="6" className="my-3">
                  <Form as="div"  className="d-flex flex-column">
                    <label htmlFor="adults" className="fw-bold fs-6">
                      {t("adults")} <Form as="span"  className="guests-notation">{t("adults_note")}</Form> 
                    </label>
                    <select id="adults" className="input-blue border mt-1 p-3 rounded" value={AdultsCount} onChange={handlesetAdultsCount}>
                    {parse(optionAdult)}
                    </select>
                  </Form> 
                </Col>
                <Col md="6" className="my-3">
                  <Form as="div"  className="d-flex flex-column">
                    <label htmlFor="childs" className="fw-bold fs-6">
                      {t("childs")} <Form as="span"  className="guests-notation">{t("childs_note")}</Form> 
                    </label>
                    <select id="childs" className="input-blue border mt-1 p-3 rounded" value={ChildsCount} data-last-value={ChildsCount}  onChange={handlesetChildsCount}>
                      {parse(optionChild)}
                    </select>
                  </Form> 
                </Col>
                <Form as="a" geChild/>
              </Row>
            </Form> 
          </Modal.Body>
          <Modal.Footer>
            <button className="btn-blue p-3 px-4 rounded-5" onClick={handleCloseGuests}>
              {t("save")}
            </button>
          </Modal.Footer>
        </Form> 
      </Modal>
    </>
  );
}

export default Guests;
