import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { CheckCircle } from "lucide-react";
import { useTranslation } from "react-i18next";

function Amenities(props) {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);

  const itemsToShow = showAll ? Object.keys(props.list) : Object.keys(props.list).slice(0, 30);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const itemsList = itemsToShow.map((key) => (
    <Col sm="4" className="my-3 d-flex align-items-center" key={"amenities-" + key}>
      <Form as="div" className="text-success">
        <CheckCircle size={25} strokeWidth={1} />
      </Form>
      <Form as="span" className="mx-2 text-capitalize">
        {props.list[key]}
      </Form>
    </Col>
  ));

  return (
    <Form as="div" className="my-3">
      <div className="text-dark fs-3 fw-medium">{t("services_and_amenties")}</div>
      <Row className="text-dark fs-6 my-3 fw-light">{itemsList}</Row>
      <Form as="div" className="my-3">
        {!showAll && Object.keys(props.list).length > 15 && (
          <button className="bg-transparent text-blue fs-6 fw-light border-blue rounded-5 py-3 px-4" onClick={toggleShowAll}>
            {t("see_more")}
          </button>
        )}
        {showAll && (
          <button className="bg-transparent text-blue fs-6 fw-light border-blue rounded-5 py-3 px-4" onClick={toggleShowAll}>
            {t("see_less")}
          </button>
        )}
      </Form>
    </Form>
  );
}

export default Amenities;
