import React from 'react'
import { Col, Row } from 'react-bootstrap'
import FormNewsLetter from './FormNewsLetter'
import { useTranslation } from "react-i18next";

const NewsLetter = () => {

  const { t } = useTranslation();

  return (
    <div className='p-5 bg-dark-blue'>
      <div className='container my-4'>
        <Row className='justify-content-between align-items-center'>
          <Col lg="6" xl="5" className='my-3'>
            <div className='d-flex text-dark'>
              <span className='mx-4'>
                <div className='fs-3 fw-medium'>{t('newsletter_title')}</div>
                <p className='fs-6 mt-3'>{t('subscribe_text')}</p>
              </span>
            </div>
          </Col>
          <Col lg="6" className='my-3'>
            <FormNewsLetter />
          </Col>
        </Row>

      </div>
    </div>
  )
}

export default NewsLetter
