import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";


const FaqNav = ({category,handleClick,selected}) => {

    const { t } = useTranslation();
   
  return (
            <>
            {
            category.map((item, index) => (
            <Col lg="12" className="my-3"  key={index}>
            <Form as="div"
       
            role="button"                   
            className={`fs-6 p-3 text-center border rounded-5 bg-white ${
                selected === item.id
                  ? "border-blue text-blue"
                  : ""
              }`}
            onClick={() => handleClick(item.id)}
            >
            {item[window.lang]}
            </Form>
            </Col>
            ))
              }
            </>
  );
};

export default FaqNav;
