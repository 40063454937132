import { send } from "../../global/axios";
export function configSearch(dataPost,searchToken,t){
    const config = {
        dataPost: dataPost,
        service: 'hotel',
        searchToken: searchToken,
       /*isMap: 1,    
        mapComponent: 'Hotels', */   
        messageFilter: t('no_hotel_matches_the_criteria_selected_filters'),
        request: {
            list: { service: 'hotel', action: "list", data: {},urlAppend:[searchToken,1] },
            filter: { service: 'hotel', action: "filter", data: { filter: [], searchToken: searchToken } },
            paginate: { service: 'hotel', action: "paginate", data: {} ,urlAppend:[searchToken,1]} ,
            availprice: { service: 'hotel', action: "availprice", data: {}, urlAppend:[searchToken] },
            mapUrl: { service: 'hotel', action: "map", data: {}, urlAppend:[searchToken] }
        },
        toolBarItems: [
            { id: "prices_hotel", name: t('price'), fiter_name: 'PRICES_ORDER' },
            { id: "category_hotel", name: t('category'), fiter_name: 'CATEGORY_ORDER' },
            { id: "note_hotel", name: t('note'), fiter_name: 'TRIPADVISOR_ORDER' }
        ],
        filterItems: [          
           { id: "category", title: t('category'), action: 'Category' },
           { id: "trip_advisor", title: t('note'), action: 'Images' }, 
            { id: "chain", title: t('hotel_chain'), action: 'Standard' },
            { id: "hotel_type", title: t('accommodation_type'), action: 'Standard' },
        ]
    }

    return  config;
}
export const  getAvailabiity = async (dataPost)=>{
    return await send({ service: 'hotel', action: "load", data: dataPost,urlAppend:[] });
   }


export const  getList = async (searchToken)=>{
 return await send({ service: 'hotel', action: "list", data: {},urlAppend:[searchToken,1] });
}
export const  getRooms = async (hotelid,searchToken)=>{
 return await send({ service: "hotel", action: "rooms", data: {}, urlAppend: [hotelid, searchToken]  });
}

export const  getReviews = async (hotelid)=>{
    return await send({ service: "hotel", action: "detail", data: {}, urlAppend: [hotelid, "reviews"] });
   }

export const getCheckRate = async (hotelName,hotelid,rateKey, searchToken,navigate) => {
    document.getElementById("loader").style.display = "block";
 const res=await send({ service: "hotel", action: "chek_rate", data: {}, urlAppend: [hotelid, searchToken, rateKey] });

 
 if (res?.data?.status == true) {
    window.scrollTo(0, 0);
    navigate(`/hotels/book/${hotelName}/${hotelid}/${searchToken}/${rateKey}`);
   }  

   document.getElementById("loader").style.display = "none";
}

 export const  getDetail = async (hotelid)=>{
    return await send({ service: 'hotel', action: "detail", data: {},urlAppend:[hotelid] });
   }


   export const  getBook = async (hotelid, searchToken, rateKey)=>{
    return  await send({ service: "hotel", action: "chek_rate", data: {}, urlAppend: [hotelid, searchToken, rateKey] });
   }

 export  const addToPanier = async (data,hotelId,searchToken,rateKey) => {
    document.getElementById("loader").style.display = "block";
    const resp= await send({ service: 'hotel', action: "prebooking", data: {dataBook:data}, urlAppend:[ hotelId,searchToken,rateKey] });        
    
    console.log(resp)
    if (resp?.data?.status == true && resp?.data?.result?.preBooking !='' && resp?.data?.result?.preBooking !=null) {
    window.location="/shoppingcart"
    }else{
        alert('error not panier')
    }
    document.getElementById("loader").style.display = "none";
  };