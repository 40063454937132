import Details from './Details'
import Search from './Search'
import SearchWithMap from './SearchWithMap'
import Book from './Book'
function Main(props) {
    const composite = {
        search: <Search />,
        searchMap: <SearchWithMap />,
        details: <Details />,
        book: <Book />,
    };
    return (
        <>{composite[props.component]}</>     

    );

}
export default Main;