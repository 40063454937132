import mapimg from "../assets/map-view-2.png";
import { MapPin } from "lucide-react";
import { useTranslation } from "react-i18next";

const FilterMap = () => {
  const { t } = useTranslation();

  return (
    <div className="position-relative border">
      <img alt="Illusatrtion d'une carte" width="100%" height="100%" className="rounded-5" src={mapimg} />
      <div className="w-100 d-flex flex-column align-items-center" style={{ position: "absolute", bottom: "20px" }}>
        <MapPin color="#DC3545" size={30} strokeWidth={1.5} />
        <button className="border bg-white fw-light fs-6 p-3 px-5 rounded-5 mt-3 text-blue">{t("view_in_map")}</button>
      </div>
    </div>
  );
};

export default FilterMap;
