import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Offers() {
  const { t } = useTranslation();

  const bgOrange = {
    background: "linear-gradient(180deg, #EE722E 0%, #f8ae85 100%)",
    overflow: "hidden",
  };

  const bgBlue = {
    background: "linear-gradient(180deg, rgba(5,62,209,1) 0%, rgba(49,121,220,1) 100%)",
    overflow: "hidden",
  };

  return (
    <Form as="section" className="container-lg py-3">
      <Row>
        <Col className="my-3" lg="6" style={{ overflow: "hidden" }}>
          <Form as="a" href="/register">
            <Form as="div" style={bgBlue} className="rounded-4 pt-4 d-flex justify-content-between align-items-center px-5 text-white h-100">
              <Row className="align-items-center">
                <Col sm="7" md="8" lg="7" xl="6">
                  <Form as="div" className="py-4">
                    <Form as="div" className="fs-4 fw-medium">
                      {t("vip_title")}
                    </Form>
                    <Form as="p" className="my-3">
                      {t("vip_text")}
                    </Form>
                    <Form as="button" className="bg-white text-dark p-3 rounded">
                      {t("become_vip")}
                    </Form>
                  </Form>
                </Col>
                <Col sm="5" md="4" lg="5" xl="6" className="d-none d-sm-block">
                  <Form as="img" alt={t("vip_title")} src="https://superio-appdir.vercel.app/_next/image?url=%2Fimages%2Fresource%2Fcandidate.png&w=256&q=75" />
                </Col>
              </Row>
            </Form>
          </Form>
        </Col>
        <Col className="my-3" lg="6" style={{ overflow: "hidden" }}>
          <Form as="a" href="https://extranet.groupidh.com/">
            <Form as="div" style={bgOrange} className="rounded-4 pt-4 d-flex justify-content-between align-items-center px-5 text-white h-100">
              <Row className="align-items-center">
                <Col sm="7" md="8" lg="7" xl="6">
                  <Form as="div" className="py-4">
                    <Form as="div" className="fs-4 fw-medium">
                      {t("suppliers")}
                    </Form>
                    <Form as="p" className="my-3">
                      {t("supplier_text")}
                    </Form>
                    <Form as="button" className="bg-white p-3 rounded text-dark">
                      {t("become_supplier")}
                    </Form>
                  </Form>
                </Col>
                <Col sm="5" md="4" lg="5" xl="6" className="d-none d-sm-block">
                  <Form as="img" alt={t("suppliers")} src="https://superio-appdir.vercel.app/_next/image?url=%2Fimages%2Fresource%2Femploy.png&w=256&q=75" />
                </Col>
              </Row>
            </Form>
          </Form>
        </Col>
      </Row>
    </Form>
  );
}

export default Offers;
