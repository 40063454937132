import { format } from "date-fns";
import { Form } from "react-bootstrap";
import { createRoot } from "react-dom/client";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { QueryClient } from "react-query";
export const sleep = async (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}
export function dateUrl(dt) {
    if (dt === null) { return '-'; }
    const date = new Date(dt);
    return format(date, 'yyyy/MM/dd')
}
 
export function dateFr(dt, type) {

    if (dt === null) { return '-'; }
    const date = new Date(dt);
    switch (type) {
        case 'datetime':
            return format(date, 'dd/MM/yyyy HH:mm:ss');
            break;
            case 'datetime2':
                return format(date, 'dd/MM/yyyy HH:mm');
                break;
        case 'time':
            return format(date, 'dd/MM/yyyy HH:mm:ss');
            break;
        default:
            return format(date, 'dd/MM/yyyy');
            break;
    }
}
export function time_hour(t) {
 
    return format(new Date(t), 'HH:mm');
     
}

export function dateTimeEn(dt) {
    const date = new Date(dt);
    return format(date, 'yyyy-MM-dd HH:mm');
       
  }
export function dateEn(dt, type) {
  var date =  dt.split('/');
  return date[2]+'-'+date[1]+'-'+date[0];
     
}

export function isValidUrl(urlString) {
    var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
    return !!urlPattern.test(urlString);

}


export function money(amount) {

    if (isNaN(amount) == true) { return amount; }
    var nStr = parseFloat(amount).toFixed(3);

    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? ',' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ' ' + '$2');
    }
    if (x2 === ',000') { return x1; }
    return x1 + x2;
}


export function scrollApp() {

    const sectionElement = document.getElementById('app-route');
    if (sectionElement) {
    sectionElement.scrollIntoView({ behavior: "smooth" });
    }
}

export function scroll(id) {

    const sectionElement = document.getElementById(id);
    if (sectionElement) {
    sectionElement.scrollIntoView({ behavior: "smooth" });
    }
}

export function innerComponent(component, classRemove, elmentcreate = 'div') {
    removeElements(classRemove)
    sleep(5000)
    const domNode = document.createElement(elmentcreate);
    domNode.setAttribute('class', classRemove)
    const root = createRoot(domNode);
    root.render(component);
    return domNode
}

export const removeElements = (className) => {
    let get = document.querySelectorAll('.' + className);
    get.forEach(element => {
        element.remove();
    });
}

export function isEmail(email) {
    var e = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return e.test(email)
}

export function error(message) {
    NotificationManager.error(message);
}

export function success(message) {
    NotificationManager.success(message);
}

export function ucfirst(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

 
export function float(amount) {
    if (isNaN(amount) === true) { return amount; }
    var nStr = parseFloat(amount).toFixed(3);
    return parseFloat(nStr);

}

export function getNights(dateFrom, dateTo) {
    var date1 = new Date(dateFrom);
    var date2 = new Date(dateTo);
    // différence des heures
    var time_diff = date2.getTime() - date1.getTime();
    // différence de jours
    var days_Diff = Math.ceil(time_diff / (1000 * 3600 * 24));
    return days_Diff - 1;
}

export function getNexDate(dateFrom, nb) {
    var tmp = new Date(dateFrom).getTime()
    var tmp_next = tmp + (86400000 * nb);
    return format(tmp_next, 'yyyy-MM-dd');
}
export function getPreviousDate(dateFrom, nb) {
    var tmp = new Date(dateFrom).getTime()
    var tmp_next = tmp - (86400000 * nb);
    return format(tmp_next, 'yyyy-MM-dd');
}

export function getNexTime(dateFrom, nb) {
    var tmp = new Date(dateFrom).getTime()
    var tmp_next = tmp + (86400000 * nb);
    return tmp_next;
}
export function getPreviousTime(dateFrom, nb) {
    var tmp = new Date(dateFrom).getTime()
    var tmp_next = tmp - (86400000 * nb);
    return tmp_next;
}
export function innerHTML(id, compoment) {
    const elem = document.getElementById(id);
    const rootelem = createRoot(elem)
    rootelem.render(<compoment />);
}

export function Error({ index, attr, title,errors }) {
    const { t } = useTranslation();
    var txt = "";
    var c = "";
    if (errors[index]) {
      var e = errors[index];
      if (e[attr]) {
        txt = t(title) + " : " + t(e[attr]["message"]);
        c = " show";
      }
    }
  
    return (
      <Form as="span" className={"p-2 invalid-feedback" + c}>
        {txt}
      </Form>
    );
  }


  export function iqueryClient(){
    const queryClient = new QueryClient(
        {
            defaultOptions:{
                queries:{
                    refetchOnWindowFocus:false,
                    retry:false,
                }
            }
        }
    );

    queryClient.clear()

    return queryClient
  }


  export function currencySymbol(key) {
    const currencyData = [
        {
          values: {
            EUR: "€",
            USD: "$",
            GBP: "£",
            TND: "د.ت",
            DZD: "د.ت",
          }
        }
      ];

    return currencyData[0].values[key]
       
  }
  