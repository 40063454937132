import React from "react";
import { Form } from "react-bootstrap";

function Descriptions({ items, title }) {
  if (items.length == 0) {
    return;
  }

  return (
    <>
     <Form as="div" className="my-3">
      <h2 className="text-dark fs-3 fw-medium">{title}</h2>
      <Form as="div" className="my-3 text-dark">
      {items.map((item, index) => (
          <Form as="p" 
            className="fs-6 fw-light"
            dangerouslySetInnerHTML={{ __html: item.value }}
          ></Form> 
        ))}
      </Form>
    </Form>
        
    </>
  );
}

export default Descriptions;
