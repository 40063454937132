import { ArrowDownToLine, Calendar, MapPin, ShoppingCart, Trash2, Users2 } from "lucide-react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { dateFr, money } from "../../../global/global";
import { getVoucher } from "../Actions";

function ItemsShoppingcartNoAutorizateToConfirm({ items }) {
  const { t } = useTranslation();

  return (
    <>
      {items.map((item, key) => (
        <Form as="div" className="border rounded-3 p-3 my-3" key={key}>
          <Row className="align-items-start">
            <Col sm="8" xl="8">
              <Form as="div" className="text-dark">
              <Alert   variant={'danger'}>
                   <Form as="p" className="fw-bold text-danger">
                   {item.message}
                    </Form>
                  </Alert> 
                <Form as="div" className="fs-6 fw-bold mt-2">
                  {item.service_name}
                </Form>
                <Form as="div" className="d-flex align-items-center">
                  <Form as="span"></Form>
                  <Form as="span" className="mx-2 d-flex">
                    {t(item.service)}
                  </Form>
                  <Form as="span"></Form>
                </Form>

                <Form as="div" className="mt-3 fw-medium">
                  

                  <Form as="div" className="d-flex align-items-center my-2">
                    <ShoppingCart size={15} />
                    <Form as="span" className="mx-2">
                      {item.shoppingcartNumber}
                    </Form>
                  </Form>
                  <Form as="div" className="d-flex align-items-center my-2">
                    <Users2 size={15} />
                    <Form as="span" className="mx-2">
                      {" "}
                      {item.holder}
                    </Form>
                  </Form>
                  <Form as="div" className="d-flex align-items-center my-2">
                    <Calendar size={15} />
                    <Form as="span" className="mx-2">
                      {dateFr(item.checkin)} - {dateFr(item.checkout)}
                    </Form>
                  </Form>
                </Form>
                <Form as="div" className="d-flex justify-content-between align-items-center mt-3">
                 
                  <Form as="div" className="fs-6">
                    {t("price")} : {money(item.amount)} {item.currency}
                  </Form>
                </Form>
              </Form>
            </Col>
          </Row>
        </Form>
      ))}
    </>
  );
}

export default ItemsShoppingcartNoAutorizateToConfirm;
 