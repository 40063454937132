import { dateFr } from '../../global/global'
import { format } from "date-fns";
const initialStatePackage = {
    date: dateFr(new Date()),
    textSearch: '',
    dataPost: {
        "destination": "",
        "CountryId": "",
        "DestinationId": "",
        "DateFrom": format(new Date(), 'yyyy-MM-dd'),
        "DateTo": format(new Date(), 'yyyy-MM-dd'),
        "checkin": dateFr(new Date()),
        "checkout": dateFr(new Date()),
        "Nationality": "DZ",
        "Adult": 2,
        "Child": 0,
        "ChildAge": []
    }

};
const packageReducer = (state = initialStatePackage, action) => {

    switch (action.type) {
        case "data_post_package":
            return {
                ...state,
                dataPost: {
                    ...state?.dataPost,
                    ...action.payload,
                }

            };
        case "add_availability_package":
            return {
                ...state,
                availability: {
                    ...state?.availability,
                    ...action.payload,
                }

            };
        case "set_availability_package":
            return {
                ...state,
                availability: action.payload,
            };
            case "LOGOUT":
                return initialStatePackage; 
        default:
            return state;
    }



};

export default packageReducer;
