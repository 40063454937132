import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

const Nav = () => {
    const { t } = useTranslation();

    const items = [
        { "id": "flights", "name": t('flights') },
        { "id": "hotels", "name": t('hotels') },
        { "id": "activities", "name": t('activities') },
        { "id": "transfers", "name": t('transfers') }
    ];

    const [button, setButton] = useState(items[0].id);

    const handleButton = (value) => {
        setButton(value);
        sessionStorage.setItem('selectedButton', value);
        const event = new Event('storageChange');
        window.dispatchEvent(event);
    };
    return (
        <nav>
            <ul className="header-nav-list m-0 d-flex justify-content-around align-items-center fs-6" style={{ listStyle: "none" }}>
                {items.map(item => (
                    <li
                        key={item.id}
                        role="button"
                        className={`fs-6 mx-3 py-1 ${button === item.id ? "text-blue text-decoration-underline" : ""}`}
                        onClick={() => handleButton(item.id)}
                    >
                        {item.name}
                    </li>
                ))}
            </ul>
        </nav>
    );
}

export default Nav;
