import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Hotels from "../../promotions/views/Hotels";
import { getPromotions } from "../../promotions/Actions";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";


const queryClient = new QueryClient();
const PromoHotel = () => {

    return (
        <QueryClientProvider client={queryClient}>
            <LoadPromo />
        </QueryClientProvider>
    );
};

export default PromoHotel;

function LoadPromo() {
    const { t } = useTranslation();

    const { isLoading, error, data } = useQuery("repoData", () => getPromotions());
    if (isLoading) return
    if (error) return
    if (data?.data?.status != true) return

    return (
        <>
            <Form as="div" className="my-3 fs-3 text-dark fw-medium">
                {t('you_might_like')}
            </Form>
            <Hotels promotions={data?.data?.result?.hotel} />
        </>

    )
}
