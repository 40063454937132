import { dateFr } from '../../global/global'
import { format } from "date-fns";
const initialStateFlight= {
    date: dateFr(new Date()),
    textSearch: '',
    ClasseTitle: 'class_travelers',
    TripTitle: 'round_trip',
    dataPost: {
        "Trip": "RT",
        "Adult": 1,
        "Child": 0,
        "Infant": 0,
        "Cabin":"ALL",
        "Stops":"direct",
        "Itineraries": [
                    {
                    "Ref": 1,
                    "DepartureCountryId": "",
                    "DepartureCode": "",
                    "DepartureType": "City",
                    "ArrivalCountryId": "",
                    "ArrivalCode": "",
                    "ArrivalType": "City",
                    "Date": format(new Date(), 'yyyy-MM-dd')
                    },
                    {
                    "Ref": 2,
                    "DepartureCountryId": "",
                    "DepartureCode": "",
                    "DepartureType": "City",
                    "ArrivalCountryId": "",
                    "ArrivalCode": "",
                    "ArrivalType": "City",
                    "Date": format(new Date(), 'yyyy-MM-dd')
                    } 
        ]
        
    },
    "destination": [
        {
            "Departure":"",
            "Arrival":""
        } , {
            "Departure":"",
            "Arrival":""
        }           
    ]
       ,
       availability: []
};
const flightReducer = (state = initialStateFlight, action) => {

    switch (action.type) {
        case "cabin_title":
            return {
                ...state,
                 TripTitle: action.payload,
            };
            case "class_title":
            return {
                ...state,
                ClasseTitle: action.payload,
            };
           


        case "data_post_flight":
            return {
                ...state,
                dataPost: {
                    ...state?.dataPost,
                    ...action.payload,
                }

            };

            case "destination_flight":
                return {
                    ...state,
                    destination: {
                        ...state?.destination,
                        ...action.payload,
                    }
    
                };
        case "add_availability_flight":
            return {
                ...state,
                availability: {
                    ...state?.availability,
                    ...action.payload,
                }

            };
        case "set_availability_flight":
            return {
                ...state,
                availability: action.payload,
            };
            case "LOGOUT":
                return initialStateFlight; 
        default:
            return state;
    }



};

export default flightReducer;
