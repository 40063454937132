import { Plus, Search } from "lucide-react";
import FlightHeader from "./FlightHeader.js";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Trip from "./Trip";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { dateEn, dateFr } from "../../../global/global";
import { Col, Form, Row } from "react-bootstrap";
import { getAvailability } from "../Actions";
import NoAvailability from "../../../components/NoAvailability";
import { config } from "../../../configs/config";

import { useNavigate } from "react-router-dom";

const Availability = ({ setComponent }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const flight = useSelector((state) => state.flightsIreducer28062024);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [flightTypeActif, setFlightTypeActif] = useState(flight.TripTitle);
  const [itineraries, setItineraries] = useState(flight.dataPost.Itineraries);
  const [destination, setDestination] = useState(flight.destination);
  const [ClasseValue, setClasseValue] = useState(config("flightClass")[0].title);
  const [adultCount, setAdultCount] = useState(flight.dataPost.Adult);
  const [childCount, setChildCount] = useState(flight.dataPost.Child);
  const [infantCount, setInfantCount] = useState(flight.dataPost.Infant);
  const handleChangeFlightTypeClick = (item) => {
    setFlightTypeActif(item.title);
    flight.TripTitle = item.title;
    flight.dataPost.Trip = item.code;
    if (item.title != "multiple") {
      setItineraries(flight.dataPost.Itineraries);
    }
  };

  const handleAddItinerary = () => {
    const count = itineraries.length;
    const ref = count + 1;
    const newItenerary = {
      Ref: ref,
      DepartureCountryId: "",
      DepartureCode: "",
      DepartureType: "City",
      ArrivalCountryId: "",
      ArrivalCode: "",
      ArrivalType: "City",
      Date: format(new Date(), "yyyy-MM-dd"),
    };
    var newdestination = {
      Departure: "",
      Arrival: "",
    };

    setDestination([...destination, newdestination]);
    setItineraries([...itineraries, newItenerary]);
  };

  const handleRemoveAddItinerary = (index) => {
    const newItenerary = itineraries.filter((itinerary, i) => i !== index);
    const newDestination = destination.filter((dest, i) => i !== index);
    setItineraries(newItenerary);
    setDestination(newDestination);
  };

  const setItinerary = (itenerary, index) => {
    if (itenerary.Ref == 1 && flightTypeActif == "round_trip") {
      let se = index + 1;

      itineraries[se]["DepartureCountryId"] = itenerary.ArrivalCountryId;
      itineraries[se]["DepartureCode"] = itenerary.ArrivalCode;
      itineraries[se]["DepartureType"] = itenerary.ArrivalType;
      itineraries[se]["ArrivalCountryId"] = itenerary.DepartureCountryId;
      itineraries[se]["ArrivalCode"] = itenerary.DepartureCode;
      itineraries[se]["ArrivalType"] = itenerary.DepartureType;
      destination[se]["Departure"] = destination[index]["Arrival"];
      destination[se]["Arrival"] = destination[index]["Departure"];
    }

    setItineraries([...itineraries]);
  };

  const handleClasseValue = (item) => {
    flight.ClasseTitle = item.title;
    flight.dataPost.Cabin = item.code;
    setClasseValue(item.title);
  };

  const incrementAdultCount = () => {
    setAdultCount(adultCount + 1);
  };

  const decrementAdultCount = () => {
    setAdultCount(adultCount - 1);
  };

  const incrementChildCount = () => {
    setChildCount(childCount + 1);
  };

  const decrementChildCount = () => {
    setChildCount(childCount - 1);
  };

  const incrementInfantCount = () => {
    setInfantCount(infantCount + 1);
  };

  const decrementInfantCount = () => {
    setInfantCount(infantCount - 1);
  };

  flight.dataPost.Adult = adultCount;
  flight.dataPost.Child = childCount;
  flight.dataPost.Infant = infantCount;

  const setDates = (dates, index) => {
    if (flightTypeActif == "round_trip") {
      if (dates[0]) {
        itineraries[index]["Date"] = dateEn(new Date(dates[0]).toLocaleDateString("fr-FR"))
      }
      if (dates[1]) {
        let se = index + 1;
        itineraries[se]["Date"] = dateEn(new Date(dates[1]).toLocaleDateString("fr-FR"))
      }

    } else {
      itineraries[index]["Date"] = dateEn(new Date(dates).toLocaleDateString("fr-FR"))
    }
  };

  const onStartSearchFlight = async (event) => {
    event.preventDefault();

    if (!ctrlDataPost()) {
      return false;
    }

    dispatch(setAvailability([]));
    setLoading(true);
    const res = await getAvailability(flight.dataPost);

    if (res?.data?.status == true) {
      var url = ["/flights", "search", res?.data?.result?.searchToken, dateFr(itineraries[0]["Date"])];
      window.history.pushState(null, "Title", url.join("/"));
      window.location.reload()
    } else {
      setComponent(<NoAvailability />);
    }

    setLoading(false);
  };

  const ctrlDataPost = () => {
    flight.dataPost.Itineraries = itineraries;
    updateDataPost();
    return true;
  };
  const updateDataPost = () => {
    dispatch(
      setDataPost({
        ...flight?.dataPost,
        ...flight.dataPost,
      })
    );
    dispatch(
      setDataDestination({
        ...flight?.destination,
        ...destination,
      })
    );

    dispatch(setTripTitle(flightTypeActif));

    dispatch(setClassTitle(ClasseValue));
  };
  return (
    <Form as="section" className="container">
      <Form onSubmit={onStartSearchFlight} id="form-flight-availability">
        <FlightHeader handleChangeFlightTypeClick={handleChangeFlightTypeClick} flightTypeActif={flightTypeActif} ClasseValue={ClasseValue} handleClasseValue={handleClasseValue} adultCount={adultCount} childCount={childCount} infantCount={infantCount} incrementAdultCount={incrementAdultCount} decrementAdultCount={decrementAdultCount} incrementChildCount={incrementChildCount} decrementChildCount={decrementChildCount} incrementInfantCount={incrementInfantCount} decrementInfantCount={decrementInfantCount} />
        <>
          {itineraries.map((itinerary, index) => (
            <Trip loading={loading} key={index} index={index} setItinerary={setItinerary} itinerary={itinerary} dateReturn={itineraries[1]["Date"]} flightTypeActif={flightTypeActif} destination={destination} handleRemoveAddItinerary={handleRemoveAddItinerary} setDates={setDates} />
          ))}
        </>
        {flightTypeActif === "multiple" && itineraries.length < 4 && (
          <Row className="justify-content-end">
            <Col xs="6" lg="3" xl="2" className="my-3">
              <Form role="button" as="button" className="btn-outline-blue w-100" type="button" style={{ height: "1.8cm" }} onClick={handleAddItinerary}>
                <Plus size={15} /> {t("add_flight")}
              </Form>
            </Col>
            <Col xs="6" lg="3" xl="2" className="my-3">
              <Form as="button" className="btn-orange w-100 text-dark" style={{ height: "1.8cm" }}>
                {loading ? <Form as="span" className="spinner-border spinner-border-sm m-1"></Form> : <Search size={20} />}
              </Form>
            </Col>
          </Row>
        )}
      </Form>
    </Form>
  );
};

export default Availability;

const setTripTitle = (value) => {
  return {
    type: "cabin_title",
    payload: value,
  };
};

const setClassTitle = (value) => {
  return {
    type: "class_title",
    payload: value,
  };
};

const setDataPost = (value) => {
  return {
    type: "data_post_flight",
    payload: value,
  };
};
const setDataDestination = (value) => {
  return {
    type: "destination_flight",
    payload: value,
  };
};
const setAvailability = (value) => {
  return {
    type: "set_availability_flight",
    payload: value,
  };
};
