import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { send } from "../global/axios";
import { NotificationManager } from 'react-notifications'
import IController from "../components/forms/IController";
import { expEmail } from "../configs/constants";
const FormNewsLetter = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: { email: "" }
    });


    const onSubmit = async (data) => {
        setLoading(true);

        const res = await send({ service: "contact", action: "news", data: data, urlAppend: [] });

        if (res?.data?.status == true) {
            if (res?.data?.result?.error == 0) {
                NotificationManager.success(res?.data?.result.message);
            } else {
                NotificationManager.error(res?.data?.result.message);
            }
        }
        setLoading(false);
    }


    var item = {
        type: "email",
        className: "w-75 border-0 rounded-1 text-dark bg-white fw-light p-4",
        placeholder: "Email",
        rules: {
            required: t('mandatory'),
            pattern: {
                value: expEmail,
                message: t('invalid_format'),
            }
        },
    };

    return (
        <Form as="div">
            <Form onSubmit={handleSubmit(onSubmit)} >
                <Form as="div" className="d-flex align-items-center">
                    <IController
                        elment={`email`}
                        item={item}
                        control={control}
                        errors={errors}
                        register={register}
                        />


                    <Form as="button" type="submit" className="bg-orange text-white py-4 w-25 mx-3 rounded-1">
                        {loading && (
                            <Form as="span" className="spinner-border spinner-border-sm"></Form>
                        )}
                        {t('subscribe')}
                    </Form>
                </Form>
            </Form>
        </Form>
    )
}


export default FormNewsLetter