 
 
 
import { stringify } from 'querystring'

export function autocomplete(request,setOptions,setIsLoading) {

    let  service=request.service.toUpperCase()
    let  action=request.action.toUpperCase()
    let  pro = process.env
    let  url =pro[`REACT_APP_API_SERVICE_${service}_URL_${action}`]
    let  method =pro[`REACT_APP_API_SERVICE_${service}_METHOD_${action}`] 
    if (request.urlAppend) {
        if (request.urlAppend!='') {
            url += '/' + request.urlAppend;
        }
    } 
  fetch(`${process.env.REACT_APP_API_URL}${url}?${stringify(request.data)}`, {
        method: "GET",
        headers: {
            "Content-type": "application/json",
            "sessionToken": `${window.sessionToken}`
        },       
        cache: "default", //ou no-store, reload, no-cache, force-cache, ou only-if-cached
    }).then((resp) => resp.json())
    .then(({ result }: Response) => {        
      setOptions(result);
      setIsLoading(false);
    }); 

}