import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Row, Col, Form, FloatingLabel } from "react-bootstrap";
import { ArrowRight } from "lucide-react";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import IController from "../../components/forms/IController";
import { send } from "../../global/axios";

function FormConcat() {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      full_name: "",
      phone: "",
      email: "",
      message: "",
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    const res = await send({ service: "contact", action: "send", data: { data }, urlAppend: [] });

    if (res?.data?.status == true) {
      NotificationManager.success(res?.data?.result.message);
    }
    setLoading(false);
  };

  var item = {
    type: "text",
    className: "input-blue w-100 input-passenger border rounded",
    size: "md",
    rules: {
      required: t("mandatory"),
    },
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form as="div" className="rounded-4 p-5 d-flex flex-column border bg-white">
          <Form as="span" className="h3 text-dark fw-bold">
            {t("send_a_message")}
          </Form>
          <Row className="justify-content-between">
            <Col md="6" className="mt-4">
              <FloatingLabel controlId="full_name" label={t("full_name")} className="mb-3">
                <IController elment={`full_name`} item={item} control={control} errors={errors} register={register} />

                <Form as="small" className="erreur-input invalid-feedback">
                  {errors?.full_name && errors.full_name.message}
                </Form>
              </FloatingLabel>
            </Col>
            <Col md="6" className="mt-4">
              <FloatingLabel controlId="phone" label={t("last_name")} className="mb-3">
                <IController elment={`phone`} item={item} control={control} errors={errors} register={register} />

                <Form as="small" className="erreur-input invalid-feedback">
                  {errors?.phone && errors.phone.message}
                </Form>
              </FloatingLabel>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg="12">
              <FloatingLabel controlId="email" label={t("email")} className="mb-3">
                <IController elment={`email`} item={item} control={control} errors={errors} register={register} />

                <Form as="small" className="erreur-input invalid-feedback">
                  {errors?.email && errors.email.message}
                </Form>
              </FloatingLabel>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg="12">
              <FloatingLabel controlId="message" label={t("message")} className="mb-3">
                <IController elment={`message`} item={item} control={control} errors={errors} register={register} />

                <Form as="small" className="erreur-input invalid-feedback">
                  {errors?.message && errors.message.message}
                </Form>
              </FloatingLabel>
            </Col>
          </Row>
          <Form as="button" type="submit" className="btn-blue mt-5 p-3 px-4 rounded-5 align-self-start mt-3 p-3 ps-4 pe-4">
            {loading && <Form as="span" className="spinner-border spinner-border-sm"></Form>}
            {t("send")} <ArrowRight className="ms-2" size={20} />
          </Form>
        </Form>
      </Form>
    </>
  );
}

export default FormConcat;
