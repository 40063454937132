import { ChevronRight } from "lucide-react";
import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TopBanner = ({ data, data2 }) => {
  const { t } = useTranslation();


  return (
    <div className="bg-dark-blue">
      <div className="container text-white py-4 fw-light d-flex align-items-center fs-6 fw-light">
        <span>
          <NavLink className="text-white" to="/">
            {t('home')}
          </NavLink>
        </span>
        <span className="mx-1"><ChevronRight  size={15} strokeWidth={2}/> </span>
        <span>
          <NavLink className="text-white" to="#">
          {data}
          </NavLink>
        </span>
      </div>
    </div>
  );
};

export default TopBanner;
