import { ShieldAlert } from "lucide-react";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function RateComment({ items, title, icon }) {
  const { t } = useTranslation();
  if (items.length == 0) {
    return;
  }

  return (
    <Form as="div" className="my-3 rounded-4 border" >
      <Form as="div"
        className="fs-6 text-blue p-3 d-flex align-items-center border-bottom"
        htmlFor="genre"
      >
        <ShieldAlert strokeWidth={1.5} />
        <Form as="span"  className="mx-1 fw-light">
        {t(title)}
        </Form>
      </Form>
        <Form as="div" className="my-3 p-3">
        {items.map((item, index) => (
          <Form as="div" className="d-flex align-items-center" key={index}>
            <Form as="span" className="me-2">{icon}</Form>
            <Form as="p"
              dangerouslySetInnerHTML={{
                __html: item.Value.replaceAll("//", "<br/>"),
              }}
            ></Form>
          </Form>
        ))}
        </Form>
    </Form>
  );
}

export default RateComment;
