import {   
    Baby,
    Users2,
    ThumbsUp 
  } from "lucide-react";
  import { Row, Col,Form } from "react-bootstrap";
  import { useTranslation } from "react-i18next";
  import { money } from "../../../global/global";
  function OrderSummary({ items }) {
    const { t } = useTranslation();
    return (
      <Form as="div"  className="my-3 border border-2">
        <Row className="p-3">
          
          <Col lg="12">
            <Form as="div"  className="d-flex flex-column align-items-start">
              <Form as="div"  className="fs-6 fw-bold text-dark">{items['transfer']['Trip'][0]['From']+' - '+items['transfer']['Trip'][0]['To']}</Form> 
              
            </Form> 
          </Col>
        </Row>
        <hr className="border-secondary m-0" />
  
        <Form as="div"  className="p-3">
          <Form as="div"  className="d-flex align-items-center my-2">
            <Users2 size={15} />
            <Form as="span"  className="mx-2">
            {items.transfer.Adult} {t("adults")}
            </Form> 
          </Form> 
          <Form as="div"  className="d-flex align-items-center my-2">
            <Baby size={15} />
            <Form as="span"  className="mx-2">
            {items.transfer.Child} {t("childs")}
            </Form> 
          </Form> 
           
        </Form> 
        <hr className="border-secondary m-0" />
  
        <Form as="div"  className="p-3">
           
          <Form as="div"  className="d-flex my-2">
            <ThumbsUp size={15} color="green" />
            <Form as="div"  className="mx-2">
              <Form as="span" >{t("great_value")}</Form> 
              <Form as="p"  className="text-secondary fw-medium">
                {t("great_value_text").replaceAll("@note@", "3.5")}
              </Form> 
            </Form> 
          </Form> 
        </Form> 
  
        <hr className="border-secondary m-0" />
        <Form as="div"  className="bg-secondary-subtle p-3 d-flex justify-content-between fs-6">
          <Form as="div"  className="text-capitalize">{t("total")}</Form> 
          <Form as="div" >
            <Form as="div"  className="text-end fw-bold text-dark">
            {money(items.totalAmount)} {items.currency}
            </Form> 
            <Form as="p"  className="text-success fw-medium"></Form> 
          </Form> 
        </Form> 
      </Form> 
    );
  }
  export default OrderSummary;
  