import Suppliers from './views/Suppliers'
import Home from './views/Home' 
function Main(props) {
    const composite = {
        home: <Home />,
        suppliers: <Suppliers />
    };
    return (
        <>{composite[props.component]}</>     

    );

}
export default Main;