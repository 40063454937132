import { useState } from "react";
import TopBanner from "../../components/TopBanner";
import { getShoppingCarToPaid } from "./Actions";
import Loader from "../../components/Loader";
import ItemShoppingCart from "./views/ItemShoppingCart";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Recap from "./views/Recap";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import Suggetions from './views/Suggetions'

const queryClient = new QueryClient()
function Home() {
  const { t } = useTranslation();
  return(
    <Form as="section" className="bg-body-tertiary" >
      <TopBanner data={t("cart")} cover="" />
      <Form as="div" className="container-xl p-3 my-3">
        <QueryClientProvider client={queryClient}>
           <LoadShoppingaCart/>
        </QueryClientProvider>
      </Form>
    </Form>
    )
}
function LoadShoppingaCart() {
  const { t } = useTranslation();
  const [total, setTotal] = useState(0 );
  const { isLoading, error, data } = useQuery('repoData', () => getShoppingCarToPaid())
  if (isLoading)    return <Loader />;
  if (error) return    <ShoppingaCartEmty  />
  if (data?.data?.status != true)  return <ShoppingaCartEmty  />
  var items = data.data.result 
  var count = items.count;
  if (count == 0)return <ShoppingaCartEmty />;

  return (
    
      <>
        <Row className="justify-content-between">
          <Col lg="8" xl="7">
            <ItemShoppingCart value={data} items={items.list} count={count}  />
          </Col>
          <Col lg="4" className="paymentstiky">
            <Recap items={items.list} count={count} total={total} ids={items.ids} />
          </Col>
        </Row>

        <hr className="border-secondary my-5"/>

        <Suggetions/>
      </>
  );
}

export default Home;

function ShoppingaCartEmty() {
  const { t } = useTranslation();
  return (
    <Row className="align-items-center justify-content-center py-5">
    <Col xl="2" className="my-3">
      <Form as="img" src="https://ouch-cdn2.icons8.com/1PQ8E4LduBL1KmzhZR4x401lzhekUCsCs9f29TKe0jw/rs:fit:368:519/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9wbmcvMTY2/LzlmZTNlMDRhLWMz/MDYtNGJmYi04YTNh/LWJiMjllYmY5OTE4/Mi5wbmc.png" width="100%" height="100%" />
    </Col>
    <Col xl="12" className="d-flex flex-column align-items-center my-3">
      <Form as="div" className="fs-2 text-dark fw-medium text-center">
        {t("empty_cart")}
      </Form>
      <Form as="p" className="fs-6 fw-light text-center">
        {t("add_items_to_cart")}.
      </Form>
    </Col>
  </Row>
  );
}
