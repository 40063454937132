import { send } from "../../global/axios";

export const  getShoppingCarToPaid = async ()=>{
return await send({service: "shoppingcart", action: "to_paid", data:{}});     
}

export const  getShoppingCarDeleted = async (id)=>{ 
const res = await send({service: "shoppingcart", action: "delete", data:{},urlAppend:[id]});  
if (res?.data?.status == true && res?.data?.result?.cancelled !='' && res?.data?.result?.cancelled ==true) {
window.location="/shoppingcart"
    }
}

export const  getGenerateFormPayment = async (ids)=>{ 
    return await send({service: "payment", action: "generateform_before", data:{bookingIds:ids}});  
   
    }