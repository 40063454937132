 
import Availability from "./forms/Availability";
import PromoHotel from "../promotions/PromoHotel";
import { useState } from "react";
import FormAvailability from "../../components/model/FormAvailability";
function Home() {
  const [component, setComponent] = useState(<PromoHotel/> );
  return (
    <FormAvailability component={component} forms={<Availability setComponent={setComponent}/>}/>
  );
}

export default Home;
