import { send } from "../../global/axios";


export  const getLogin = async (data) => {
return await send({ service: 'auth', action: "login", data: {data}, urlAppend:[] });  
 
  };
  export  const geRegister = async (data) => {
    return await send({ service: 'customer', action: "register", data: {data}, urlAppend:[] });  
     
      };
    
  
export  const getFindBooking = async (data) => {
  return await send({ service: 'booking', action: "findwithmail", data:data, urlAppend:[] });  
   
    };