import { send } from "../../global/axios";
 
 export const  getConfirmBooking = async (transactionId)=>{
    return await send({ service: "booking", action: "confirm", data: {}, urlAppend: [transactionId]  });
   }


   export const  getVoucher = async (bookingNumber)=>{
      return await send({ service: "booking", action: "voucher", data: {}, urlAppend: [bookingNumber]  });
     }
     
   


 