import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import IController from "../../components/forms/IController";
import ICountry from "../../components/forms/ICountry";
import SelectForm from "../../components/forms/SelectForm";
import { useTranslation } from "react-i18next";
import BookingContactForm from "../../components/model/BookingContactForm";

import IMask from "../../components/forms/IMask";
import { ContactRound } from "lucide-react";
var errors, register, control;

const BookingForm = ({ paxes, ctrl, airline }) => {
  errors = ctrl.errors;
  control = ctrl.control;
  register = ctrl.register;
  if (paxes.length == 0) {
    return;
  }
  return (
    <>
      {paxes.map((paxe, index) => (
        <PaxForm paxe={paxe} index={index} key={index} />
      ))}
      <BookingContactForm ctrl={ctrl} />
    </>
  );
};

export default BookingForm;

function PaxForm({ paxe, index }) {
  const { t } = useTranslation();

  var itemDate = {
    type: "text",
    className: "input-blue w-100 input-passenger border rounded",
    mask: "DD/MM/YYYY",
    rules: [
      ["D", /\d/],
      ["M", /\d/],
      ["Y", /\d/],
    ],
  };

  var item = {
    type: "text",
    className: "input-blue w-100 input-passenger border rounded uppercase",
    size: "md",
    rules: {
      required: t("mandatory"),
    },
  };

  return (
    <div
      className="my-3 bg-white rounded-4 border"
      key={index}
      style={{ overflow: "hidden" }}
    >
      <div
        className="fs-6 text-blue p-3 d-flex align-items-center border-bottom"
        htmlFor="genre"
      >
        <ContactRound strokeWidth={1.5} />{" "}
        <span className="mx-1 fw-light">
          {t(paxe.Type)} {index + 1}
        </span>
      </div>
      <Row className="p-3">
        <Col lg="3" className="my-3">
          <label className="fs-6 fw-light" htmlFor={paxe.PaxId + `.Gender`}>
            {t("gender")} <span className="text-danger">*</span>
          </label>
          <div className="mt-1">
            <SelectForm
              data={paxe.Gender}
              title="gender"
              elment={paxe.PaxId + `.Gender`}
              register={register}
            />
          </div>
        </Col>

        <Col lg="3" className="my-3">
          <label className="fs-6 fw-light" htmlFor={paxe.PaxId + `.Title`}>
            {t("title")} <span className="text-danger">*</span>
          </label>
          <div className="mt-1">
            <SelectForm
              data={paxe.Title}
              title="title"
              elment={paxe.PaxId + `.Title`}
              register={register}
            />
          </div>
        </Col>

        <Col lg="6" className="my-3">
          <label
            className="fs-6 fw-light"
            htmlFor={paxe.PaxId + `.Nationality`}
          >
            {t("nationality")} <span className="text-danger">*</span>
          </label>
          <div className="mt-1">
            <ICountry
              elment={paxe.PaxId + `.Nationality`}
              item={item}
              control={control}
              errors={errors}
              register={register}
            />
            <Error index={paxe.PaxId} attr="Nationality" title="nationality" />
          </div>
        </Col>
        <Col lg="6" className="my-3">
          <label className="fs-6 fw-light" htmlFor={paxe.PaxId + `.LastName`}>
            {t("last_name")} <span className="text-danger">*</span>
          </label>
          <div className="mt-1">
            <IController
              elment={paxe.PaxId + `.LastName`}
              item={item}
              control={control}
              errors={errors}
              register={register} 
            />
            <Error index={paxe.PaxId} attr="LastName" title="last_name" />
          </div>
        </Col>

        <Col lg="6" className="my-3">
          <label className="fs-6 fw-light" htmlFor={paxe.PaxId + `.FirstName`}>
            {t("first_name")} <span className="text-danger">*</span>
          </label>
          <div className="mt-1">
            <IController
              elment={paxe.PaxId + `.FirstName`}
              item={item}
              control={control}
              errors={errors}
              register={register} 
            />
            <Error index={paxe.PaxId} attr="FirstName" title="first_name" />
          </div>
        </Col>

        <Col lg="12" className="my-3">
          <label
            className="fs-6 fw-light"
            htmlFor={paxe.PaxId + `.DateOfBirth`}
          >
            {t("date_Of_birth")} <span className="text-danger">*</span>
          </label>
          <div className="mt-1">
            <IMask
              elment={paxe.PaxId + `.DateOfBirth`}
              item={itemDate}
              control={control}
              errors={errors}
              register={register}
            />
            <Error
              index={paxe.PaxId}
              attr="DateOfBirth"
              title="date_Of_birth"
            />
          </div>
        </Col>

        <Col lg="12" className="my-3">
          <label
            className="fs-6 fw-light"
            htmlFor={paxe.PaxId + `.PassportNumber`}
          >
            {t("passport_number")} <span className="text-danger">*</span>
          </label>
          <div className="mt-1">
            <IController
              elment={paxe.PaxId + `.PassportNumber`}
              item={item}
              control={control}
              errors={errors}
              register={register} 
            />
            <Error
              index={paxe.PaxId}
              attr="PassportNumber"
              title="passport_number"
            />
          </div>
        </Col>

        <Col lg="6" className="my-3">
          <label className="fs-6 fw-light" htmlFor={paxe.PaxId + `.ExpiryDate`}>
            {t("expiry_date")} <span className="text-danger">*</span>
          </label>
          <div className="mt-1">
            <IMask
              elment={paxe.PaxId + `.ExpiryDate`}
              item={itemDate}
              control={control}
              errors={errors}
              register={register}
            />
            <Error index={paxe.PaxId} attr="ExpiryDate" title="expiry_date" />
          </div>
        </Col>
        <Col lg="6" className="my-3">
          <label
            className="fs-6 fw-light"
            htmlFor={paxe.PaxId + `.PassportCountry`}
          >
            {t("passport_country")} <span className="text-danger">*</span>
          </label>
          <div className="mt-1">
            <ICountry
              elment={paxe.PaxId + `.PassportCountry`}
              item={item}
              control={control}
              errors={errors}
              register={register}
            />

            <Error
              index={paxe.PaxId}
              attr="PassportCountry"
              title="passport_country"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

function Error({ index, attr, title }) {
  const { t } = useTranslation();
  var txt = "";
  var c = "";
  if (errors[index]) {
    var e = errors[index];
    if (e[attr]) {
      txt = t(title) + " : " + t(e[attr]["message"]);
      c = " show";
    }
  }

  return (
    <Form as="span" className={"p-2 invalid-feedback" + c}>
      {txt}
    </Form>
  );
}
