import { MapPin, Bed, Users, Baby, Users2, Clock } from "lucide-react";
import { money } from "../../../global/global";
import { Col, Row, Form } from "react-bootstrap";
import { Panel } from "rsuite";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
const addDefaultImg = ev => {
  ev.target.src = process.env.REACT_APP_IMAGE_DEFAULT
}
const Recap = ({ hotel, param, rooms, price, extraIncluded }) => {
  const { t } = useTranslation();
  let itemRooms = [];
  let roomName;

  let travelers = 0;
  Object.keys(rooms).forEach((key) => {
    var room = rooms[key];
    travelers += (room.Adult + room.Child) * room.Count;
    roomName = room.Name;
    itemRooms.push(
      <Form as="div" key={key}>
        <Panel header={<Form as="div" className="fs-6 fw-bold text-dark">{room.Name}</Form>} collapsible defaultExpanded className="fs-6">
          <Form as="div" className="my-2 fs-6 d-flex justify-content-between flex-wrap align-items-center">
            <Form as="span" className="fw-light">
              {t("bed")} :
            </Form>
            <Form as="span" className="mx-1 text-capitalize text-dark">{room.Board}</Form>
          </Form>

          <Form as="div" className="my-2 fs-6 d-flex justify-content-between flex-wrap align-items-center">
            <Form as="span" className="fw-light">
              {t("adults")} :
            </Form>
            <Form as="span" className="mx-1 text-capitalize text-dark">{room.Adult}</Form>
          </Form>

          <Form as="div" className="my-2 fs-6 d-flex justify-content-between flex-wrap align-items-center">
            <Form as="span" className="fw-light">
              {t("childs")} :
            </Form>
            <Form as="span" className="mx-1 text-capitalize text-dark">{room.Child}</Form>
          </Form>
        </Panel>
      </Form>
    );
  });
  return (
    <>
      <Form as="div" className="rounded-4 border mt-3">
        <Form as="div" className="p-3">
          <Row>
            <Col xs="4">
              <img alt={hotel.name} className="rounded-4" width="100%" height="90" src={hotel.image} onError={addDefaultImg} />
            </Col>
            <Col xs="8">
              <Form as="div" className="fs-5 fw-bold text-dark text-capitalize">{hotel.name}</Form>
              <Form as="div" className="mt-1 d-flex align-items-center">
                <MapPin strokeWidth={1} size={20} />
                <Form as="span" className="mx-1 fs-6 fw-light text-capitalize">
                  {hotel.city_name}, {hotel.country_name}
                </Form>
              </Form>
            </Col>
          </Row>
          <Form as="div" className="mt-3 fs-6 d-flex justify-content-between flex-wrap align-items-center">
            <Form as="span" className="fw-light">{t("type")} : </Form>
            <Form as="span" className="mx-1 text-capitalize text-dark">{roomName}</Form>
          </Form>
        </Form>

        <hr className="my-1 border-secondary" />
        <Form as="div" className="text-dark">{itemRooms}</Form>
        <hr className="my-1 border-secondary" />
        <Duration param={param} hotel={hotel} travelers={travelers} />
        <hr className="my-1 border-secondary" />
        <ExtraIncluded items={extraIncluded} />
        <hr className="my-1 border-secondary" />
        <Coupon />
        <hr className="my-1 border-secondary" />
        <Total price={price} />
      </Form>
    </>
  );
};

export default Recap;

function Duration({ param, hotel, travelers }) {
  const { t } = useTranslation();

  return (
    <Panel header={<Form as="div" className="fs-6 fw-bold text-dark">{t("your_trip")}</Form>} collapsible className="fs-6">
      <Form as="div" className="my-2 fs-6 d-flex justify-content-between flex-wrap align-items-center fw-light">
        <Form as="span" className="fw-light">
          {t("check_in")} :
        </Form>
        <Form as="span" className="mx-1 text-capitalize text-dark">{param.dataPost.checkin}</Form>
      </Form>

      <Form as="div" className="my-2 fs-6 d-flex justify-content-between flex-wrap align-items-center fw-light">
        <Form as="span" className="fw-light">
          {t("check_out")} :
        </Form>
        <Form as="span" className="mx-1 text-capitalize text-dark">{param.dataPost.checkout}</Form>
      </Form>

      <Form as="div" className="my-2 fs-6 d-flex justify-content-between flex-wrap align-items-center fw-light">
        <Form as="span" className="fw-light">
          {t("travelers")} :
        </Form>
        <Form as="span" className="mx-1 text-capitalize text-dark">{travelers} {t('persons')}</Form>
      </Form>

      <Form as="div" className="my-2 fs-6 d-flex justify-content-between flex-wrap align-items-center fw-light">
        <Form as="span" className="fw-light">
          {t("duration")} :
        </Form>
        <Form as="span" className="mx-1 text-capitalize text-dark">{hotel.nights} {t('nights')}</Form>
      </Form>
    </Panel>
  );
}

function Coupon() {
  return (
    <Form as="div" className="my-0">
      <Panel className="m-0 p-0" header={<Form as="div" className="fw-bold fs-6 text-dark">{t("coupon_code")}</Form>} defaultExpanded collapsible>
        <Row className="d-flex align-items-center justify-content-between my-1">
          <Col xs="8">
            <input placeholder={t("coupon_code")} style={{ background: "#F7F8FA" }} className="input-blue p-3 px-5 rounded-5 border w-100" />
          </Col>
          <Col xs="4">
            <button className="btn-blue text-center py-3  mx-1 rounded-5 w-100">{t("apply")}</button>
          </Col>
        </Row>
      </Panel>
    </Form>
  );
}
function Total({ price }) {
  return (
    <>
      <Form as="div" className="d-flex justify-content-between fs-6 p-3">
        <Form as="span" className="fw-light">{t("subtotal")}</Form>
        <Form as="span" >
          {money(price)} {window.currency}
        </Form>
      </Form>
      <hr className="my-2 border-secondary" />
      <Form as="div" className="d-flex justify-content-between fs-5 fw-bold text-dark p-3">
        <Form as="span" className="fw-light">{t("total_price")}</Form>
        <Form as="span">
          {money(price)} {window.currency}
        </Form>
      </Form>
    </>
  );
}

function ExtraIncluded({ items }) {
  const { t } = useTranslation();
  if (items.length == 0) {
    return;
  }

  return (
    <>
      <Panel className="m-0 p-0" header={<Form as="div" className="fs-6 fw-bold text-dark">{t("tax_included")}</Form>} collapsible>
        {items.map((extra, index) => (
          <Form as="p" key={index} className="text-capitalize text-dark">
            {extra.Value}
          </Form>
        ))}
      </Panel>
    </>
  );
}
