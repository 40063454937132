import { Col, Form, Row } from "react-bootstrap";
import LoginRegister from "./views/LoginRegister";
import FindBooking from "./views/FindBooking";
import { useTranslation } from "react-i18next";
import { Check } from "lucide-react";
import TopBanner from "../../components/TopBanner";

 

function Home(props) {
    const { t } = useTranslation();
    return (
        <>
         <TopBanner title={t('travel')} data={t('bookings')} /> 
      
        <Form as="div" className="container border mb-3 mt-3 rounded-5"   >
       <Row>
        <Col md="4" className="p-3">
        <LoginRegister/>
        </Col>
        <Col md="4" className="border-left border p-3"><FindBooking/></Col>
        <Col md="4" className="p-3">

        <Form as="div" className="mt-3 fw-medium">
        <Form as="div" className="d-flex align-items-center my-2">
        <Check size={15} />
        <Form as="span" className="mx-2">
        {t('easily_check_change_or_cancel_your_booking')}
        </Form>
        </Form>

        <Form as="div" className="d-flex align-items-center my-2">
        <Check size={15} />
        <Form as="span" className="mx-2">
        {t('add_luggage_or_choose_your_seats_hotels_etc')}
        </Form>
        </Form>

        <Form as="div" className="d-flex align-items-center my-2">
        <Check size={15} />
        <Form as="span" className="mx-2">
        {t('get_all_your_boarding_passes_in_one_click')}
        </Form>
        </Form>


        <Form as="div" className="d-flex align-items-center my-2">
        <Check size={15} />
        <Form as="span" className="mx-2">
        {t('login_or_enter_your_booking_reference_to_manage_your_booking')}
        </Form>
        </Form>
        </Form>
      
        
        </Col>
       </Row>
       </Form>
       </>
    );

}
export default Home;