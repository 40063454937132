import { Row, Col, Form } from 'react-bootstrap' 
import IController from '../../components/forms/IController' 
import { useTranslation } from 'react-i18next'
import { ContactRound, NotepadText } from 'lucide-react';
 var errors,register,control;
const BookingContactForm = ({ctrl} ) => {
    errors=ctrl.errors
    control=ctrl.control
    register=ctrl.register
    const { t } = useTranslation();
    var item= {
      "type": "text",
      "className": "input-blue w-100 input-passenger border rounded",
      size: 'md',
      "rules": {
          required: t('mandatory')
      }
  }
    return (
        <>
            <Form as="div" className='my-3 bg-white rounded-4 border'> 
                <Form as="div"     className="fs-6 text-blue p-3 d-flex align-items-center border-bottom" >
                    <NotepadText strokeWidth={1.5} />{" "}
                    <Form as="span" className="mx-1 fw-light">
                    {t('contact_information')} 
                    </Form>
                </Form>
                <Row className='p-3'>
                    <Col lg="6" className='my-2'>
                        <Form as="label" className="fs-6 fw-light" htmlFor="phone">
                            {t("phone_number")} <Form as="span" className="text-danger">*</Form>
                        </Form>
                        <Form as="div" className="mt-1">
                            <IController  elment={"phone"} item={item} control={control} errors={errors}  register={register} />
                            <Error index={"phone"} title="phone_number"/>
                        </Form> 
                    </Col>
                    <Col lg="6" className='my-2'>
                        <Form as="label" className="fs-6 fw-light" htmlFor="email">
                         {t("email")} <Form as="span" className="text-danger">*</Form>
                        </Form>
                        <Form as="div" className="mt-1">
                            <IController  elment={"email"} item={item} control={control} errors={errors}   register={register} />
                            <Error index={"email"} title="email"/>
                        </Form>  
                    </Col>
                </Row>

            </Form>
            <Form as="div" className='my-3 bg-white rounded-4 border'> 
                <Form as="div"  className="fs-6 text-blue p-3 d-flex align-items-center border-bottom"  >
                    <ContactRound strokeWidth={1.5} />{" "}
                    <Form as="span" className="mx-1 fw-light">
                    {t('special_Requirements')} 
                    </Form>
                </Form>
                <Row className='p-3'>
                    <Col lg="12" className='my-2'>
                        <Form as="div">
                         <Form as="textarea"  id="comment" name="comment" {...register('comment')}    rows={4} className='input-blue w-100 p-4 border rounded'/>
                        </Form>
                    </Col>
                </Row>
            </Form>

            </>
    )
}

export default BookingContactForm


function Error({index,title}) {
    const { t } = useTranslation();
   var txt='';
   var c ='';
             if(errors[index]){
                txt=t(title)+' : '+t(errors[index]['message'])
                c= ' show'
             }

           return  <Form as='span' className={'p-2 invalid-feedback'+c} >{txt}</Form>

  }