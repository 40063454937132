import React, { lazy, Suspense } from 'react';
import HotelsMap from './Hotels.js'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { send } from '../../global/axios';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Frown } from 'lucide-react';
 
const queryClient = new QueryClient()
const Home = ({config}) => {
    
    return (
        <QueryClientProvider client={queryClient}>
        <LoadMap config={config}/>
        </QueryClientProvider>
      );
}
 
  
 

export default Home;

function LoadMapError() {
    const { t } = useTranslation();
    return(
        <Form as="div" className="px-4 py-5 my-5 text-center text-dark">
            <Form as="div" className="fs-1 fw-bold">
              <Frown size={50} strokeWidth={1.5} />
              <Form as="span" className="mx-1">{t("Loading map arror")}</Form>
            </Form>
            
          </Form>
      )

}
function LoadMap({config}) {
  
 
    const { isLoading, error, data } = useQuery('repoData', () => send(config.request.mapUrl))
    if (isLoading)   return  <Form as="div" >Page is Loading...</Form> ;
    console.log(error)
    if (error) return <LoadMapError/>
  
    if (data?.data?.status != true) {
      return <LoadMapError/>
    }
return  <HotelsMap  items={data?.data?.result?.items} center={data?.data?.result?.center} config={config} />    
 
    
     
     
}
