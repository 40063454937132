import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { AlarmClock, Clock, Heart, MapPin } from "lucide-react";
import { useTranslation } from "react-i18next";
import { PiHeartLight } from "react-icons/pi";
import { currencySymbol } from '../../../global/global'

const addDefaultImg = (ev) => {
  ev.target.src = process.env.REACT_APP_IMAGE_DEFAULT;
};
const Activity = ({ promotions }) => {

  if (!promotions?.list) {
    return;
  }
  if (promotions?.list.length == 0) {
    return;
  }
  return <>{Object.keys(promotions.list).map((category_index) => Object.keys(promotions.list[category_index]).map((category_sub_index) => <PromoList category_index={category_index} category_sub_index={category_sub_index} items={promotions.list[category_index][category_sub_index]} category={promotions.category[category_index]} sub_category={promotions.sub_category[category_sub_index]} key={"promo_lits_" + category_index + "_" + category_sub_index} />))}</>;
};

export default Activity;

function PromoList(props) {
  const { t } = useTranslation();
  var items = props.items;

  return (
    <Form as="section">
      <Row className="my-3">
        {items.map((item, index) => (
          <>
            <Col key={index} md="6" lg="4" className="my-3">
              <div role="button" className="border bg-white h-100 rounded-4">
                <img className="rounded-top-3" src={item.activity.image} onError={addDefaultImg} alt={`Illustration de l'activité - ${item.activity.name}`} width="100%" height="260" />
                <div className="p-3 d-flex flex-column justify-content-between">
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <Form as="span" className="d-flex align-items-center bg-secondary bg-opacity-10 p-1 rounded">
                        <span className="text-blue"><MapPin strokeWidth={1.5} size={15} /></span>
                        <Form as="span" className="mx-1 text-capitalize">
                        {item.city[window.lang]} , {item.country[window.lang]}
                        </Form>
                      </Form>
                      <span className="text-danger"><PiHeartLight size={20} /></span>
                    </div>
                    <div className="title-blue fs-5 fw-medium my-3">{item.activity.name}</div>
                    <Form as="div" className="d-flex align-items-center">
                      <span className="text-blue"><AlarmClock strokeWidth={1.5} size={20} /></span>
                      <Form as="span" className="mx-1 text-capitalize fw-light text-secondary">
                        {item.activity.durration}
                      </Form>
                    </Form>

                  </div>

                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <span className="fs-3 fw-light text-dark">  {item.activity.amount} {currencySymbol(item.activity.currency)}</span>
                    <span className="btn-outline-blue p-3 rounded-5">{t('view_details')}</span>
                  </div>
                </div>
              </div>
            </Col>
          </>
        ))}
      </Row>
    </Form>
  );
}
