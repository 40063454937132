import React from 'react'

const OfferComponent = ({ img, value,text, city }) => {
    return (
        <div className="my-3 position-relative" style={{ height: "140px" }}>
            <img style={{ filter: "brightness(0.7)" }} width="100%" height="100%" src={img} alt="Illustrationd e voyage" />
            <div className="position-absolute top-0 end-0 text-white">
                <div className="p-4 fs-5 fw-light text-end">
                    <div>Jusqu'à <span className="bg-blue p-2">-{value}%</span></div>
                    <div className="my-2">{text}</div>
                    <div>à {city}.</div>
                </div>
            </div>
        </div>
    )
}

export default OfferComponent
