import React from 'react'

const Textarea = ({id,rows,placeholder,value, handleChange, maxlength}) => {
    return (
        <div>
            <textarea id={id} rows={rows} className='input-blue w-100 p-4 border rounded' placeholder={placeholder} defaultValue={value} onChange={handleChange} maxLength={maxlength} />
        </div>
    )
}

export default Textarea