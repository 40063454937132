import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { money } from "../../../global/global";
import { IModal } from "../../../global/IModal";
import PaymentForm from "./PaymentForm";
import OfferComponent from '../../../components/OfferComponent'

function Recap({ count, total, ids, items }) {
    const { t } = useTranslation();
    return (
        <Form as="div" className="my-3 bg-white">
            <div className="border-blue" style={{ overflow: "hidden" }}>
                <div className='fs-4 fw-light text-blue p-3 text-decoration-underline'>0{count} {count <= 1 ? t('item') : t('items')}</div>

                <div className="px-3">
                    {items.map(item => (
                        <div className="d-flex justify-content-between align-items-center my-3 fw-light">
                            <span className="fs-6 text-capitalize w-75 fw-light">{item.service_name}</span>
                            <span className="fs-5 text-dark w-25 text-end">{item.amount_total} {window.currency}</span>
                        </div>
                    ))}
                </div>

                <Form as="div" className="d-flex align-items-center justify-content-between p-3 bg-secondary bg-opacity-10 border-top">
                    <Form as="div">
                        <div className="fw-light">{t('total_price')}</div>
                        <div className="fs-4 fw-bold text-dark">{money(total)} {window.currency}</div>
                        <div className="fw-light">{t('tax_included')}</div>
                    </Form>
                    {
                        count > 0 && (
                            <Form as="a" href='#' onClick={() => IModal(<PaymentForm ids={ids} />, t('go_to_payment'), "fs", 0)} className="btn-outline-blue text-blue p-3 rounded-5 fw-light">{t('go_to_payment')}</Form>

                        )}
                </Form>
            </div>
            <OfferComponent img="https://images.pexels.com/photos/2114206/pexels-photo-2114206.jpeg?auto=compress&cs=tinysrgb&w=600" value={5} text="de réductions" city="chaque résrevation" />
        </Form>

    );

}

export default Recap;