import { send } from "../../global/axios";
export function configSearch(dataPost,searchToken,t){
    const config = {
        dataPost: dataPost,
        service: 'transfer',
        searchToken: searchToken,
        messageFilter: t('no_transfer_matches_the_criteria_selected_filters'),
        request: {
            list: { service: 'transfer', action: "list", data: {},urlAppend:[searchToken,1] },
            filter: { service: 'transfer', action: "filter", data: { filter: [], searchToken: searchToken } },
            paginate: { service: 'transfer', action: "paginate", data: {} ,urlAppend:[searchToken,1]} ,
       },
        toolBarItems: [
            { id: "prices_transfer", name: t('price'), fiter_name: 'PRICES_ORDER' } 
        ],
        filterItems: [          
            { id: "name", title: t('vehicle'), action: 'GoReturn' } ,
            { id: "type", title: t('type'), action: 'GoReturn' } ,
        ]
    }

    return  config;
}


export const  getAvailability = async (dataPost)=>{
    return await send({ service: "transfer", action: "load", data: { dataPost: dataPost} });
   }
   
 
   
   export const  getChekRate= async (data,searchToken,RateKey)=>{
    return await send({ service: "transfer", action: "chek_rate", data: {travelCode: data},urlAppend:[searchToken,RateKey] });
   }
   
   export const  getdetail = async (searchToken,rateKey)=>{
    return await send({ service: "transfer", action: "detail", data: {}, urlAppend: [searchToken,rateKey]  });
   }
   export const  getList = async (searchToken)=>{
    return await send({ service: 'transfer', action: "list", data: {},urlAppend:[searchToken,1] });
   } 
 



 export  const addToPanier = async (data,searchToken,rateKey) => {
    
    const resp= await send({ service: 'transfer', action: "prebooking", data: {dataBook:data}, urlAppend:[ searchToken,rateKey] });  
   
    if (resp?.data?.status == true && resp?.data?.result?.preBooking !='' && resp?.data?.result?.preBooking !=null) {
    window.location="/shoppingcart"
    }else{
        alert('error not pabier')
    }
      
  };