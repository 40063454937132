import React, { useState, useEffect } from 'react';
import { ChevronRight, ChevronLeft, X } from 'lucide-react'

const ImagesCarousel = ({ showModal, closeModal,images }) => {

    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [showModal]);

    return (
        showModal && (
            <div className="modal">
                <div className="w-100 h-100 d-flex justify-content-center flex-column">
                    <span className='align-self-end mx-5 my-1 p-3 rounded-circle' style={{ background: "#FFFFFF", cursor: "pointer" }} onClick={closeModal}><X color='#282929' /></span>
                    <div className='d-flex align-items-center justify-content-around'>
                        <span className='d-none d-lg-block p-3 rounded-circle' style={{ background: "#ffffff1a", cursor: "pointer" }} onClick={handlePrevious}><ChevronLeft color='white' /></span>
                        <div onClick={handleNext} className='px-1 position-relative'>
                            <img alt={`Illustration de l'hôtel ${currentIndex}`} width="100%" height="100%" style={{ maxHeight: "650px", minWidth: "965px", maxWidth: "965px", cursor: "pointer" }} src={images[currentIndex]} />
                            <div className='d-none d-md-flex justify-content-center position-absolute w-100 text-white' style={{ bottom: '20px' }}><span className='py-3 px-4 rounded-5' style={{ background: "#00000036" }}>{currentIndex + 1} / {images.length}</span></div>
                        </div>
                        <span className='d-none d-lg-block p-3 rounded-circle' onClick={handleNext} style={{ background: "#ffffff1a", cursor: "pointer" }} ><ChevronRight color='white' /></span>
                    </div>
                </div>
            </div>
        )

    )
}

export default ImagesCarousel