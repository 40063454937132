import React, { useEffect, useState } from "react";
import Activity from "../../../pages/activities/forms/Availability";
import Transfer from "../../../pages/transfers/forms/Availability";
import Hotel from "../../../pages/hotels/forms/Availability";
import Flight from "../../../pages/flights/forms/Availability";
import { Form } from "react-bootstrap";

const MainForm = ({ setComponent }) => {

  const items = {
    flights: <Flight setComponent={setComponent} />,
    hotels: <Hotel setComponent={setComponent} />,
    activities: <Activity setComponent={setComponent} />,
    transfers: <Transfer setComponent={setComponent} />,
  };

  const [selectedButton, setSelectedButton] = useState("flights");

  const updateSelectedButton = () => {
    const storedButton = sessionStorage.getItem('selectedButton');
    if (storedButton) {
      setSelectedButton(storedButton);
    }
  };

  useEffect(() => {

    updateSelectedButton();

    window.addEventListener('storageChange', updateSelectedButton);

    return () => {
      window.removeEventListener('storageChange', updateSelectedButton);
    };
  }, []);

  return (
    <Form as="section" className="w-100 container">
      <div className="bg-white rounded-3 py-2">{items[selectedButton]}</div>
    </Form>
  )

}

export default MainForm;
