import { Form } from "react-bootstrap";
import { Panel, PanelGroup } from "rsuite";
const QuestionAnswers = ({items,searchQuery}) => {
    const currentLanguage = window.lang
  
      return(
        <PanelGroup accordion bordered className="bg-white">
                      {items.map((item ,h) => (
                        <Form as="div" key={h}>
                          {item.quetions.filter((question) => {
                              return question[currentLanguage]
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase());
                            }).map((questions,index) => (
                              <Form as="div" className="border-bottom" key={index}>
                                <Panel
                                  key={questions.id}
                                  header={
                                    <Form as="div" className="fs-5 p-3 text-dark fw-medium">
                                      {questions[currentLanguage]}
                                    </Form>
                                  }
                                >
                                  {questions.answers.map((answer,i) => (
                                    <Form as="p"  key={i}
                                      className="p-3 fs-6 fw-light"
                                      dangerouslySetInnerHTML={{
                                        __html: answer[currentLanguage],
                                      }}
                                    ></Form>
                                  ))}
                                </Panel>
                              </Form>
                            ))}
                        </Form>
                      ))}
                    </PanelGroup>
      )
}

export default QuestionAnswers;
