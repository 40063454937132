import React from 'react'
import TopBanner from '../../../components/TopBanner'
import { CircleCheck } from 'lucide-react'
import { Col, Row } from 'react-bootstrap'

const Voucher = () => {
    return (
        <div>
            <TopBanner data="voucher" />
            <div className='py-5 container px-lg-5'>
                <Row>
                    <Col xl="8">
                        <div className='border rounded-3 p-3'>
                            <div className='fs-5 fw-medium text-dark'>Booking informations</div>
                            <div className='p-3 bg-secondary-green text-dark my-3 d-flex align-items-center rounded'>
                                <span className='text-green'><CircleCheck strokeWidth={1.5} /></span> <span className='mx-1'>Congratulations! Your order was submitted successfully!</span>
                            </div>
                            <hr className='border-secondary' />
                            <Row className='fs-6'>
                                <Col xl="4">
                                    <div className='fw-light'>Full name</div>
                                    <div className='text-dark mt-2'>John doe</div>
                                </Col>
                                <Col xl="4">
                                    <div className='fw-light'>Email</div>
                                    <div className='text-dark mt-2'>John-doe@exemple.com</div>
                                </Col>
                                <Col xl="4">
                                    <div className='fw-light'>Phone</div>
                                    <div className='text-dark mt-2'>(+213) 552 149 575</div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xl="4">
                        <div className='border rounded-3 p-3 fs-6 h-100 d-flex flex-column justify-content-between'>
                            <div className='fs-5 fw-medium text-dark'>Summary</div>
                            <hr className='border-secondary m-0' />
                            <div>
                                <div className='d-flex justify-content-between align-items-center my-2'>
                                    <span className='fw-light'>Booking Date</span>  <span className='text-dark'>10/08/2024</span>
                                </div>
                                <div className='d-flex justify-content-between align-items-center my-2'>
                                    <span className='fw-light'>Booking Number</span>  <span className='text-dark'>16813</span>
                                </div>
                                <div className='d-flex justify-content-between align-items-center my-2'>
                                    <span className='fw-light'>Country</span>  <span className='text-dark'>France</span>
                                </div>
                                <div className='d-flex justify-content-between align-items-center my-2'>
                                    <span className='fw-light'>City</span>  <span className='text-dark'>Paris</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className='my-4'>
                    <Col xl="8">
                        <div className='border rounded-3 p-3'>
                            <div className='fs-5 fw-medium text-dark'>Payement details</div>
                            <div className='p-3 bg-primary bg-opacity-10 my-3 rounded'>
                                <Row>
                                    <Col xl="3">
                                        Description
                                    </Col>
                                    <Col xl="3">
                                        Prix
                                    </Col>
                                    <Col xl="3">
                                        Taxe
                                    </Col>
                                    <Col xl="3">
                                        Total
                                    </Col>
                                </Row>
                            </div>
                            <div className='p-3 bg-white py-3 borer-bottom'>
                                <Row>
                                    <Col xl="3">
                                        peace and love hostel

                                    </Col>
                                    <Col xl="3">
                                        184 EUR
                                    </Col>
                                    <Col xl="3">
                                        Aucune
                                    </Col>
                                    <Col xl="3">
                                        184 EUR
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Voucher
