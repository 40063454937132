import { dateFr } from '../../global/global'
import { format } from "date-fns";
const initialStateTransfer = {
    date: dateFr(new Date()),
    textSearch: '',
    tripTitle: 'round_trip',
    rangeDate: true,
    dataPost: {
        "Trip":"RT",
        "Adult":"2",
        "Child":0,
        "ChildAge":[],
        "Nationality":"DZ",
        "CountryOfResidence":"DZ",
        "From":{
            "Code":611,
            "Type":"A"
        },
        "To":{
            "Code":9710,
            "Type":"H"
        },
        "DateFrom":format(new Date(), 'yyyy-MM-dd HH:mm'),
        "DateReturn":format(new Date(), 'yyyy-MM-dd HH:mm')
    },
    "destination": {
        "From": "",
        "To": "",            
       } 
       ,
       availability: []
};
const transferReducer = (state = initialStateTransfer, action) => {

    switch (action.type) {
        case "data_post_transfer":
            return {
                ...state,
                dataPost: {
                    ...state?.dataPost,
                    ...action.payload,
                }

            };
        case "add_availability_transfer":
            return {
                ...state,
                availability: {
                    ...state?.availability,
                    ...action.payload,
                }

            };
        case "set_availability_transfer":
            return {
                ...state,
                availability: action.payload,
            };
            case "LOGOUT":
                return initialStateTransfer; 
        default:
            return state;
    }



};

export default transferReducer;
