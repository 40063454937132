import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PexelsImages = ({ city }) => {
  const [photos, setPhotos] = useState([]);
  const API_KEY = 'LP31ZUrT1YFGT4995GFjUztuNh9cGPMdBGUtwGEnUoAuYkAyEuVXmls5';
  const query = city;
  const url = `https://api.pexels.com/v1/search?query=${query}&per_page=10`;

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: API_KEY,
          },
        });
        setPhotos(response.data.photos);
      } catch (error) {
        console.error('Erreur lors de la récupération des images:', error);
      }
    };

    fetchPhotos();
  }, [url]);

  return (


    <>
      {photos.slice(2, 3).map(photo => (

        <img key={photo.id} width="100%" height="100%" style={{ minHeight: "200px", maxHeight: "200px" }} src={photo.src.tiny} alt={photo.photographer} />

      ))}
    </>

  );
};

export default PexelsImages;
