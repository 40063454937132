import { Typeahead } from 'react-bootstrap-typeahead';
const InputTypeahead = ({id,labelKey,placeholder,options,size,handleClick,className}) => {
    return (
      
                    <Typeahead              
                    id={id}                
                    labelKey={labelKey} 
                    clearButton={true}              
                    options={options}                    
                    placeholder={placeholder}                 
                    positionFixed={true}                   
                    useCache={true}                    
                    size={size} 
                    onChange={handleClick}       
                    className={className} 
                    />

    )
}
//  
export default InputTypeahead