import { QueryClient, QueryClientProvider,useQuery } from "react-query";
import TopDestinations from "./views/TopDestinations";
import Hotels from "./views/Hotels";
import Activity from "./views/Activity";
import {getPromotions } from "./Actions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
const queryClient = new QueryClient();

const PromoHome = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <LoadPromo />
    </QueryClientProvider>
  );
};

export default PromoHome;

function LoadPromo() {
    const [buttonActif, setButtonActif] = useState("hotels");
    const handleButton = (value) => {
    setButtonActif(value);
    };
    const { t } = useTranslation();


    const { isLoading, error, data } = useQuery("repoData", () => getPromotions());
    if (isLoading) return 
    if (error) return 
    if (data?.data?.status != true) return
  
    return(
      <>
{ /*     <TopDestinations items={data?.data?.result?.top} /> */}
      <Form as="div" className="py-5">
        <Form as="h2" className="text-dark fs-1 fw-medium text-center">{t("our_best_sellers")}</Form>
        <Form as="p" className="text-center fs-6 fw-light">{t("recommended_for_you")}</Form>
        <Form as="div" className="d-flex justify-content-center my-4">
          <Form as="button" className={`p-2 border px-4 mx-2 rounded ${buttonActif === "hotels" ? "btn-blue" : "bg-white"}`} onClick={() => handleButton("hotels")}>
            Hotels
          </Form>
          <Form as="button" className={`p-2 border px-4 mx-2 rounded ${buttonActif === "activities" ? "btn-blue" : "bg-white"}`} onClick={() => handleButton("activities")}>
            Activities
          </Form>
        </Form>
        {buttonActif === "hotels" && <Form as="div"> <Hotels promotions={data?.data?.result?.hotel} /></Form>}
        {buttonActif === "activities" && <Form as="div"><Activity promotions={ data?.data?.result?.activity} /></Form>}
      </Form>
     
      </>
    )
  } 
