import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const SearchBox = ({ searchQuery, setSearchQuery }) => {
  const { t } = useTranslation();

  return (
    <>
      <Form as="div" className="text-blue fs-6">
        {t("help_center")}
      </Form>
      <Form as="h2" className="text-dark">
        {t("frequently_asked_questions")}
      </Form>
      <Form as="p" className="my-3 fs-5 fw-light">
        {t("help_center_description")}.
      </Form>
      <Form as="div" className="my-3">
        <Form as="input" type="search" className="input-blue p-4 border w-100 fs-6" placeholder={t("question_search")} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
      </Form>
    </>
  );
};

export default SearchBox;
