import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const HoverEffectCols = () => {

    const { t } = useTranslation();

    const [activeCol, setActiveCol] = useState(null);
    const [shuffledData, setShuffledData] = useState([]);

    const style = {
        transition: "0.4s"
    }

    const data = [
        { titre : "Madrid", img : "https://www.radiustheme.com/demo/wordpress/themes/tripfery/wp-content/uploads/2024/01/2-min.webp" },
        { titre : "Bali", img : "https://www.radiustheme.com/demo/wordpress/themes/tripfery/wp-content/uploads/2024/01/3-min.webp" },
        { titre : "Paris", img : "https://images.pexels.com/photos/342113/pexels-photo-342113.jpeg?auto=compress&cs=tinysrgb&w=600" },
        { titre : "Tokyo", img : "https://images.pexels.com/photos/161251/temple-senso-ji-japon-kyoto-monument-historique-161251.jpeg?auto=compress&cs=tinysrgb&w=600" },
        { title: "Jordanie", img: "https://www.radiustheme.com/demo/wordpress/themes/tripfery/wp-content/uploads/2023/11/lc-1.jpg" },
        { title: "New-york", img: "https://www.radiustheme.com/demo/wordpress/themes/tripfery/wp-content/uploads/2023/11/lc-4.jpg" },
        { title: "Londres", img: "https://images.pexels.com/photos/77171/pexels-photo-77171.jpeg?auto=compress&cs=tinysrgb&w=600" },
        { title: "Thaïlande", img: "https://images.pexels.com/photos/3538245/pexels-photo-3538245.jpeg?auto=compress&cs=tinysrgb&w=600" },
        { title: "Rome", img: "https://images.pexels.com/photos/2225439/pexels-photo-2225439.jpeg?auto=compress&cs=tinysrgb&w=600" },
        { title: "Barcelone", img: "https://www.radiustheme.com/demo/wordpress/themes/tripfery/wp-content/uploads/2023/11/15.jpg" }, ]
        
    useEffect(() => {
        const shuffled = [...data].sort(() => Math.random() - 0.5);
        setShuffledData(shuffled.slice(0, 5));
    }, []);

    return (
        <div className='my-5'>
            <div className='fs-2 fw-bold text-dark'>{t('top_destinations')}</div>
            <p className='fs-6'>{t('top_destinations_caption')}</p>
            <Row className='mt-3'>
                {shuffledData.map((item, index) => (
                    <Col
                        key={index}
                        lg={activeCol === index || (activeCol === null && index === 0) ? 4 : 2}
                        onMouseEnter={() => setActiveCol(index)}
                        style={style}
                        className='my-3'
                    >
                        <div className='position-relative'>
                            <img className='rounded' src={item.img} width="100%" height="100%" style={{ minHeight: "460px", maxHeight: "460px", objectFit: "cover" }} alt="illustration ville" />
                            <div className='position-absolute top-0 d-flex justify-content-center align-items-center h-100 w-100 mt-5 pt-5 fs-4 fw-medium text-white'>{item.title}</div>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default HoverEffectCols;
