import { Dot } from 'lucide-react';
import React from 'react'

const RoomRecap = ({ selectedRoomData, t }) => {

    const options = [t('confirmation_is_immediate'), t('no_registration_required'), t('no_booking_credit_fess')]

    if (!selectedRoomData || typeof selectedRoomData !== 'object') {
        return <div className='p-3'><div className='fw-lighter fs-5'>{t('select_a_room')}</div><div className=' mt-3'>{options.map(item => <div key={item} className='my-1 fw-light'><Dot/> {item}</div>)}</div></div>;
    }

    const totals = Object.keys(selectedRoomData)
        .filter(key => key !== 'rateKey' && key !== 'amount')
        .reduce((acc, key) => {
            const room = selectedRoomData[key];
            if (room) {
                acc.adults += Number(room.Adult) || 0;
                acc.children += Number(room.Child) || 0;
            }
            return acc;
        }, { adults: 0, children: 0 });

    return (
        <div className='px-3'>
            {Object.keys(selectedRoomData)
                .filter(key => key !== 'rateKey' && key !== 'amount')
                .map(key => {
                    const room = selectedRoomData[key];
                    return room ? (
                        <div key={room.Code}>
                            <div className='d-flex justify-content-between fs-6 fw-light my-3'>
                                <span className='w-75'>
                                    <div>{room.Name}x{room.Count}</div>
                                </span>
                                <span className='fw-medium text-end text-dark w-25'>{room.Price} {window.currency}</span>
                            </div>
                        </div>
                    ) : null;
                })}
            <div className='d-flex justify-content-between fs-6 fw-light my-3 text-capitalize'>
                <span>
                    {t('travelers')}
                </span>
                <span className='fw-medium text-dark'>{parseInt(totals.adults) + parseInt(totals.children)}</span>
            </div>
        </div>
    );
}

export default RoomRecap;
