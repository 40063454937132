import { useParams } from "react-router-dom";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../../components/Loader";
import { Form } from "react-bootstrap";
import TopBanner from "../../components/TopBanner";
import { useTranslation } from "react-i18next";
 
import { lazy, Suspense } from "react";
import { getDetails } from "./Actions";
 
const queryClient = new QueryClient()

function Detail(props) {
    const { t } = useTranslation();
    const bookingNumber = useParams().bookingNumber;
    const token = useParams().token;
    return (
        <QueryClientProvider client={queryClient}>
           <Form as="section">
           <TopBanner title={'booking'} data={t("details")} cover="" />
          <LoadBooking bookingNumber={bookingNumber} token={token}/>
          </Form>
        </QueryClientProvider>
      );

}
function DetailError() {}
function LoadBooking({bookingNumber,token}){
    const { isLoading, error, data } = useQuery("repoData", () => getDetails(bookingNumber,token));
    if (isLoading) return <Loader />;
    if (error) return <DetailError />;
    if (data?.data?.status != true) {
      return <DetailError />;
    }
var item = data?.data?.result 
 
    const Component = lazy(() => import(`./views/${item.service }`));  

    return (
        <Suspense >
        {
        <Component 
            item={item}
            bookingNumber={bookingNumber}  
         />
        }
        </Suspense>
    )


}
export default Detail;