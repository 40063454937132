const SelectForm = ({ title, data, register, elment }) => {
  //custom-select 
    return (
      <div className="custom-select">
        <select name={elment} id={elment} {...register(elment)} className="input-blue w-100 input-passenger border rounded">
          {data.map((value, index) => (
            <option value={value} key={title + "-" + index}>
              {value}
            </option>
          ))}
        </select>
      </div>
    );
  };
  export default SelectForm;
  