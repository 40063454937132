import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Hotels from "./views/Hotels";
import {getPromotions} from "./Actions";
import TopDestinations from "./views/TopDestinations";
const queryClient = new QueryClient();
const PromoHotel = () => {
 
  return (
    <QueryClientProvider client={queryClient}>
      <LoadPromo />
    </QueryClientProvider>
  );
};

export default PromoHotel;

function LoadPromo() {
  const { isLoading, error, data } = useQuery("repoData", () => getPromotions());
  if (isLoading) return 
  if (error) return 
  if (data?.data?.status != true) return

  return  (
        <>
        <Hotels promotions={data?.data?.result?.hotel} />
        <TopDestinations items={data?.data?.result?.top} />
        </>

  )
}
