 
import { Controller } from "react-hook-form";
import { MaskedInput } from "react-hook-mask";
import React from 'react';
const IMask = ({elment, item,control,errors,register}) => {
    const [value, setValue] = React.useState('');
    var className = "" 
    if(errors[elment]){
        className =  " form-control-invalid  is-invalid"  
     }
   
     const maskGenerator = {
       rules: new Map(item.rules),
       generateMask: () => item.mask
     };
    return (
        <>
        <MaskedInput
        name={elment}
        {...register(elment)} 
        maskGenerator={maskGenerator}
      
        className={item?.className+className}   
        placeholder={item.mask}
        value={value}
        onChange={setValue}
      />
 
</>

    );
}

export default IMask