 
 
import { LuPlane , LuTrain,LuShip } from 'react-icons/lu'; 
import { RiHotelLine } from 'react-icons/ri';
function Transfer({item}) {
var icon=<LuTrain  size={23}/>
  switch(item?.type) {
    case 'A':
      icon = <LuPlane  size={23}/>
     break;
    case 'T':
      icon = <LuTrain  size={23}/>
      break;
      case 'S':
      icon = <LuTrain  size={23}/>
      break;
    case 'P':
    icon = <LuShip  size={23}/>
    break;
    default:
      case 'P':
        icon = <RiHotelLine  size={23}/>
        break;
      break;
  } 
 
 
    return (
        <div className="d-flex align-items-center">
              <div className='pe-2 d-flex justify-content-center'>{icon}</div>
              <div className="d-flex flex-column pl-2">
                <span className='title-blue text-start fw-bold my-2'>{item?.name}</span>
                <span className='mx-1 fs-13'>{item.country_name}</span>
              </div>
            </div>
      )
}

export default Transfer;
