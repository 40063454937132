import { Row, Col } from 'react-bootstrap'
import data from '../../data/blogdata.json'
import Pagination from '../../components/Pagination';

const FlexDisplay = () => {

  const renderSpecificItems = (items) => {

    return (
      <>
        <Row className='mt-3'>
          {items.map((article, index) => (
            <Col key={index} xs="12" className="my-3">
              <a href={`/blog/${article.id}`} className="text-dark text-decoration-none">
                <div className='bg-white rounded-4 border recommendations-item' style={{ overflow: "hidden" }}>
                  <Row>
                    <Col lg="4">
                      <div className='h-100' style={{ overflow: "hidden" }}>
                        <img alt={`Vue d'ensemble de l'activité - ${article.name}`} className='recommendations-item-img' width="100%" height="100%" src={article.img} />
                      </div>
                    </Col>

                    <Col sm="7" md="5" className='py-2'>
                      <div className='p-3 py-5 border-end d-flex flex-column justify-content-center align-items-start'>
                        <div style={{ fontSize: '18px' }} className='title-blue fw-bold my-2'>{article.name}</div>

                        <>
                          {article.type}
                        </>

                        <p className='my-3 fs-6'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                      </div>
                    </Col>

                    <Col sm="5" md="3">
                      <div className='d-flex flex-column align-items-center justify-content-center h-100'>
                        <div>{article.date}</div>
                        <button className='btn-blue my-3 py-3 px-4 rounded-5 fw-bold'>View details</button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </a>
            </Col>
          ))}
        </Row>
      </>
    );
  };

  return (
    <>
        <Pagination data={data.articles} itemsPerPage={9} renderItems={renderSpecificItems} />
    </>
  )
}

export default FlexDisplay