
const Input = ({ id, name, value, handleChange, placeholder, type,maxlength,onFocus}) => {

    return (


        <div>
            <input name={name} id={id} placeholder={placeholder} type={type} onChange={handleChange} value={value} className='input-blue w-100 p-4 border rounded' max={maxlength} maxLength={maxlength} onFocus={onFocus} />            
        </div>
    )
}

export default Input