import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import Availability from "./forms/Availability";
import { useTranslation } from "react-i18next";
import BookingForm from "./BookingForm";
import OrderSummary from "./views/OrderSummary";

import { useForm } from "react-hook-form";
import { ShoppingCart } from "lucide-react";
import { addToPanier, getDetails } from "./Actions";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../../components/Loader";
import MainForm from "../home/views/MainForm"

const queryClient = new QueryClient();
var searchToken, rateKey, carrierCode, airline, day, year, month;

function Book() {
  const { t } = useTranslation();
  searchToken = useParams().searchToken;
  rateKey = useParams().rateKey;
  airline = useParams().airline;
  carrierCode = useParams().carrierCode;
  day = useParams().day;
  month = useParams().month;
  year = useParams().year;
  return (
    <QueryClientProvider client={queryClient}>
      <Form as="section">
        <div className="bg-dark-blue py-4">
          <MainForm/>
        </div>
        <CheckRate />
      </Form>
    </QueryClientProvider>
  );
}

function CheckRate() {
  const { isLoading, error, data } = useQuery("repoData", () => getDetails(rateKey, searchToken, day, month, year));
  if (isLoading) return <Loader />;
  if (error) return <DetailError />;
  if (data?.data?.status != true) {
    return <DetailError />;
  }

  return <BookFlight flight={data.data.result} />;
}
function DetailError() { }

function BookFlight({ flight }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({});
  const ctrl = {
    control: control,
    register: register,
    errors: errors,
  };
  const onSubmit = async (data) => {
    await addToPanier(data, searchToken, rateKey, day, month, year);
  };

  return (
    <Form as="div" className="container-xl p-3 my-3">
      <Row>
        <Col lg="8" className="my-3">
          <Form as="div" className="fw-bold text-dark">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <BookingForm paxes={flight.paxes} ctrl={ctrl} airline={flight.airline} />
              <Form as="div" className="mb-3">
                <Button className="btn-blue p-3 px-4 rounded-5 mt-3 fs-6 text-white" type="submit">
                  <ShoppingCart /> {t("add_to_panier")}
                </Button>
              </Form>
            </Form>
          </Form>
        </Col>
        <Col lg="4" className="my-3" id="recap-flight">
          <OrderSummary flight={flight} />
        </Col>
      </Row>
    </Form>
  );
}


export default Book;
