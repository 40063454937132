import React, { useState, useEffect } from "react";
import { Col, Row, Placeholder, Form } from "react-bootstrap";
import { Check, Frown } from "lucide-react";
import { useTranslation } from "react-i18next";
import { getCheckRate, getRooms } from "../Actions";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { useNavigate } from "react-router-dom";
import RoomRecap from './RoomRecap';
import { Loader } from "rsuite";
import OfferComponent from "../../../components/OfferComponent";

const queryClient = new QueryClient();

function RoomsAvail({ hotelid, searchToken, name: hotelName, amenities,city }) {
  return (
    <QueryClientProvider client={queryClient}>
      <RoomsList city={city} hotelid={hotelid} searchToken={searchToken} hotelName={hotelName} amenities={amenities} />
    </QueryClientProvider>
  );
}

function LoadError() {
  const { t } = useTranslation();
  return (
    <Form as="div" className="px-4 py-5 my-5 text-center text-dark border rounded">
      <Form as="div" className="fs-1 fw-bold">
        <Frown size={50} strokeWidth={1.5} />
        <Form as="span" className="mx-1">{t("no_results")}</Form>
      </Form>
      <Form as="div" className="col-lg-6 mx-auto">
        <Form as="p" className="lead mb-4">{t("no_results_match")}</Form>
      </Form>
    </Form>
  );
}

function RoomsList({ hotelid, searchToken, hotelName, amenities,city }) {
  const { t } = useTranslation();
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedRoomData, setSelectedRoomData] = useState(null);
  const [visibleRoomsCount, setVisibleRoomsCount] = useState(parseInt(process.env.REACT_APP_ROOM_PAGINATION) || 5);
  const [loadingMore, setLoadingMore] = useState(false);
  const { isLoading, error, data, refetch } = useQuery('repoData', () => getRooms(hotelid, searchToken), { keepPreviousData: true });
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, [hotelid, searchToken, refetch]);

  if (isLoading) return <PlaceRoomHolder />;
  if (error || data?.data?.status !== true) return <LoadError />;

  const roomsAvail = data?.data?.result;

  const handleRadioChange = (rateKey) => {
    setSelectedRoom(rateKey);
    setSelectedRoomData({
      rateKey,
      ...roomsAvail.rooms[rateKey],
      amount: roomsAvail.priceRooms[rateKey]
    });
  };

  const loadMoreRooms = () => {
    setLoadingMore(true);

    setTimeout(() => {
      setVisibleRoomsCount((prevCount) => prevCount + parseInt(process.env.REACT_APP_ROOM_PAGINATION));
      setLoadingMore(false);
    }, 1000);
  };

  return (
    <>
      <h2 className="text-dark fs-3 fw-medium">{t("available_rooms")}</h2>
      <div className="my-3">
        <div className="bg-dark-blue text-white p-3 py-4">
          <Row>
            <Col lg="8">
              <Row className="justify-content-between">
                <Col xs="1">#</Col>
                <Col xs="11">
                  <Row>
                    <Col xs="8" sm="5" lg="4">{t('room_type')}</Col>
                    <Col sm="5" lg="3" className="d-none d-sm-block">{t('occupancy')}</Col>
                    <Col sm="3" className="d-none d-lg-block">{t('your_price_include')}</Col>
                    <Col xs="4" sm="2">{t('price')}</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col lg="4" className="d-none d-lg-block">
              {t('overview')}
            </Col>
          </Row>
        </div>

        <Row>
          <Col lg="8" className="my-3">
            <div className="border border-bottom-0 px-3 px-sm-0" style={{ overflow: "hidden" }}>
              {Object.entries(roomsAvail.priceRooms)
                .slice(0, visibleRoomsCount)
                .map(([rateKey], index) => (
                  <div key={index} className="border-bottom">
                    <Row>
                      <Col xs="1" className="d-flex justify-content-center align-items-center">
                        <input type="radio" name="selectedRoom"
                          onChange={() => handleRadioChange(rateKey)}
                          checked={selectedRoom === rateKey} />
                      </Col>
                      <Col xs="11">
                        <Rooms
                          amenities={amenities}
                          roomsType={roomsAvail.rooms[rateKey]}
                          rateKey={rateKey}
                          selectedRoomData={selectedRoomData}
                        />
                      </Col>
                    </Row>
                  </div>
                ))}
            </div>

            {visibleRoomsCount < Object.entries(roomsAvail.priceRooms).length && (
              <div className="mt-3 text-center">
                {loadingMore ? (
                  <Loader />
                ) : (
                  <button onClick={loadMoreRooms} className="btn-outline-blue p-3 px-4 rounded-5 fs-6 fw-light">{t("view_more")}</button>
                )}
              </div>
            )}
          </Col>
          <Col lg="4" className="my-3">
            <div className="border-blue" style={{ overflow: "hidden" }}>
            
              <div className="p-3">
                <div className="fs-3 fw-medium text-dark text-capitalize">{hotelName}</div>
                <div className='fs-5 fw-light text-blue text-decoration-underline'>{t('your_price_details')}</div>
              </div>
              <RoomRecap t={t} selectedRoomData={selectedRoomData}/>
              <Form as="div" className="d-flex align-items-center justify-content-between p-3 bg-secondary bg-opacity-10 border-top">
                <Form as="div">
                  <div className="fw-light">{t('total_price')}</div>
                  <div className="fs-4 fw-bold text-dark">{selectedRoomData && selectedRoomData.amount} {window.currency}</div>
                  <div className="fw-light">{t('included')}</div>
                </Form>
                <button
                  disabled={!selectedRoomData}
                  onClick={() => getCheckRate(hotelName, hotelid, selectedRoom, searchToken, navigate)}
                  className={`fs-6 fw-light py-3 px-4 rounded-5 ${selectedRoomData ? "btn-blue" : "bg-white border-blue text-blue"}`}
                >
                  {t("reserve")}
                </button>
              </Form>
           
            </div>

            <OfferComponent img="https://images.pexels.com/photos/870170/pexels-photo-870170.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" value={8} text="sur votre réservation d'activité" city={city}/>
          </Col>
        </Row>
      </div>
    </>
  );
}

function Rooms({ roomsType, amenities }) {
  const { t } = useTranslation();
  const [randomAmenities, setRandomAmenities] = useState([]);

  useEffect(() => {
    const selectedAmenities = amenities.sort(() => Math.random() - 0.5).slice(0, 3);
    setRandomAmenities(selectedAmenities);
  }, [amenities]);

  return (
    <Form as="div" className="py-2" style={{ overflow: "hidden" }}>
      {roomsType.map((room, index) => (
        <Form as="div" key={index} className="py-3 fw-light">
          <Row className="justify-content-between align-items-center">
            <Col xs="8" sm="4">
              <Form as="div" className="fs-6 fw-light text-blue text-break">{room.Name}</Form>
            </Col>
            <Col sm="3" className="d-none d-sm-block">
              <div>{room.Adult} {t('adults')}, {parseFloat(room.Child)} {t("childs")}</div>
              <div>{room.Board}</div>
            </Col>
            <Col sm="3" className="d-none d-lg-block">
              {randomAmenities.map((item, idx) => (
                <div key={idx} className="d-flex align-items-center my-1 fw-light text-green">
                  <Check size={15} />
                  <span className="mx-1 text-capitalize">{item}</span>
                </div>
              ))}
            </Col>
            <Col xs="4" sm="2">
              <span className="fw-medium fs-6">{room.Price} {window.currency}</span>
            </Col>
          </Row>
        </Form>
      ))}
    </Form>
  );
}

function PlaceRoomHolder() {
  return (
    <Row className="mt-2">
      {Array.from({ length: 3 }).map((_, index) => (
        <Col className="p-3" lg="4" md="6" key={`room-place-holder-${index + 1}`}>
          <Placeholder as="div" animation="wave" style={{ height: "200px" }}>
            <Placeholder xs={12} style={{ height: "200px" }} />
          </Placeholder>
          <Placeholder className="text-dark" as="div" animation="wave">
            <Placeholder xs={12} />
          </Placeholder>
          <Placeholder className="text-dark" as="div" animation="wave">
            <Placeholder xs={12} />
          </Placeholder>
        </Col>
      ))}
    </Row>
  );
}

export default RoomsAvail;