import {
  Clock,
  Flag,
  Baby,
  Users2,
  ThumbsUp,
  CalendarX2,
  MapPin,
} from "lucide-react";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { dateFr, money } from "../../../global/global";
function OrderSummary({ item }) {
  const { t } = useTranslation();
  return (
    <Form as="div" className="my-3 border border-2">
      <Row className="p-3">
        <Col lg="3">
          <Form as="div" >
            <img
              className="rounded-3"
              width="100%"
              height="100%"
              alt={`${item.activity.Name}`}
              src={item.activity.Image}
            />
          </Form>
        </Col>
        <Col lg="9">
          <Form as="div" className="d-flex flex-column align-items-start">
            <Form as="div" className="fs-6 fw-bold text-dark">{item.activity.Name}</Form>
            <Form as="div" className="bg-secondary-subtle p-1 rounded mt-2">
              <MapPin size={20} strokeWidth={1.5} /> {item.activity.City},{" "}
              {item.activity.Country}
            </Form>
          </Form>
        </Col>
      </Row>
      <hr className="border-secondary m-0" />

      <Form as="div" className="p-3">
        <Form as="div" className="d-flex align-items-center my-2">
          <span className="text-blue">
            <Users2 size={15} />
          </span>
          <Form as="span" className="mx-2">
            {item.category.Adult} {t("adults")}
          </Form>
        </Form>
        <Form as="div" className="d-flex align-items-center my-2">
          <span className="text-blue">
            <Baby size={15} />
          </span>
          <Form as="span" className="mx-2">
            {item.category.Child} {t("childs")}
          </Form>
        </Form>
        <Form as="div" className="d-flex align-items-center my-2">
          <span className="text-blue">
            <CalendarX2 size={15} />
          </span>
          <Form as="span" className="mx-2">
            {t("date_from")} : {dateFr(item.dateFrom)}
          </Form>
        </Form>
        <Form as="div" className="d-flex align-items-center my-2">
          <span className="text-blue">
            <Clock size={15} />
          </span>
          <Form as="span" className="mx-2">
            {t("duration")} : {item.category.NumberOfDay} {t("day")}
          </Form>
        </Form>
      </Form>

      <hr className="border-secondary m-0" />
      <Form as="div" className="bg-secondary-subtle p-3 d-flex justify-content-between fs-6">
        <Form as="div" className="text-capitalize">{t("total")}</Form>
        <Form as="div" >
          <Form as="div" className="text-end fw-bold text-dark">
            {window.currency} {money(item.totalAmount)}
          </Form>
          <Form as="p" className="text-success fw-medium"></Form>
        </Form>
      </Form>
    </Form>
  );
}
export default OrderSummary;
