import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { send } from "../../../global/axios";
import { dateUrl, iqueryClient} from "../../../global/global"; 
import { Col, Row,Form } from "react-bootstrap";
import { getCategory} from "../Actions";
import {QueryClientProvider, useQuery } from "react-query";
import { PaginationControl } from 'react-bootstrap-pagination-control';

var images=[]
var activity
var priceCategory
var category
var totalItem =0
var itemsPerPage = 5;
var searchToken

function Category(props) {
 
  return (
    <QueryClientProvider client={iqueryClient()}>
      <LoadCategoty  activityCode={props.activityCode}     searchToken={props.searchToken}/>
      </QueryClientProvider>
 )

}
 
function LoadCategoty(props) {
  searchToken=props.searchToken
 const { isLoading, error, data } = useQuery("repoData", () => getCategory(props.activityCode,props.searchToken)); 
  if (isLoading) return <LoadingOrError message="Loading....."/>;
  if (error) return <LoadingOrError message="Error"/>;  
   const activityCategory =data?.data?.result
  if (!activityCategory.activity) {
    return (<LoadingOrError message="Error"/>)
  }
 
  totalItem     = activityCategory.count;
  images        = activityCategory.images;
  activity      = activityCategory.activity;
  priceCategory = activityCategory.priceCategory;
  category      = activityCategory.category;
   return <PrintCategory/>

}

function PrintCategory() {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage]           = useState(1);
  const getCurrentPageCategory = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return Object.entries(priceCategory)
      .map(([rateKey, amount]) => rateKey)
      .flat()
      .slice(startIndex, endIndex);
  };
    
  function Paginate() {
    return (
      <PaginationControl
        page={currentPage}
        between={3}
        total={totalItem}
        limit={itemsPerPage}
        changePage={(page) => {
          setCurrentPage(page);
        }}
        ellipsis={1}
      />
    );
  }
  
  return (
      <Form as="div"  className="box-search p-2">   
        <Form as="p"  className="fs-3 fw-medium text-dark">{t('availability')}</Form> 
        <Form as="div"  className="bg-dark-blue text-white p-3 mt-3">
          <Row>
            <Col  md="6" lg="3" >{t('category')}</Col>
            <Col  md="6" lg="3" >{t('date')}</Col>
            <Col  md="6" lg="2" >{t('duration')}</Col>
            <Col  md="6" lg="2" >{t('price')}</Col>
          </Row>
        </Form> 
        {
          getCurrentPageCategory().map((rateKey, index) => (
            <Col lg="12" xl="12" key={rateKey}>
              <CategoryList  rateKey={rateKey} index={index}   />
            </Col>
          ))
        } 
        <Paginate/>
      </Form> 
  )
     
}

export default Category ;

function CategoryList({  rateKey, index }) {
    const { t }  = useTranslation();   
    var item     = category[rateKey]
    var amount = item.Price
    const [price, setPrice]     = useState(priceCategory[rateKey]);
    var duration=[]
    for (let i = item.MinimumDuration; i <=item.MaximumDuration; i++) {
      duration.push(i)
    }
 

const handleChangeDuration = (event) => {
  let value = parseInt(event.target.value)
  priceCategory[rateKey]=amount * value
  item.NumberOfDay = value
  setPrice(priceCategory[rateKey])
};

const handleChangeDate = (event) => {
  item.DateFrom = event.target.value 
};
return (
  <Form as="div"  className="my-3">
    <Row className="align-items-center">
      <Col  md="6" lg="3" className="my-1">
        <select className="input-blue p-3 w-100" key={'name-'+rateKey}>
          <option selected="">{item.Name}</option> 
        </select>              
      </Col>
      <Col  md="6" lg="3" className="my-1"> 
        <select className="input-blue p-3 w-100" onChange={handleChangeDate}  key={'date-'+rateKey} defaultValue={item.DateFrom}>
          {
            item.OperationDates.map((date, index) => (
             <option value={date} key={index}>{date}</option>
            ))
          }
        </select>
      </Col>
      <Col  md="6" lg="2" className="my-1">
        <select className="input-blue p-3 w-100" onChange={handleChangeDuration} defaultValue={item.NumberOfDay} key={'duration-'+rateKey}>
        {
          duration.map((val, index) => (
           <option  key={index} value={val}>{val}</option>
          ))
        }
        </select>
      </Col>
      <Col  md="6" lg="2" className="my-1">
        <label type="button" className="label "  key={'price-label-'+rateKey}>
          <Form as="span"  key={'price-'+rateKey}>{price}</Form>    {item.Currency}
        </label>
      </Col>
      <Col md="6" lg="2" className="my-1">
        <button type="button" className="btn-blue p-3 rounded-5 w-100"  onClick={() =>CheckRate(rateKey)}>
         {t('select')}
        </button>
      </Col>
    </Row>
  </Form> 
) 
}

export const CheckRate = async (rateKey) => {
  var item     = category[rateKey]
   const Req = { service: "activity", action: "chek_rate", data: {}, urlAppend: [dateUrl(item.DateFrom),activity.ActivityCode,item.NumberOfDay ,searchToken,rateKey] };
  const res = await send(Req); 
   if (res?.data?.status == true) {
      var url=['/activities', searchToken,dateUrl(item.DateFrom),activity.Country,activity.City.replaceAll('/', ' '),activity.Name.replaceAll('/', ' '),activity.ActivityCode,item.NumberOfDay,rateKey]
      window.location=url.join('/')
   }
}
function LoadingOrError({message}) {
    return (
        <Form as="div"  className="container p-2">
            <Form as="div"  className="hero">
                <Form as="div"  className="px-4 py-5 my-5 text-center">
                    <h1 className="display-5 fw-bold">{message}</h1>
                </Form> 
            </Form> 
        </Form> 
    )
} 

//url=