import React from "react";
import { money, dateFr } from "../../global/global";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { ShieldX } from "lucide-react";

function CancelPolicy({ items }) {
  const { t } = useTranslation();
  if (items.length==0) {
    return;
  }
  return (
    <Form as="div" className="my-3 rounded-4 border"
    style={{ overflow: "hidden" }}>
      <div
        className="fs-6 text-blue p-3 d-flex align-items-center border-bottom"
        htmlFor="genre"
      >
        <ShieldX strokeWidth={1.5} />{" "}
        <span className="mx-1 fw-light">
          {t("cancel_policy")}
        </span>
      </div>
      <div className="p-3">
        {items.map((item, index) => (
          <Form as="div" className="fs-6 px-3 my-2" key={index}>
            <Form as="span">{t("cancel_text")}</Form>
            <Form as="span" className="mx-1">{dateFr(item.Date, "datetime")}</Form>
            <Form as="span" className="mx-1">{t("cancel_text2")}</Form>
            <Form as="span" className="mx-1 fw-bold text-dark">
              {money(item.Amount)} {window.currency}
            </Form>
          </Form>
        ))}
      </div>
    </Form>
  );
}

export default CancelPolicy;
