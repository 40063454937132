import { BiMap } from 'react-icons/bi'
 
function Activity({item}) {
 
    return (
        <div className="d-flex align-items-center">
              <div className='pe-2 d-flex justify-content-center'><BiMap  size={23}/></div>
              <div className="d-flex flex-column pl-2">
                <span className='title-blue text-start fw-bold my-2'>{item?.city}</span>
                <span className='mx-1 fs-13'>{item?.province === '' ?' ':item?.province+' , '} {item.country_name}</span>
              </div>
            </div>
      )
}

export default Activity;
