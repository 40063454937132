import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Baby, Car, Dog, LogIn, LogOut, ShieldAlert, Wifi } from 'lucide-react';
import { useTranslation } from 'react-i18next';

function Policy(props) {
    const { t } = useTranslation();
    const { hotel } = props;
    const policy = hotel?.policy || {};

    const policyIcons = {
        Groups: <ShieldAlert strokeWidth={1.5} />,
        Internet: <Wifi strokeWidth={1.5} />,
        Parking: <Car strokeWidth={1.5} />,
        Pets: <Dog strokeWidth={1.5} />
    };

    return (
        <div className='fs-6 fw-light my-3'>
            <div className='text-dark fs-4 fw-medium'>{t('politique_conditions')}</div>

            <div className='my-3'>
                <Row className='align-items-start py-3'>
                    <Col xs="3">
                        <span className='text-blue'><LogIn strokeWidth={1.5} /></span>
                        <span className='mx-3'>{t('arrival')}</span>
                    </Col>
                    <Col xs="9">
                        <span dangerouslySetInnerHTML={{ __html: hotel?.checkin_time || 'Not assigned' }}></span>
                    </Col>
                </Row>

                <Row className='align-items-start py-3'>
                    <Col xs="3">
                        <span className='text-blue'><LogOut strokeWidth={1.5} /></span>
                        <span className='mx-3'>{t('departure')}</span>
                    </Col>
                    <Col xs="9">
                        <span dangerouslySetInnerHTML={{ __html: hotel?.checkout_time || 'Not assigned' }}></span>
                    </Col>
                </Row>

                {Object.entries(policy).map(([key, value]) => (
                    <Row key={key} className='align-items-start py-3'>
                        <Col xs="3">
                            <span className='text-blue'>{policyIcons[key] ? policyIcons[key] : <Baby strokeWidth={1.5} />}</span>
                            <span className='mx-3'>{t(key)}</span>
                        </Col>
                        <Col xs="9">
                            <span dangerouslySetInnerHTML={{ __html: value }}></span>
                        </Col>
                    </Row>
                ))}
            </div>
        </div>
    );
}

export default Policy;
