import { Card, Col, Form, NavDropdown, Row } from "react-bootstrap"; 
import { Suspense, lazy, useEffect, useState } from "react";
import { Filter, FilterX, Frown,LayoutGrid, Map, Minus, SlidersHorizontal, StretchHorizontal, ChevronDown } from "lucide-react";
import { useTranslation } from "react-i18next";
import { createRoot } from "react-dom/client";
import PrintAvailability from "./PrintAvailability.js";
import { send } from "../../global/axios";
import { IModal } from "../../global/IModal";
import HomeMap from "../map/Home"
import { PaginationControl } from "react-bootstrap-pagination-control";
import { ButtonToolbar, Dropdown, Panel, RangeSlider } from "rsuite";
import { scroll } from "../../global/global";
var config,result,ActiveDisplay,totalItem,setTotalItem,rootPaginate,rootPaginateControl,countRequest, setCountRequest,page, setPage, ActiveDisplay
var startPrice=0



export function SideNavAvailability(props) {
    config = props.config
    result = props.result
 
    const { t } = useTranslation();
    const [show, setShow] = useState("");
    const handleClose = () => setShow("");
    const handleShow = () => setShow("visibility");    
    [page, setPage] = useState(1);
    [totalItem, setTotalItem] = useState(props.result.count);
    ActiveDisplay = "flex";
    [countRequest, setCountRequest] = useState(result.countRequest);


    //lancer la recherche de nouveau prix
    const startCountHandler = async () => {
      availPrice();
    };
 
    
  
    useEffect(() => { 
      (async () => {     
        if (countRequest > 0) {
       startCountHandler();
        }
      })();
    }, []);
 
 

    return (
<>       <SidebarOffCaneva show={show} handleClose={handleClose}/>
            <Form as="div" className="body-flex"> 
                <Form as="div" className="holygrail-body">
                    <Form as="div" className="flex-nav-hy w50 p-3">
                        <ToolBar handleShow={handleShow}/>
                    
                    <Form as="div" id="availability-container"  >
                    <Print items={props.result.avails}  />    
                    </Form> 
                    <Form as="div" id="paginate-control"> <Paginate/> </Form>
                    </Form>
                    <Form as="div" className="flex-content w50">
                    <HomeMap config={config}  />
                    </Form>
                </Form>
         
            </Form>

            </>
    )

    
}

 
const handleClick = async (bouton) => {   
 ActiveDisplay= bouton
 setPrinter(  result.avails)
}

function Print({items}) {
   
  if (result.avails.length == 0) {
     return <NoResult/>;
  }  
  return <PrintAvailability items={items} activeDisplay={ActiveDisplay} component={config.service} dataPost={config.dataPost} searchToken={config.searchToken} />;
}


export default Print;

function NoResult() {
  const { t } = useTranslation();
  return (
    <Form as="div" className="container p-5 mt-5">
      <Form as="div" className="hero">
        <Form as="div" className="px-4 py-5 my-5 text-center text-dark">
          <Form as="div" className="fs-1 fw-bold">
            <Frown size={50} strokeWidth={1.5} />
            <Form as="span" className="mx-1">{t("no_results")}</Form>
          </Form>
          <Form as="div" className="col-lg-6 mx-auto">
            <Form as="p" className="lead mb-4">{t("no_results_match")}</Form>
            <Form as="div" className="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <Form as="button" onClick={reset} type="button" className="btn-orange px-4 p-3">
                {t("reset")}
              </Form>
            </Form>
          </Form>
        </Form>
      </Form>
    </Form>
  );
}


function  ToolBar({handleShow}) {  
  
  const { t } = useTranslation();




  return(
    <Form as="div" className="d-flex justify-content-between align-items-center bg-white border p-3 rounded-4">
    <Form as="span" onClick={handleShow} className="btn btn-light border rounded-5 d-flex align-items-center">
    <SlidersHorizontal className="me-1" strokeWidth={1} size={15} />
    {t("filter")}
    </Form>
    <Form as="span" style={{ fontSize: "16px" }} className="fw-light fs-6">
    {totalItem} {t("results_found")}
    </Form>
    <Form as="div" className="d-none d-md-block">
    <Form as="span" title={t("list")}>
        <StretchHorizontal onClick={() => handleClick("flex")} style={{ cursor: "pointer", color: ActiveDisplay === "flex" ? "#3B71FE" : "" }} size={20} strokeWidth={1} className="mx-1" />
    </Form>
    <Form as="span" title={t("grid")}>
        <LayoutGrid onClick={() => handleClick("grid")} style={{ cursor: "pointer", color: ActiveDisplay === "grid" ? "#3B71FE" : "" }} size={20} strokeWidth={1} className="mx-1" />
    </Form>
  
      
    </Form>
</Form>
  )

 
}
function SidebarOffCaneva({ show,handleClose }) {
  const { t } = useTranslation();
  return (
    <Card className={"filter-offcaneva  " + show} key={'SidebarOffCaneva'}>
      <Card.Header>
        <Card.Title>
          <Filter size={20} color="#3B71FE" /> {t("filter")}
          <Form as="button" type="button" className="btn-close float-right" onClick={handleClose} aria-label="Close"></Form>
        </Card.Title>
      </Card.Header>
      <Card.Body className="w-100 overflow-auto">{config.filterItems.map((item, key) => <MakeFilter key={'makefilter-a-'+key}  item={item} suffix="a_"/>)}</Card.Body>
    </Card>
  );
}

 


function MakeFilter(props) { 


  if(result.filter[props.item.id].length==0){return}
 
  const ComponentFilter = lazy(() => import(`./filter/${props.item.action }`));  
  
  return (
      <Suspense >
      {
      <ComponentFilter 
          item={result.filter[props.item.id]}
          list={result.filter_list[props.item.id]} 
          suffix={props.suffix}   
          title={props.item.title} 
          id={props.item.id} 
          check={check}
       />
      }
      </Suspense>
  )

}


function SliderSPrice() {
  const { t } = useTranslation();
  var items = [];
  Object.keys(result.filter.prices).forEach((key) => {
    items.push(parseFloat(result.filter.prices[key]));
  });
  const min = Math.min(...items);
  const max = Math.max(...items);
  const [PriceValue, setPriceValue] = useState([min, max]);
  const handleSliderChange = (values) => {
    setPriceValue(values);
  };
  const handleSliderChangeComplete = () => {
    var data = { PRICES_SLIDERS: PriceValue };
    getFilter(data);
  };
  return (
    <Form as="div" className="p-2 border rounded-4 bg-white my-3">
      <Panel
        defaultExpanded
        
        header={
          <Form as="div">
            <Form as="div" className="fw-medium fs-5">{t("total_price")}</Form>
            <Minus size={30} color="#3B71FE" strokeWidth={3} />
          </Form>
        }
         >
        <>
          <Form as="div" className="my-3">
            <RangeSlider min={min} max={max} defaultValue={[min, max]} constraint={([start, end]) => start <= max && end >= min} onChange={handleSliderChange} onChangeCommitted={handleSliderChangeComplete} />
          </Form>
          <Form as="div" className="d-flex justify-content-between align-items-center py-3">
            <Form as="span" className="bg-light border rounded-3 p-2 w-50">
              <Form as="div">{t("min_price")}</Form>
              <Form as="div">
               {PriceValue[0]} {window.currency}
              </Form>
            </Form>
            <Form as="span" className="mx-2">-</Form>

            <Form as="span" className="bg-light border rounded-3 p-2 w-50">
              <Form as="div">{t("max_price")}</Form>
              <Form as="div">
               {PriceValue[1]} {window.currency}
              </Form>
            </Form>
          </Form> 
        </>
      </Panel>
    </Form>
  );
}


  const orderBy = async (event) => {
  var data = { ORDERBY: [event.target.dataset.filter, event.target.dataset.order] };
   getFilter(data);
};
  const reset = async () => {
  var data = { RESET: "RESET" };
  getFilter(data);
};
  const check = async (event) => {
    const id = event.target.id;
    let s = id.split("_");
    if (s[0] == "a") {
      document.getElementById(s[1]).checked = event.target.checked;
    } else {
      document.getElementById("a_" + id).checked = event.target.checked;
    }
    let selected = [];
    var form = document.getElementById("form-filter-availability");
    var elms = form.querySelectorAll('input[type="checkbox"]:checked');
    selected = Array.from(elms).map((x) => x.value);
    var data = { form: selected };
    getFilter(data);
}

  function Paginate() {
 
  return (
    <Form as="div" onClick={scrollToTop}>
      <PaginationControl
        page={page}
        between={process.env.REACT_APP_PAGINATION_BETWEEN}
        total={totalItem}
        limit={process.env.REACT_APP_PAGINATION}
        changePage={(page) => {
          setPage(page);
          load(page);
        }}
        ellipsis={2}
      />
    </Form>
  );
}

const scrollToTop = () => {
  scroll('container-avail')
};

const getFilter = async (data) => {
 const Req = config.request.filter;
  Req.data.filter = data;
  const res = await send(Req); 
  if (res?.data?.status == true) {
     setTotalItem(parseFloat(res?.data?.result.count))
     setCountRequest(parseFloat(res?.data?.result.countRequest))  
     makePaginate(1)
  } 
   
};


const load = async (page,display='block') => {
  document.getElementById("loader").style.display = display; 
  const Req = config.request.paginate;
  Req.urlAppend = [config.searchToken, page];
  const res = await send(Req);
  if (res?.data?.status == true) {
    result.avails=res?.data?.result
     setPrinter(res?.data?.result)
   
  }

  document.getElementById("loader").style.display = "none";
};

const setPrinter = async (avails) => { 
  if (!rootPaginate) {
    const elem = document.getElementById("availability-container");
    rootPaginate = createRoot(elem);
    }
    rootPaginate.render(<Print items={avails}/>);

}

const availPrice = async () => { 
 
startPrice=1 
const res = await send(config.request.availprice); 

 
if (res?.data?.status == true) {
  setTotalItem(parseFloat(res?.data?.result.count));
  setCountRequest(parseFloat(res?.data?.result.countRequest)) 
  makePaginate(1)
 
  if(countRequest>0){
    availPrice()
  }
}

}


const makePaginate = async (page) => { 
  setPage(page);
  if (!rootPaginateControl) {
  const elem = document.getElementById("paginate-control");
  rootPaginateControl = createRoot(elem);
  }
  rootPaginateControl.render(<Paginate/>);
  load(page,'none');
}