import { ChevronFirst, ChevronLast, ChevronLeft, ChevronRight } from "lucide-react";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";

const Pagination = ({ data, itemsPerPage, renderItems }) => {

    const buttonsToShow = 3;

    const [currentPage, setCurrentPage] = useState(0);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const displayedItems = data.slice(startIndex, endIndex);

    const pageCount = Math.ceil(data.length / itemsPerPage);


    let startPage = currentPage - Math.floor(buttonsToShow / 2);
    startPage = Math.max(startPage, 0);
    let endPage = startPage + buttonsToShow;
    endPage = Math.min(endPage, pageCount);


    const paginationButtons = [];
    for (let i = startPage; i < endPage; i++) {
        paginationButtons.push(
            <button style={i === currentPage ? { background: '#3B71FE', color: "white", width: "40px", height: "40px" } : { background: 'white', width: "40px", height: "40px" }}
                key={i}
                onClick={() => handlePageChange(i)}
                className={i === currentPage ? 'active btn rounded d-flex justify-content-center align-items-center' : 'btn btn-white border rounded d-flex justify-content-center align-items-center'}
            >
                {i + 1}
            </button>
        );
    }

    const goToPrevPage = () => {
        if (currentPage > 0) {
            handlePageChange(currentPage - 1);
        }
    };

    const goToFirstPage = () => {
        handlePageChange(0);
    };

    const goToLastPage = () => {
        handlePageChange(pageCount - 1);
    };

    const goToNextPage = () => {
        if (currentPage < pageCount - 1) {
            handlePageChange(currentPage + 1);
        }
    };


    return (
        <>
            {renderItems(displayedItems)}

            <Row className="d-flex justify-content-center my-5">
                <Col className="d-flex justify-content-between" xl="6">
                    <button className='btn border d-flex align-items-center justify-content-center rounded' onClick={goToFirstPage} disabled={currentPage === 0}>
                        <ChevronFirst size={15} />
                    </button>
                    <button disabled={currentPage === 0} className='btn border rounded d-flex align-items-center justify-content-center' onClick={goToPrevPage}><ChevronLeft size={15} /></button>
                    {paginationButtons}
                    <button className='btn border rounded d-flex align-items-center justify-content-center' onClick={goToNextPage} disabled={currentPage === pageCount - 1}><ChevronRight size={15} /></button>
                    <button className='btn border d-flex align-items-center justify-content-center rounded' onClick={goToLastPage} disabled={currentPage === pageCount - 1}>
                        <ChevronLast size={15} />
                    </button>
                </Col>
            </Row>
        </>
    )
}

export default Pagination