import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
const IController = ({elment, item,control,errors,register}) => {
    var className = ""

    if(errors[elment]){
        className =  " form-control-invalid  is-invalid"  
     }

     // 
    return (
        <Controller
            name={elment}
            id={elment}
            placeholder={item?.placeholder}
            className={item?.className+className}
            control={control}
            rules={item?.rules && item.rules}
            render={({ field }) => (
                <Form.Control
                id={elment}
                    className={item?.className+className}
                    placeholder={item?.placeholder}
                    type={item?.type}
                    size={item?.size && item.size}
                    {...field}
                    {...register(elment)} 
                     


                />
            )}
        />

    );
}

export default IController