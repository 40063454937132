import { send } from "../../global/axios";
export function configSearch(dataPost,searchToken,t){
    const config = {
        dataPost: dataPost,
        service: 'activity',
        searchToken: searchToken,
        messageFilter: t('no_activity_matches_the_criteria_selected_filters'),
        request: {
            list: { service: 'activity', action: "list", data: {},urlAppend:[searchToken,1] },
            filter: { service: 'activity', action: "filter", data: { filter: [], searchToken: searchToken } },
            paginate: { service: 'activity', action: "paginate", data: {} ,urlAppend:[searchToken,1]} ,
       },
        toolBarItems: [
            { id: "prices_activity", name: t('price'), fiter_name: 'PRICES_ORDER' } 
        ],
        filterItems: [          
            { id: "name", title: t('name'), action: 'Standard' }, 
           { id: "type", title: t('type'), action: 'Standard' }
        ]
    }

    return  config;
}
export const  getList = async (searchToken)=>{
return await send({ service: 'activity', action: "list", data: {},urlAppend:[searchToken,1] });
}
export const  getAvailability = async (dataPost)=>{
return await send({ service: "activity", action: "load", data: { dataPost: dataPost} });
}

export const  getCategory = async (activityCode,searchToken)=>{
 return await send({ service: "activity", action: "category", data: {}, urlAppend: [activityCode, searchToken]  });
}
  export const  getdetail = async (activityCode,searchToken,rateKey)=>{
    return await send({ service: "activity", action: "detail", data: {}, urlAppend: [activityCode,searchToken,rateKey]  });
   }
   


 export  const addToPanier = async (data,activityCode,searchToken,rateKey) => {
    const resp= await send({ service: 'activity', action: "prebooking", data: {dataBook:data}, urlAppend:[ activityCode,searchToken,rateKey] });        
  if (resp?.data?.status == true && resp?.data?.result?.preBooking !='' && resp?.data?.result?.preBooking !=null) {
    window.location="/shoppingcart"
    }else{
        alert('error not panier')
    }
      
  };