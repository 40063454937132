
import axios from "axios"; 


export const auth = async () => {    
 
    let res = [];
    try {
        res = await http().get(process.env.REACT_APP_API_SERVICE_AUTHIWS_URL , '');
        
    } catch (e) {
        console.log(e);

    }
    return res;
 

}
export function http() {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Content-type": "application/json",
            "sessionToken": `${window.sessionToken}`,
            "currencyCustomer": `${window.currency}`,
            "lang": `${window.lang}`
        },
    });
}
