import { Card, Col, Form, NavDropdown, Row } from "react-bootstrap";
import { Suspense, lazy, useEffect, useState } from "react";
import { ChevronLeft, ChevronRight, Filter, FilterX, Frown, LayoutGrid, LayoutPanelLeft, Map, Minus, SlidersHorizontal, StretchHorizontal } from "lucide-react";
import { useTranslation } from "react-i18next";
import { createRoot } from "react-dom/client";
import PrintAvailability from "./PrintAvailability.js";
import { send } from "../../global/axios";
import { IModal } from "../../global/IModal";
import HomeMap from "../map/Home"
import { PaginationControl } from "react-bootstrap-pagination-control";
import { Panel, RangeSlider } from "rsuite";
import { money, scroll, dateFr, innerComponent } from "../../global/global";
import { useParams } from "react-router-dom";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';


var params, config, result, activeDisplay, setActiveDisplay, totalItem, setTotalItem, rootPaginate, rootPaginateControl, countRequest, setCountRequest, page, setPage
var startPrice = 0

export default function AvailabiltyComponent(props) {
  params = useParams();
  config = props.config
  result = props.result

  const { t } = useTranslation();
  const [show, setShow] = useState("");
  const handleClose = () => setShow("");
  const handleShow = () => setShow("visibility");
  [page, setPage] = useState(1);
  [activeDisplay, setActiveDisplay] = useState("flex");
  [totalItem, setTotalItem] = useState(props.result.count);
  [countRequest, setCountRequest] = useState(result.countRequest);


  //lancer la recherche de nouveau prix
  const startCountHandler = async () => {
    availPrice();
  };

  useEffect(() => {
    (async () => {
      if (countRequest > 0) {
        startCountHandler();
      }
    })();
  }, []);

  return (
    <>
      <Form as="div" id="progress-bar"></Form>
      <Row className="mt-3">
        {
          result?.calendar && (
            <Col xl="12" className="mb-3">
              <Calendar items={result?.calendar} />
            </Col>
          )
        }
        <Col className="filtres-container  d-none d-xl-block overflow-auto" xl="3">
          <ISidebar />
        </Col>
        <Col xl="9">
          <ToolBar handleShow={handleShow} />

          <Form as="div" id="availability-container" className="mt-3">
            <Form as="div" className="availability-container-child">
              <Print items={props.result.avails} />
            </Form>
          </Form>
          <Form as="div" id="paginate-control"> <Paginate /> </Form>
        </Col>
      </Row>
      <SidebarOffCaneva show={show} handleClose={handleClose} />
    </>
  )
}
const handleClick = async (bouton) => {
  setActiveDisplay(bouton)

  setPrinter(result.avails)
}

/**
 * afficher les prix minimum selon la date 
 * elle sera utilisé uniqument par les vols
 * @param {*} param0 
 * @returns 
 */
function Calendar({ items }) {
  let elements = [];
  var indexselected = 0
  Object.keys(items).forEach(function (key, index) {
    elements.push({ price: items[key], date: key });
    if (key == params.year + '-' + params.month + '-' + params.day) {
      indexselected = index
    }
  })

  const [startIndex, setStartIndex] = useState(0);
  const [selectedItemIndex, setSelectedItemIndex] = useState(indexselected);
  const handleNext = () => {
    if (startIndex < elements.length - 1) {
      setStartIndex((prev) => prev + 1);
    }
  };
  const handlePrevious = () => {
    if (startIndex > 0) {
      setStartIndex((prev) => prev - 1);
    }
  };

  const handleItemClick = (index, date) => {
    setSelectedItemIndex(index);
    var url = ["/flights", "search", config.searchToken, dateFr(date)];
    window.history.pushState(null, "Title", url.join("/"));
    window.location.reload()
  };
  const visibleElements = elements.slice(startIndex, startIndex + 6);

  return (
    <Row>
      <Col sm="12" className="px-4">
        <Row className="align-items-center bg-white border-blue rounded-4 p-3">
          <Col xs="2" lg="1">
            <Form as="button" className="text-dark bg-transparent border rounded-circle p-2" onClick={handlePrevious} disabled={startIndex === 0}>
              <ChevronLeft strokeWidth={1.1} size={25} />
            </Form>
          </Col>
          <Col xs="8" lg="10">
            <Row className="justify-content-around align-items-center">
              {visibleElements.map((element, index) => (
                <Col sm="6" md="4" lg="2" className="p-0" role="button" onClick={() => handleItemClick(index, element.date)} key={index}>
                  <Form as="div" className={`w-100 text-center align-items-center fs-6 p-3 ${index === selectedItemIndex ? "bg-blue text-white border-blue rounded-4" : "text-dark"}`}>
                    <Form as="div" className="fw-light text-capitalize">{format(new Date(element.date), "EEE dd MMM yyyy", { locale: fr })}</Form>
                    <Form as="div" className={`fs-6  ${index === selectedItemIndex ? "text-white fw-medium" : "text-blue fw-light"}`}>{money(element.price)} {window.currency}</Form>
                  </Form>
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs="2" lg="1" className="d-flex justify-content-end">
            <Form as="button" className="text-dark bg-transparent border rounded-circle p-2" onClick={handleNext} disabled={startIndex >= elements.length - 6}>
              <ChevronRight strokeWidth={1.1} size={25} />
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>

  )

}

function ToolBar({ handleShow }) {

  const { t } = useTranslation();
  return (
    <>
      <Form as="span" onClick={handleShow} className="d-md-none border p-3 rounded-3">
        <SlidersHorizontal className="me-1" strokeWidth={1} size={15} />
        {t("filter")}
      </Form>

      <Form as="div" className="d-none d-md-flex border bg-white rounded-3 p-2 align-items-center bd-highlight">

        <Form as="span" className="text-blue fw-light fs-6  d-none d-md-block p-3 m-1" >
          {totalItem} {t("results_found")}
        </Form>

        <Form as="div" className="ms-auto d-flex align-items-center">

          <Form as="span" className="text-dark mx-1 fw-light" role="button" onClick={reset}>
            {t("reset")} <FilterX size={15} />
          </Form>

          <Form as="span" title={t("list")} className="mx-1 d-flex fw-light">
            {config.toolBarItems.map((item, key) => (

              <NavDropdown key={'tool-bar-' + key} className="text-dark mx-1" id="nav-dropdown-dark-example" title={<Form as="span" className="text-capitalize mx-2">{item.name}</Form>} menuVariant="light">
                <NavDropdown.Item href="#">
                  <Form as="div" key={"sort-dropdown-1-" + item.id + "-" + key} data-filter={item.fiter_name} data-order="asc" onClick={orderBy}>
                    {t("asc")}
                  </Form>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#">
                  <Form as="div" key={"sort-dropdown-2-" + item.id + "-" + key} data-filter={item.fiter_name} data-order="desc" onClick={orderBy}>
                    {t("desc")}
                  </Form>
                </NavDropdown.Item>
              </NavDropdown>

            ))}
          </Form>

          <Form as="span" title={t("list")} className="mx-1">
            <StretchHorizontal onClick={() => handleClick("flex")} style={{ cursor: "pointer", color: activeDisplay === "flex" ? "#3B71FE" : "" }} size={20} strokeWidth={1.2} className="mx-1" />
          </Form>

          <Form as="span" title={t("grid")} className="mx-1">
            <LayoutGrid onClick={() => handleClick("grid")} style={{ cursor: "pointer", color: activeDisplay === "grid" ? "#3B71FE" : "" }} size={20} strokeWidth={1.2} className="mx-1" />
          </Form>

          <Form as="span" title={t("map")} className="mx-1">
            <LayoutPanelLeft onClick={() => handleClick("map")} style={{ cursor: "pointer", color: activeDisplay === "map" ? "#3B71FE" : "" }} size={20} strokeWidth={1.2} className="mx-1" />
          </Form>

          {config?.isMap == 1 && (
            <Form as="span" title={t("map")} className="mx-1">
              <Map onClick={() => IModal(<HomeMap config={config} />, 'map', "fs", 1)} style={{ cursor: "pointer" }} size={20} strokeWidth={1} className="mx-1" />
            </Form>
          )}
        </Form>
      </Form>
    </>
  )
}

function Print({ items }) {
  if (result.avails.length == 0) {
    return <NoResult />;
  }

  return <PrintAvailability items={items} activeDisplay={activeDisplay} component={config.service} dataPost={config.dataPost} searchToken={config.searchToken} />;
}

function NoResult() {
  const { t } = useTranslation();
  return (
    <Form as="div" className="container p-5 mt-5">
      <Form as="div" className="hero">
        <Form as="div" className="px-4 py-5 my-5 text-center text-dark">
          <Form as="div" className="fs-1 fw-bold">
            <Frown size={50} strokeWidth={1.5} />
            <Form as="span" className="mx-1">{t("no_results")}</Form>
          </Form>
          <Form as="div" className="col-lg-6 mx-auto">
            <Form as="p" className="lead mb-4">{t("no_results_match")}</Form>
            <Form as="div" className="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <Form as="button" onClick={reset} type="button" className="btn-orange px-4 p-3">
                {t("reset")}
              </Form>
            </Form>
          </Form>
        </Form>
      </Form>
    </Form>
  );
}

function SidebarOffCaneva({ show, handleClose }) {
  const { t } = useTranslation();
  return (
    <Card className={"filter-offcaneva  d-lg-none " + show} key={'SidebarOffCaneva'}>
      <Card.Header>
        <Card.Title>
          <Filter size={20} color="#3B71FE" /> {t("filter")}
          <Form as="button" type="button" className="btn-close float-right" onClick={handleClose} aria-label="Close"></Form>
        </Card.Title>
      </Card.Header>
      <Card.Body className="w-100 overflow-auto">{config.filterItems.map((item, key) => <MakeFilter key={'makefilter-a-' + key} item={item} suffix="a_" />)}</Card.Body>
    </Card>
  );
}

function ISidebar() {
  const { t } = useTranslation();
  return (
    <form id="form-filter-availability" name="form-filter-availability" className="bg-white border rounded-3">
      <div className="p-2 px-3 fs-5 fw-medium text-dark border-bottom">{t('filter_per')} :</div>
      <SliderSPrice />
      {
        config.filterItems.map((item, key) => <MakeFilter item={item} suffix="" key={'makefilter-' + key} />)

      }
    </form>
  );
}


function MakeFilter(props) {


  if (result.filter[props.item.id].length == 0) { return }

  const ComponentFilter = lazy(() => import(`./filter/${props.item.action}`));

  return (
    <Suspense >
      {
        <ComponentFilter
          item={result.filter[props.item.id]}
          list={result.filter_list[props.item.id]}
          suffix={props.suffix}
          title={props.item.title}
          id={props.item.id}
          check={check}
        />
      }
    </Suspense>
  )

}

function SliderSPrice() {
  const { t } = useTranslation();
  var items = [];
  Object.keys(result.filter.prices).forEach((key) => {
    items.push(parseFloat(result.filter.prices[key]));
  });
  const min = Math.min(...items);
  const max = Math.max(...items);
  const [PriceValue, setPriceValue] = useState([min, max]);
  const handleSliderChange = (values) => {
    setPriceValue(values);
  };
  const handleSliderChangeComplete = () => {
    var data = { PRICES_SLIDERS: PriceValue };
    getFilter(data);
  };
  return (
    <Form as="div" className="border-bottom">
         <Form as="div" className="p-2 px-3">
            <Form as="div" className="text-dark fw-light fs-6">{t("total_price")}</Form>
          </Form>
        <div className="p-3 py-1">
          <Form as="div" className="my-3">
            <RangeSlider min={min} max={max} defaultValue={[min, max]} constraint={([start, end]) => start <= max && end >= min} onChange={handleSliderChange} onChangeCommitted={handleSliderChangeComplete} />
          </Form>
          <Form as="div" className="d-flex justify-content-between align-items-center py-3">
            <Form as="span" className="bg-light border rounded-3 p-2 w-50">
              <Form as="div">{t("min_price")}</Form>
              <Form as="div">
                {PriceValue[0]} {window.currency}
              </Form>
            </Form>
            <Form as="span" className="mx-2">-</Form>

            <Form as="span" className="bg-light border rounded-3 p-2 w-50">
              <Form as="div">{t("max_price")}</Form>
              <Form as="div">
                {PriceValue[1]} {window.currency}
              </Form>
            </Form>
          </Form>
        </div>
    </Form>
  );
}

const orderBy = async (event) => {
  var data = { ORDERBY: [event.target.dataset.filter, event.target.dataset.order] };
  getFilter(data);
};
const reset = async () => {
  var data = { RESET: "RESET" };
  getFilter(data);
};
const check = async (event) => {
  const id = event.target.id;
  let s = id.split("_");
  if (s[0] == "a") {
    document.getElementById(s[1]).checked = event.target.checked;
  } else {
    document.getElementById("a_" + id).checked = event.target.checked;
  }
  let selected = [];
  var form = document.getElementById("form-filter-availability");
  var elms = form.querySelectorAll('input[type="checkbox"]:checked');
  selected = Array.from(elms).map((x) => x.value);
  var data = { form: selected };
  getFilter(data);
}

function Paginate() {

  return (
    <Form as="div" onClick={scrollToTop}>
      <PaginationControl
        page={page}
        between={process.env.REACT_APP_PAGINATION_BETWEEN}
        total={totalItem}
        limit={process.env.REACT_APP_PAGINATION}
        changePage={(page) => {
          setPage(page);
          load(page);
        }}
        ellipsis={2}
      />
    </Form>
  );
}

const scrollToTop = () => {
  scroll('container-avail')
};

const getFilter = async (data) => {
  const Req = config.request.filter;
  if (config.day) {
    Req.urlAppend = [config.searchToken, params.day, params.month, params.year];
  } else {
    Req.urlAppend = [config.searchToken];
  }





  Req.data.filter = data;
  const res = await send(Req);

  if (res?.data?.status == true) {
    let count = parseFloat(res?.data?.result.count);
    setTotalItem(count)
    setCountRequest(parseFloat(res?.data?.result.countRequest))
    makePaginate(1)

  }

};


const load = async (page, display = 'block') => {
  document.getElementById("loader").style.display = display;

  const Req = config.request.paginate;
  if (config.day) {
    Req.urlAppend = [config.searchToken, params.day, params.month, params.year, page];
  } else {
    Req.urlAppend = [config.searchToken, page];
  }
  const res = await send(Req);
  if (res?.data?.status == true) {
    result.avails = res?.data?.result
    setPrinter(res?.data?.result)
  }

  document.getElementById("loader").style.display = "none";
};

const setPrinter = async (avails) => {


  var containerId = document.getElementById('availability-container')
  containerId.appendChild(innerComponent(<Print items={avails} />, 'availability-container-child'))
  document.getElementById('paginate-control').style.direction = 'block'

}

const availPrice = async () => {
  startPrice = 1
  const res = await send(config.request.availprice);
  if (res?.data?.status == true) {
    setTotalItem(parseFloat(res?.data?.result.count));
    setCountRequest(parseFloat(res?.data?.result.countRequest))
    makePaginate(1)
    if (countRequest > 0) {
      availPrice()
    }
  }

}


const makePaginate = async (page) => {
  setPage(page);
  if (!rootPaginateControl) {
    const elem = document.getElementById("paginate-control");
    rootPaginateControl = createRoot(elem);
  }
  rootPaginateControl.render(<Paginate />);
  load(page, 'none');
}