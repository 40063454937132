import { ChevronRight, PlaneLanding, PlaneTakeoff } from "lucide-react";
import React from "react";
import { Col, OverlayTrigger, Popover, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { time_hour } from "../../../global/global";
import { airline } from "../../../data/airline";
import { getCheckRate } from "../Actions";
import { useParams } from "react-router-dom";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

var searchToken, params;
function Availability(props) {

  const currencyData = [
    {
      values: {
        EUR: "€",
        USD: "$",
        GBP: "£",
        TND: "د.ت",
        DZD: "د.ت",
      }
    }
  ];


  searchToken = props.searchToken;
  params = useParams();
  const composite = {
    flex: <FlexDisplay currencyData={currencyData} props={props} />,
    grid: <FlexDisplay currencyData={currencyData} props={props} />,
  };
  return <>{composite[props.activeDisplay]} </>;
}
export default Availability;

function FlexDisplay({ props, currencyData }) {
  const { t } = useTranslation();



  return (
    <Row className="justify-content-center">
      {props.items.map((flight, index) => (
        <Col key={"flight-" + index} xs="12" className="my-2">
          <Form
            as="div"
            className="rounded-4 border bg-white"
            style={{ overflow: "hidden" }}
          >
            <div className="p-3 border-bottom d-flex justify-content-between align-items-center">
              <div className="fs-6 fw-light"><span className="text-blue fs-3 fw-bold">{flight.TotalAmount}{currencyData[0].values[window.currency]}</span><br/>{t('total_price')} {t('tax_included')}</div>
              <Form
                as="a"
                className="btn-orange text-dark p-3 fw-light px-4 rounded fs-6"
                onClick={() => getCheckRate(flight.RateKey, searchToken, params.day, params.month, params.year)}
                href={`/flights/${searchToken}/${flight.CarrierCode}/${airline(
                  flight.CarrierCode
                )}/${params.day}/${params.month}/${params.year}/${flight.RateKey}`}
                target="_blank"
                rel="noreferrer"
              >
                {t("view_details")} <ChevronRight size={20} strokeWidth={1.5}/>
              </Form>
            </div>

            <Row className="align-items-center">
              <Col md="12" className="p-0 h-100">
                {flight.Itinerary.map((itinerary, index) => (
                  <Itinerary
                    itinerary={itinerary}
                    carrierCode={flight.CarrierCode}
                    key={"itinerary-" + index + "-" + index}
                  />
                ))}
              </Col>
            </Row>
          </Form>
        </Col>
      ))}
    </Row>
  );
}

function GridDisplay({ props }) {
  const { t } = useTranslation();
  return (
    <Row className="mt-3">
      {props.items.map((flight, index) => (
        <Col key={index} sm="6" lg="6" className="my-3">
          <Form
            as="div"
            className="bg-white rounded-4 border"
            style={{ overflow: "hidden" }}
          >
            <Row>
              <Col md="12" className="border-end p-0">

                {flight.Itinerary.map((itinerary, index) => (
                  <Itinerary
                    itinerary={itinerary}
                    carrierCode={flight.CarrierCode}
                  />
                ))}
              </Col>
              <Col md="12">
                <Form
                  as="div"
                  className="d-flex flex-column align-items-center justify-content-center h-100"
                >
                  <Form as="div">
                    {t("from")}:{" "}
                    <Form
                      as="span"
                      className="fw-bold text-dark"
                      style={{ fontSize: "16px" }}
                    >
                      {flight.TotalAmount} {window.currency}
                    </Form>
                  </Form>
                  <Form
                    as="a"
                    className="btn-blue my-3 py-3 px-4 rounded-5 fw-bold text-white"
                    onClick={() => getCheckRate(flight.RateKey, searchToken, params.day, params.month, params.year)}
                    href={`/flights/${searchToken}/${flight.CarrierCode}/${airline(
                      flight.CarrierCode
                    )}/${params.day}/${params.month}/${params.year}/${flight.RateKey}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("view_details")}
                  </Form>
                </Form>
              </Col>
            </Row>
          </Form>
        </Col>
      ))}
    </Row>
  );
}

function Segment({ segment, seg }) {
  if (seg == 0) {
    return;
  }

  return (
    <li key={seg} className="my-1">
      {segment.DepartureAirportCode} - {time_hour(segment.DepartureDateTime)}
    </li>
  );
}

function Itinerary({ itinerary, carrierCode }) {
  const { t } = useTranslation();
  const stops = itinerary["stops"];
  const segment = itinerary["Segments"][0];
  const count_segment = itinerary["Segments"].length;
  const last_segment = itinerary["Segments"][count_segment - 1];
  const reference = itinerary["Ref"];

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h6">{t('escales')}</Popover.Header>
      <Popover.Body>
        <ul>
          {itinerary.Segments.map((segment, seg) => (
            <Segment
              segment={segment}
              seg={seg}
              key={"itinerary-" + itinerary.Ref + "-" + seg}
            />
          ))}
        </ul>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      {reference === 2 && (
        <Form as="hr" className="border-secondary w-100 m-0" />
      )}

      <Row className="py-4 px-5 align-items-center">
        <Col md="1" className="my-1">
          <Form as="div" className={"airline-image airline-" + carrierCode}></Form>
        </Col>

        <Col md="2" className="my-1">
          <Form as="div" className="text-dark fs-6 fw-medium text-capitalize">
            {airline(carrierCode)}
          </Form>

          <Form as="div" className="text-capitalize fw-light">
            {reference === 1 ? t("departure") : t("return")}
          </Form>

          <Form as="div" className="text-capitalize fw-light">
            {segment.FlightNumber}
          </Form>
        </Col>

        <Col md="9">
          <Row className="justify-content-between">
            <Col md="2" className="my-1">
              <Form as="div" className="text-dark fs-6 fw-bold text-capitalize">
                {time_hour(segment.DepartureDateTime)}
              </Form>
              <Form as="div" className="text-capitalize">{format(new Date(segment.DepartureDateTime), "EEE dd MMM yyyy", { locale: fr })}</Form>
            </Col>

            <Col md="6">
              <Row className="justify-content-between">
                <Col md="2" className="my-1" >
                  <PlaneTakeoff size={20} strokeWidth={1.5} />
                </Col>

                <Col md="8" className="d-flex justify-content-center text-center text-dark my-1">

                  {itinerary.Duration}

                </Col>

                <Col md="2" className="my-1 d-flex justify-content-end">
                  <PlaneLanding size={20} strokeWidth={1.5} />
                </Col>

                <Col md="12" className="my-1">
                  <div className="d-flex" style={{ height: "3px" }}>
                    <span className="w-25 h-100 bg-blue"></span>
                    <span className="w-50 h-100 bg-primary-subtle"></span>
                    <span className="w-25 h-100 bg-blue"></span>
                  </div>
                </Col>

                <Col md="2" className="text-secondary my-1" >
                  {segment.DepartureAirportCode}
                </Col>

                <Col mds="8" className="my-1 text-center">
                  {stops > 0 ? (
                    <OverlayTrigger placement="top" overlay={popover}>
                      <Form as="span" role="button" className="mx-1 text-dark">
                        {stops} {stops <= 1 ? t("escale") : t('escales')}
                      </Form>
                    </OverlayTrigger>
                  ) : (
                    <Form as="span" className="mx-1 text-dark">
                      Direct
                    </Form>
                  )}
                </Col>

                <Col lg="2" className="text-secondary my-1 d-flex justify-content-end" >
                  {segment.ArrivalAirportCode}
                </Col>

              </Row>
            </Col>

            <Col md="2" className="d-flex flex-column align-items-end my-1">
              <Form as="div" className="fs-6 fw-bold text-dark">
                {time_hour(last_segment.ArrivalDateTime)}
              </Form>
              <Form as="div" className="text-capitalize text-end">{format(new Date(last_segment.DepartureDateTime), "EEE dd MMM yyyy", { locale: fr })}</Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
