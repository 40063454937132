import { dateFr } from '../../global/global'
import { format } from "date-fns";
const initialStateflightandaccommodationsReducer = {
    date: dateFr(new Date()),
    maxRooms: 3,
    dataPost: {
        "trip": "RT",
        "cabin":"ALL",
        "departure": "",
        "destination": "",      
        "checkin": dateFr(new Date()),
        "checkout": dateFr(new Date()),
        "rooms": [
            {
                "adult": 1,
                "child": 0,
                "childAge": []
            }
        ],
        "itineraries": [
            {
            "Ref": 1,
            "DepartureCountryId": "",
            "DepartureCode": "",
            "DepartureType": "City",
            "ArrivalCountryId": "",
            "ArrivalCode": "",
            "ArrivalType": "City",
            "Date": format(new Date(), 'yyyy-MM-dd')
            },
            {
            "Ref": 2,
            "DepartureCountryId": "",
            "DepartureCode": "",
            "DepartureType": "City",
            "ArrivalCountryId": "",
            "ArrivalCode": "",
            "ArrivalType": "City",
            "Date": format(new Date(), 'yyyy-MM-dd')
            } 
        ],
        "nationality": "DZ",
        "roomsCount": 1,
        "adultCount": 1,
        "childCount": 0
    },
    availability: []

};
const flightandaccommodationsReducer = (state = initialStateflightandaccommodationsReducer, action) => {

    switch (action.type) {  


        case "data_post_flightandaccommodations":
            return {
                ...state,
                dataPost: {
                    ...state?.dataPost,
                    ...action.payload,
                }

            };
            case "add_availability_flightandaccommodations":
                return {
                    ...state,
                    availability: {
                        ...state?.availability,
                        ...action.payload,
                    }
    
                };
            case "set_availability_flightandaccommodations":
                return {
                    ...state,
                    availability: action.payload,
                };
    
            

            case "LOGOUT":
                return initialStateflightandaccommodationsReducer;

        default:
            return state;
    }



};

export default flightandaccommodationsReducer;
