import Home from './Home';
import Settings from './views/Settings'

function Main(props) {
    const composite = {
        home: <Home />,
        settings: <Settings />,
    };
    return (
        <>{composite[props.component]}</>     

    );

}
export default Main;