import { X } from 'lucide-react'
import { Form } from 'react-bootstrap';
import YouTube from 'react-youtube';

const VideoContainer = ({ closeModal }) => {
    const opts = {
        width: "430",
        height: "360",
        playerVars: {
            autoplay: true,
            controls: 0
        },
    };

    const videoId = 'bEAm2PHhvpo';
    return (
        <Form as="div" className="modal">
            <Form as="div" role='button' onClick={closeModal} className='position-absolute' style={{ top: "20px", right: "20px" }}><X size={40} color='white' /></Form>
            <Form as="div" className="d-flex justify-content-center">
                <YouTube videoId={videoId} opts={opts} />
            </Form>
        </Form>
    )
}

export default VideoContainer