import { BiMap } from 'react-icons/bi'
import { LuLandmark, LuMapPin, LuPlane } from 'react-icons/lu';
import { MdOutlineAirplanemodeActive } from 'react-icons/md';
import { PiMapPinFill } from 'react-icons/pi';
import { RiHotelFill, RiHotelLine } from 'react-icons/ri';
function Hotel({item}) {
  var footer = ''


 switch(item?.type) {
    case 'city':
     footer = item.region+','
     break;
    case 'region':
      footer = ''
      break;
    default:
      footer= item.city_name+','+item.region
      break;
  }
 

  return (
    <div className="d-flex align-items-center">
          <div className='pe-2 d-flex justify-content-center'>
          {item?.type === 'hotel' ? <RiHotelLine size={19} /> : item?.type === 'airport' ? <LuPlane size={18} /> : item?.type === "landmark" ? <LuLandmark  size={19}/> :<BiMap  size={23}/>}
          </div>
          <div className="d-flex flex-column pl-2">
            <span className='title-blue text-start fw-bold my-2'>{item?.name}</span>
            <span className='mx-1 fs-13'> {footer}  {item.country_name}</span>
          </div>
        </div>
  )
 
 
     
}

export default Hotel;
