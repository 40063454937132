import ResultAvailability from "../../components/model/ResultAvailability";
import { configSearch, getList } from "./Actions";
import { useSelector } from "react-redux";
import MainForm from "../home/views/MainForm"

function Search() {

  const flight = useSelector((state) => state.flightsIreducer28062024);

  
  return (<ResultAvailability  dataPost={flight.dataPost} forms={<MainForm/>} getList={getList} configSearch={configSearch}/>)
}
export default Search;
