import TopBanner from "../../components/TopBanner";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import ImageItem from "./ImageItem";
import SearchBox from "./SearchBox";
import { send } from "../../global/axios";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { Frown } from "lucide-react";
import { useTranslation } from "react-i18next";
import FaqNav from "./FaqNav";
import QuestionAnswers from "./QuestionAnswers";

const queryClient = new QueryClient();
var categoryId, setCategory, searchQuery, setSearchQuery;

const Home = () => {
  [categoryId, setCategory] = useState(0);
  [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();

  return (
    <>
      <TopBanner data={t("frequently_asked_questions")} title="Faq" />
      <Form as="div" className="container my-5">
        <Row className="justify-content-between">
          <Col lg="3" className="d-none d-lg-block">
            <ImageItem />
          </Col>
          <Col lg="8" className="my-3">
            <SearchBox searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          </Col>
        </Row>
        <QueryClientProvider client={queryClient}>
          <Faq />
        </QueryClientProvider>
      </Form>
    </>
  );
};

function Faq() {
  const { isLoading, error, data } = useQuery("repoData", () => send({ service: "faq", action: "list", data: "", urlAppend: [] }));
  if (isLoading) return "Laoding........";
  if (error) return <FaqError />;

  if (data?.data?.status != true) {
    return <FaqError />;
  }
  const category = data.data.result.category;
  const list = data.data.result.list;

  const handleClick = (id) => {
    setCategory(id);
  };

  const selected = categoryId > 0 ? categoryId : category[0]["id"];

  return (
    <Row className="mt-4 justify-content-between">
      <Col lg="3">
        <FaqNav category={category} handleClick={handleClick} selected={selected} />
      </Col>
      <Col lg="8">
        <QuestionAnswers items={list[selected]} searchQuery={searchQuery} />
      </Col>
    </Row>
  );
}

function FaqError() {
  const { t } = useTranslation();
  return (
    <Form as="div" className="hero">
      <Form as="div" className="px-4 py-5 my-5 text-center text-dark">
        <Form as="div" className="fs-1 fw-bold">
          <Frown size={50} strokeWidth={1.5} />
          <Form as="span" className="mx-1">
            {t("no_results")}
          </Form>
        </Form>
      </Form>
    </Form>
  );
}
export default Home;
