import React from 'react';
import { Col, Row } from 'react-bootstrap';

const calculateAverage = (reviews, key) => {
    const values = reviews.map(review => parseInt(review[key]));
    const total = values.reduce((acc, value) => acc + value, 0);
    return values.length ? (total / values.length) : 0;
};

const RatingBar = ({ label, average, percentage }) => (
    <Col sm="6" md="4" className='my-4'>
        <div className='text-dark d-flex justify-content-between'>
            <span className='fs-6'>{label}</span><span className='fw-light'>{average.toFixed(1)}</span>
        </div>
        <div className='bg-secondary bg-opacity-25 w-100 position-relative mt-3' style={{ height: "5px" }}>
            <div className='bg-blue h-100 position-absolute top-0 left-0' style={{ width: `${percentage}%` }}></div>
        </div>
    </Col>
);

const AverageRatings = ({ hotelReviews, caption, t }) => {
    const ratingKeys = [
        { key: 'rate', label: t('global') },
        { key: 'location', label: t('site') },
        { key: 'cleanliness', label: t('cleanliness') },
        { key: 'staff_and_service', label: t('staff_and_service') },
        { key: 'conditions_of_establishment', label: t('conditions_of_establishment') },
        { key: 'rate', label: t('comfort') },
        { key: 'rate', label: t('facilities') },
    ];

    const averages = ratingKeys.map(({ key }) => calculateAverage(hotelReviews, key));
    const overallAverage = averages.reduce((acc, avg) => acc + avg, 0) / ratingKeys.length;

    return (
        <Row className='align-items-center justify-content-between'>
            <Col md="4" xl="3" className='my-3'>
                <div className="p-5 d-flex flex-column align-items-center bg-primary bg-opacity-10">
                    <div className='text-blue fw-bold' style={{ fontSize: "600%" }}>{overallAverage.toFixed(1)}</div>
                    <div className='fs-4 text-dark'>{caption[parseInt(overallAverage.toFixed(1))]}</div>
                    <div>{hotelReviews.length} {t('reviews')}</div>
                </div>
            </Col>
            <Col md="8" xl="9" className='my-3'>
                <Row>
                    {ratingKeys.map(({ key, label }, index) => (
                        <RatingBar
                            key={key}
                            label={label}
                            average={averages[index]}
                            percentage={(averages[index] / 5) * 100}
                        />
                    ))}
                </Row>
            </Col>
        </Row>
    );
};

export default AverageRatings;
