import { Row, Col, Form, FloatingLabel } from "react-bootstrap";
import IController from "../../components/forms/IController";
import SelectForm from "../../components/forms/SelectForm";
import { useTranslation } from "react-i18next";
import BookingContactForm from "../../components/model/BookingContactForm";
var errors, register, control;

const BookingForm = ({ paxes, ctrl }) => {
  errors = ctrl.errors;
  control = ctrl.control;
  register = ctrl.register;
  if (paxes.length==0) {
    return;
  }
  return (
    <>
      {paxes.map((paxe, index) => (
        <PaxForm paxe={paxe} index={index}  key={index}/>
      ))}


 
      <BookingContactForm ctrl={ctrl} />
    </>
  );
};

export default BookingForm;

 
function PaxForm({ paxe,index }) {
  const { t } = useTranslation();
  var item = {
    type: "text",
    className: "input-blue w-100 input-passenger border rounded",
    size: "md",
    rules: {
      required: t("mandatory"),
    },
  };
  return (
    <Form as="div"  className="my-3" key={index}>
    <Form as="div"  className="fs-6 text-blue text-decoration-underline" htmlFor="genre">
      {t(paxe.Type)} {index + 1}
    </Form> 
    <Row>
      <Col lg="6" className="my-2">
        <label className="fs-6 fw-light" htmlFor={paxe.PaxId + `.Gender`}>
          {t("gender")} <Form as="span"  className="text-danger">*</Form> 
        </label>
        <Form as="div"  className="mt-1">
          <SelectForm data={paxe.Gender} title="gender" elment={paxe.PaxId + `.Gender`} register={register} />
        </Form> 
      </Col>

      <Col lg="6" className="my-2">
        <label className="fs-6 fw-light" htmlFor={paxe.PaxId + `.Title`}>
          {t("title")} <Form as="span"  className="text-danger">*</Form> 
        </label>
        <Form as="div"  className="mt-1">
          <SelectForm data={paxe.Title} title="title" elment={paxe.PaxId + `.Title`} register={register} />
        </Form> 
      </Col>

      <Col lg="6" className="my-2">
        <label className="fs-6 fw-light" htmlFor={paxe.PaxId + `.LastName`}>
          {t("last_name")} <Form as="span"  className="text-danger">*</Form> 
        </label>
        <Form as="div"  className="mt-1">
          <IController elment={paxe.PaxId + `.LastName`} item={item} control={control} errors={errors} register={register}  />
          <Error index={paxe.PaxId} attr="LastName" title="last_name" />
        </Form> 
      </Col>

      <Col lg="6" className="my-2">
        <label className="fs-6 fw-light" htmlFor={paxe.PaxId + `.FirstName`}>
          {t("first_name")} <Form as="span"  className="text-danger">*</Form> 
        </label>
        <Form as="div"  className="mt-1">
          <IController elment={paxe.PaxId + `.FirstName`} item={item} control={control} errors={errors} register={register}  />
          <Error index={paxe.PaxId} attr="FirstName" title="first_name" />
        </Form> 
      </Col>
    </Row>
  </Form> 
  );
}

function Error({ index, attr, title }) {
  const { t } = useTranslation();
  var txt = "";
  var c = "";
  if (errors[index]) {
    var e = errors[index];
    if (e[attr]) {
      txt = t(title) + " : " + t(e[attr]["message"]);
      c = " show";
    }
  }

  return (
    <Form as="span" className={"p-2 invalid-feedback" + c}>
      {txt}
    </Form>
  );
}
