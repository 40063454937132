import React from "react";
import { Star } from "lucide-react";
import { Col, Row, Placeholder, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { getReviews } from "../Actions";
import AverageRatings from "./AverageRatings";

const queryClient = new QueryClient()


const Reviews = ({ hotelid }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReviewsLoad hotelid={hotelid} />
    </QueryClientProvider>
  )

}

function LoadError() {

}
const ReviewsLoad = ({ hotelid }) => {
  const { t } = useTranslation();

  const { isLoading, error, data } = useQuery('repoData', () => getReviews(hotelid))
  if (isLoading) return <PalceReviewsHolder />;
  if (error) return <LoadError />
  if (data?.data?.status != true) {
    return <LoadError />
  }
  var hotelReviews = data?.data?.result

  const caption = {
    1: "Moyen",
    2: "Agréable",
    3: "Bien",
    4: "Trés bien",
    5: "Fabuleux"
  }

  return (
    <div className="my-3">

      <h2 className="text-dark fs-4 fw-medium">{t("customer_reviews")}</h2>
      <AverageRatings hotelReviews={hotelReviews} caption={caption} t={t} />
      <Row className="mt-4 justify-content-between">
        <Col lg="8" xl="7" className="my-3">
          {hotelReviews.map((comment, index) => (
            <Form as="div" key={index} className="my-4 px-3 px-sm-0">
              <Row className="justify-content-between align-items-center">
                <Col xs="6" sm="4">
                  <Form as="div" className="d-flex align-items-center">
                    <img alt={`Illustration d'un utilisateur`} className="rounded-circle" width={60} height={60} src="https://modmixmap.travelerwp.com/wp-content/uploads/2022/04/32.jpg" />
                    <div className="mx-3">
                      <Form as="div" className="fs-5 fw-medium text-dark">{comment.name}</Form>
                      <Form as="p" className="fw-light">{comment.date}</Form>
                    </div>
                  </Form>
                </Col>
                <Col xs="4" sm="3" className="text-center text-blue">
                  <div className="text-blue fs-5 fw-light">{caption[comment.rate]}</div>
                  <div className="fs-6"><IStar count={comment.rate} /></div>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <Form as="p" className="fs-6 my-3 fw-light">{comment.message}</Form>
                </Col>
              </Row>
            </Form>
          ))}
        </Col>
        <Col lg="4" className="my-3 d-none d-lg-block">
          <Form as="div" className="p-4 rounded-4 border d-flex flex-column align-items-center my-2" style={{ overflow: "hidden" }}>
            <img alt={`Illustration d'un utilisateur`} className="rounded-circle" width={90} height={90} src="https://modmixmap.travelerwp.com/wp-content/uploads/2022/04/32.jpg" />
            <Form as="div" className="fs-4 fw-medium text-dark mx-3 my-2">
              John doe
            </Form>
            <Form as="p" className="fs-6 text-center">
              {t("write_review_caption")}
            </Form>
            <button className="btn-blue fw-light fs-6 p-3 rounded-5 mt-3" type="button">
              {t("write_a_review")}
            </button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Reviews;

function IStar({ count }) {
  return (
    <>
      {Array.from({ length: count }, (_, index) => (
        <Star className="me-1" size={15} color="#F8D448" fill="#F8D448" key={index} />
      ))}

      {Array.from({ length: 5 - count }, (_, index) => (
        <Star className="me-1" size={15} color="#ddd" fill="#ddd" key={index} />
      ))}
    </>
  );
}

function PalceReviewsHolder() {
  return (
    <Row className="mt-2">
      <Col className="p-3" lg="12" md="12" key="room-place-holder-1">
        <Placeholder as="div" animation="wave" style={{ height: "200px" }}>
          <Placeholder xs={12} style={{ height: "200px" }} />
        </Placeholder>
        <Placeholder className="text-dark" as="div" animation="wave">
          <Placeholder xs={12} />
        </Placeholder>
        <Placeholder className="text-dark" as="div" animation="wave">
          <Placeholder xs={12} />
        </Placeholder>
        <Placeholder className="text-dark" as="div" animation="wave">
          <Placeholder xs={12} />
        </Placeholder>
      </Col>
    </Row>
  );
}

