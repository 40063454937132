import { Row, Col, Form } from 'react-bootstrap';
import logo from '../../assets/404.svg'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
function ErrorPage() {
    const { t } = useTranslation();
    return (
        <Form as="section" >
            <Row className='py-5'>
                <Col md="12">
                    <Form as="div" className='d-flex justify-content-center align-items-center'>
                        <img style={{ maxWidth: '30%' }} src={logo} alt="logo du site" loading="lazy" />
                    </Form>
                </Col>
                <Col md="12">
                    <Form as="div" className='d-flex flex-column align-items-center justify-content-center'>
                        <Link to='/'><button className='btn-blue p-4 rounded-5 text-decoration-none'>{t('back_to_home')}</button></Link>
                    </Form>
                </Col>
            </Row>
        </Form>

    );
}

export default ErrorPage;