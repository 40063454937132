import { Check } from "lucide-react";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Inclus({ items }) {
    const { t } = useTranslation();
    if (items.length == 0) {
        return;
    }

    return (
        <Form as="div" className="my-3">
            {items.map((item, index) => (

                <div className="d-flex align-items-center fs-6 fw-light my-2">
                    <span className="text-green"><Check size={20} /></span>
                    <Form as="p" className="mx-3"
                        dangerouslySetInnerHTML={{
                            __html: item.Value.replaceAll("//", "<br/>"),
                        }}
                    ></Form>
                </div>
            ))}
        </Form>
    );
}

export default Inclus;
