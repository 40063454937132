import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CheckCircle2, Lock, MessageSquare } from "lucide-react";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { getGenerateFormPayment } from "../Actions";
import { money } from "../../../global/global";
const queryClient = new QueryClient()
function PaymentForm({ ids }) {
    const { t } = useTranslation();
    return (
        <Form as="div" className="p-4">
            <QueryClientProvider client={queryClient}>
                <LoadFormPayment ids={ids} t={t} />
            </QueryClientProvider>

            <Form as="div" className="border mt-3 p-3 rounded-3 d-flex flex-column text-dark">
                <Form as="div" className="fs-6 fw-medium">{t('why_book_with_us')}</Form>
                <Form as="div">
                    <Form as="div" className="d-flex align-items-center my-3">
                        <Lock color="#3B71FE" size={20} />
                        <Form as="span" className="mx-2">{t('secure_payment')}</Form>
                    </Form>
                    <Form as="div" className="d-flex align-items-center my-3">
                        <CheckCircle2 color="#3B71FE" size={20} />
                        <Form as="span" className="mx-2">{t('no_hidden_costs')}</Form>
                    </Form>
                    <Form as="div" className="d-flex align-items-center my-3">
                        <MessageSquare color="#3B71FE" size={20} />
                        <Form as="span" className="mx-2">{t('24_7_customer_support_worldwide')}</Form>
                    </Form>
                </Form>
            </Form>

        </Form>

    );

}

export default PaymentForm;

function LoadFormPayment({ ids, t }) {
    const { isLoading, error, data } = useQuery('repoData', () => getGenerateFormPayment(ids))
    if (isLoading) return <LoadingOrError message="Loading....." />;
    if (error) return <LoadingOrError message="Error" />

    if (data?.data?.status != true) return <LoadingOrError message="Error" />
    var items = data.data.result
    const colTripList = {
        '1': 12,
        '2': 6,
        '3': 4
    }

    let count = items.length

    return (
        <Row  >
            {items.map((item, key) => (
                <Col md={colTripList[count]} className="p-3" key={key}>
                    <Form as="div" className="card">
                        <Form as="div" className="card-body">
                            <Form as="h5" className="card-title">{t('title_to_proced_payment_' + item.currency)}</Form>
                            <Form as="p" className="card-text p-2">
                                <Form as="span" className="price-payment">{money(item.total)} </Form>
                                {item.currency}
                            </Form>

                            <Form action={item.paymentData.endpoint} method={item.paymentData.method}  >
                                {item.paymentData.data.length > 0 ? <GenerateForm data={item.paymentData.data} /> : ''}
                                <Form as="button" type="submit" className="btn btn-primary">{t('proceed_to_payment')}</Form>
                            </Form>
                        </Form>
                    </Form>
                </Col>
            ))}

        </Row>
    )
}
function GenerateForm({ data }) {

    return (
        <>
            {data.map((item, key) => (
                <input type="hidden" value={item.value} name={item.name} />

            ))}
        </>
    )

}
function LoadingOrError({ message }) {
    return (
        <Form as="div" className="container p-2">
            <Form as="div" className="hero">
                <Form as="div" className="px-4 py-5 my-5 text-center">
                    <h1 className="display-5 fw-bold">{message}</h1>
                </Form>
            </Form>
        </Form>
    )
} 