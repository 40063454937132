import { AsyncTypeahead } from "react-bootstrap-typeahead"; 
import Home from '../viewAutocomplete/Home'

const InputAutocomplete = ({ id, label, placeholder, icon, value, filterBy, isLoading, handleSearch, handleClick, options, selected, className,views }) => {
  return (
    <AsyncTypeahead
      filterBy={filterBy}
      id={id}
      isLoading={isLoading}
      labelKey="destination"
      minLength={3}
      onSearch={handleSearch}
      onChange={handleClick}
      clearButton={false}
      options={options}
      placeholder={placeholder}
      defaultInputValue={value}
      className={className}
      positionFixed={true}
      useCache={true}
      renderMenuItemChildren={(option: result) => (
        <Home item={option} views={views}/>
      )}
    />
  );
}; 
export default InputAutocomplete;
