
import axios from "axios"; 
export function send(request) {
let  service=request.service.toUpperCase()
let  action=request.action.toUpperCase()
let  pro = process.env
let  url =pro[`REACT_APP_API_SERVICE_${service}_URL_${action}`]
let  method =pro[`REACT_APP_API_SERVICE_${service}_METHOD_${action}`] 
var res;
    if (request.urlAppend) {
        if (request.urlAppend.length>0) {
            url += '/' + request.urlAppend.join('/');
        }
    } 
 
    switch (method) {
        case 'get':
            res = _get(url, request.data);
            break;
            case 'delete':
                res = _delete(url, request.data);
                break;
        case 'post':
            res = _post(url, request.data);
            break;
        default:

            break;
    }

    return res;

}
export function http() {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Content-type": "application/json",
            "sessionToken": `${window.sessionToken}`,
            "currencyCustomer": `${window.currency}`,
            "lang": `${window.lang}`
        },
    });
}
export const _post = async (url, data) => {
    let res = [];
    try {
        res = await http().post(url, data);
    } catch (e) {
        console.log(e);

    }
    return res;
}
export const _put = async (url, data) => {
    let res = [];
    try {
        res = await http().put(url, data);
    } catch (e) {
        console.log(e);
    }
    return res;
}
export const _get = async (url, param = "") => {
    let res = [];
    try {
        res = await http().get(url, { params: param });
    } catch (e) {
        console.log(e);

    }
    return res;
}
export const _delete= async (url, param = "") => {

    let res = [];
    try {
        res = await http().delete(url, { params: param });
    } catch (e) {
        console.log(e);

    }
    return res;
}

export const getOptions = async (url) => {
    const items = [];
    try {
        const res = await http().get(url);
        res?.data?.data?.map((item) => {
            items.push({ value: item.id, label: item.name });
        });

    } catch (e) {
        console.log(e);
    }

    return items;

};

export const autocomplete = async (text, url) => {
    try {
        const res = await http().get(url + "?search=" + text);
        const data = res?.data?.data?.map((item) => {
            return { ...item, value: item.id, label: item.name };
        });
        return data;
    } catch (e) {
        return [];
    }
};