import { Row, Col } from 'react-bootstrap'
import data from '../../data/blogdata.json'
import Pagination from '../../components/Pagination';

const GridDisplay = () => {

    const renderSpecificItems = (items) => {

        return (
            <>
                <Row className='mt-3'>
                    {items.map((article, index) => (
                        <Col key={index} md="6" className="position-relative my-3">
                            <a href={`/blog/${article.id}`} className="text-dark text-decoration-none">
                                <img className="rounded-4" width="100%" height="100%" alt="article illustration" src={article.img} style={{ maxHeight: "310px" }} />
                                <div className="my-3">
                                    <div className="fs-6">{article.date}</div>
                                    <div className="fs-5 fw-medium title-blue">{article.name}</div>
                                </div>
                                <span className="position-absolute top-0 start-0 m-4 bg-white p-2 px-3 rounded-5">{article.type}</span>
                            </a>
                        </Col>
                    ))}
                </Row>
            </>
        );
    };

    return (
        <>
            <Pagination data={data.articles} itemsPerPage={9} renderItems={renderSpecificItems} />
        </>
    )
}

export default GridDisplay