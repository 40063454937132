import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const addDefaultImg = (ev) => {
  ev.target.src = process.env.REACT_APP_IMAGE_DEFAULT;
};
function TopDestinations({ items }) {

  const { t } = useTranslation();
  const xls = [{ value: 4 }, { value: 4 }, { value: 4 }, { value: 4 }, { value: 4 }, { value: 4 }];
  if(items.length===0){return}
   

  const getImage = (index) => {
    let item = items[index];
    return item?.image;
  };

  const getCity = (index) => {
    let item = items[index];
    return item?.city?.fr;
  };
  const getNumberOfHotel = (index) => {
    let item = items[index];
    return item?.numberOfHotel;
  };

  return (
    <Form as="section" className="container-md top-destination py-5">
      <h1 className="text-dark text-center fw-medium fs-1">{t("top_destination")}</h1>
      <Row className="justify-content-between mt-3">
        {xls.map((xl, index) => (
          <Col key={index} sm="6" lg="4" className="my-3" xl={xl.value}>
            <Form as="div" className="top-destination-item rounded-4 h-100" style={{ overflow: "hidden", position: "relative" }}>
              <img className="top-destination-img" alt={getCity(index)} style={{ maxHeight: "300px", filter: "brightness(80%)" }} width="100%" height="100%" src={getImage(index)} onError={addDefaultImg} />
              <Form as="div" className="text-white w-100 h-100 d-flex flex-column justify-content-center align-items-center" style={{ position: "absolute", bottom: "0", left: "0" }}>
                <Form as="div" className="fs-2 fw-medium text-capitalize" style={{ fontWeight: "400" }}>
                  {getCity(index)}
                </Form>
                <Form as="div" className="fw-light fs-6">
                  {getNumberOfHotel(index)} {t("hotels")}
                </Form>
              </Form>
            </Form>
          </Col>
        ))}
      </Row>
    </Form>
  );
}

export default TopDestinations;
