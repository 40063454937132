import React, { useState } from 'react'
import TopBanner from '../../components/TopBanner'
import { useParams } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
 
import Loader from "../../components/Loader";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { addToPanier, getdetail } from "./Actions";
import OrderSummary from './views/OrderSummary';
import BookingForm from './BookingForm';
import CancelPolicy from '../../components/model/CancelPolicy';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ShoppingCart } from 'lucide-react';
const queryClient = new QueryClient();

const Book = () => {

  return (
    <QueryClientProvider client={queryClient}>
      <LoadDetails  />
      </QueryClientProvider>
 )

}

const LoadDetails = () => {
  const searchToken = useParams().searchToken;
  const rateKey = useParams().rateKey;
  const [isBook, setIsBook] = useState(false);   
  const { t } = useTranslation();

  
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({});


  const { isLoading, error, data } = useQuery("repoData", () => getdetail(searchToken,rateKey)); 
  if (isLoading) return <Loader />;
  if (error) return <LoadError />;
 
  if (data?.data?.status != true) {
  return <LoadError />;
    }
    
    const items =  data?.data?.result;
    const onSubmit = async (data) => {
      await addToPanier(data,searchToken,rateKey)
     
    };
    const ctrl = {
      control: control,
      register: register,
      errors: errors,
    };
    return(
      <Form as="section" >
        <TopBanner title={t('booking')} data={t('booking')} cover="" />     
        <Form as="div" className="container-xl p-3">
          <Row className="my-3">
            <Col lg="8">
            <fieldset>
        <Form onSubmit={handleSubmit(onSubmit)}>
        <BookingForm paxes={items.paxes}  ctrl={ctrl}  />
        <CancelPolicy items={items.cancellationPolicy} />
        <Form as="div" className="mb-3">
                <Button className="btn-blue p-3 px-4 rounded-5 mt-3 fs-6 text-white" type="submit">
                  <ShoppingCart /> {t("add_to_panier")}
                </Button>
              </Form>      
        </Form>
        </fieldset> 

            </Col>
            <Col lg="4">
            <OrderSummary items={items}/>
           
            </Col>
          </Row>

        </Form>
      </Form>
    )
}

function LoadError() {



}
export default Book


