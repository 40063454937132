import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { MapPin, AlarmClock } from "lucide-react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { IModal } from "../../../global/IModal";
import Category from "./Category";
import { PiHeartLight } from "react-icons/pi";
import { currencySymbol } from '../../../global/global'

const addDefaultImg = ev => {
  ev.target.src = process.env.REACT_APP_IMAGE_DEFAULT
}
function Availability(props) {
  const images = [
    "https://images.pexels.com/photos/271624/pexels-photo-271624.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/164595/pexels-photo-164595.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/271618/pexels-photo-271618.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/172872/pexels-photo-172872.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/2736388/pexels-photo-2736388.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/271619/pexels-photo-271619.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/271643/pexels-photo-271643.jpeg?auto=compress&cs=tinysrgb&w=600",
    "",
    "https://images.pexels.com/photos/2873951/pexels-photo-2873951.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/262048/pexels-photo-262048.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/3659683/pexels-photo-3659683.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/3201763/pexels-photo-3201763.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/3634741/pexels-photo-3634741.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/3144580/pexels-photo-3144580.jpeg?auto=compress&cs=tinysrgb&w=600",
    "",
    "https://images.pexels.com/photos/2029698/pexels-photo-2029698.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/3688261/pexels-photo-3688261.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/1454806/pexels-photo-1454806.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/6186819/pexels-photo-6186819.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/6480198/pexels-photo-6480198.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/7061675/pexels-photo-7061675.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/6588569/pexels-photo-6588569.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/6480201/pexels-photo-6480201.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/3659681/pexels-photo-3659681.jpeg?auto=compress&cs=tinysrgb&w=600",
  ];

  const [currentImage, setCurrentImage] = useState("");

  const selectRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    const randomImage = images[randomIndex];
    setCurrentImage(randomImage);
  };

  useEffect(() => {
    selectRandomImage();
  }, []);

  const composite = {
    flex: <FlexDisplay images={images} props={props} />,
    grid: <GridDisplay images={images} props={props} />,
  };
  return <>{eval("composite." + props.activeDisplay)} </>;
}
export default Availability;
function FlexDisplay({ props, images }) {
  const { t } = useTranslation();
  return (
    <div className="mt-3">
      {props.items.map((activity, index) => (

        <div role="button" className="border bg-white h-100 rounded-4 my-3" onClick={() =>
          IModal(
            <Category
              activityCode={activity.ActivityCode}
              searchToken={props.searchToken}
            />,
            activity.Name,
            "fs",
            0
          )
        }>
          <Row>
            <Col sm="4">
              <div className="h-100">
                <img className="rounded-start-3" src={activity.Image} onError={addDefaultImg} alt={`Illustration de l'activité - ${activity.Name}`} width="100%" height="100%" />
              </div>
            </Col>
            <Col sm="8">
              <div className="ps-2 py-3 p-4">
                <div className="d-flex justify-content-between align-items-center">
                  <Form as="span" className="d-flex align-items-center bg-secondary bg-opacity-10 p-1 rounded">
                    <span className="text-blue"><MapPin strokeWidth={1.5} size={15} /></span>
                    <Form as="span" className="mx-1 text-capitalize">
                      {activity.City}, {activity.Country}
                    </Form>
                  </Form>
                  <span className="text-danger"><PiHeartLight size={20} /></span>
                </div>
                <div className="title-blue fs-5 fw-medium my-2">{activity.Name}</div>
                <Form as="div" className="d-flex align-items-center">
                  <span className="text-blue"><AlarmClock strokeWidth={1.5} size={20} /></span>
                  <Form as="span" className="mx-1 text-capitalize fw-light text-secondary">
                    0{activity.Duration}
                  </Form>
                </Form>
                <Form as="div">
                  <Form as="div" className="mt-2 fs-6 fw-light">
                    {parse(activity.Description.slice(0, 100))} ...
                  </Form>
                </Form>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <span className="fs-5 fw-light text-dark">{t('from')} {activity.Amount}{currencySymbol(activity.Currency)}</span>
                  <span className="btn-outline-blue p-3 rounded-5">{t('view_details')}</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
}

function GridDisplay({ props, images }) {
  const { t } = useTranslation();
  return (
    <Row className="mt-3">
      {props.items.map((activity, index) => (
        <Col key={index} sm="6" lg="4" className="my-3">
          <div role="button" className="border bg-white h-100 rounded-4" onClick={() =>
            IModal(
              <Category
                activityCode={activity.ActivityCode}
                searchToken={props.searchToken}
              />,
              activity.Name,
              "fs",
              0
            )
          }>
            <img className="rounded-top-3" src={activity.Image} onError={addDefaultImg} alt={`Illustration de l'activité - ${activity.Name}`} width="100%" height="190" />
            <div className="p-3 d-flex flex-column justify-content-between">
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <Form as="span" className="d-flex align-items-center bg-secondary bg-opacity-10 p-1 rounded">
                    <span className="text-blue"><MapPin strokeWidth={1.5} size={15} /></span>
                    <Form as="span" className="mx-1 text-capitalize">
                      {activity.City}, {activity.Country}
                    </Form>
                  </Form>
                  <span className="text-danger"><PiHeartLight size={20} /></span>
                </div>
                <div className="title-blue fs-6 fw-medium my-3">{activity.Name}</div>
                <Form as="div" className="d-flex align-items-center">
                  <span className="text-blue"><AlarmClock strokeWidth={1.5} size={20} /></span>
                  <Form as="span" className="mx-1 text-capitalize fw-light text-secondary">
                    0{activity.Duration}
                  </Form>
                </Form>

              </div>

              <div className="d-flex justify-content-between align-items-center mt-2">
                <span className="fs-3 fw-light text-dark">{activity.Amount}{currencySymbol(activity.Currency)}</span>
                <span className="btn-outline-blue p-3 rounded-5">{t('view_details')}</span>
              </div>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
}
