import ResultAvailability from "../../components/model/ResultAvailability";
import { configSearch, getList } from "./Actions";
import { useSelector } from "react-redux";
import MainForm from "../home/views/MainForm";
 
function Search() {
  const hotel = useSelector((state) => state.hotelIdhtours28062024);
 
  return (<ResultAvailability  dataPost={hotel.dataPost} forms={<MainForm/>} getList={getList} configSearch={configSearch}/>)
}
export default Search;
