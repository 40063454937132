import Search from './Search' 
import Details from './Details' 
import Book from './Book'
function Main(props) {
    const composite = {
        search: <Search />,
        details: <Details />,
        book: <Book />
        
    };
    return (
        <>{composite[props.component]}</>     

    );

}
export default Main;