import { DatePicker } from "antd";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;
const DateTimeInput = (props) => {
  const handleDateChange = (dates) => {
    if(  props.onDateChange){
      props.onDateChange(dates);    
    }
  };
  const dateFormat = !props.format?'DD/MM/YYYY HH:mm':props.format;

  if(props?.range==false){

    return <DatePicker 
    showTime 
    className="border input-blue p-2 rounded-0 w-100" 
    style={{ height: "1.5cm" }} format={dateFormat}
     onChange={handleDateChange}
     defaultValue={[dayjs(props.checkin, dateFormat)]}
      placeholder={[props.leftLabel]} showSecond={false} />;
 

  }



  return <RangePicker 
  showTime 
  className="border input-blue p-2 rounded-0 w-100" 
  style={{ height: "1.5cm" }} format={dateFormat}
   onChange={handleDateChange}
   defaultValue={[dayjs(props.checkin, dateFormat), dayjs(props.checkout, dateFormat)]}
    placeholder={[props.leftLabel, props.rightLabel]} showSecond={false} />;
};

export default DateTimeInput;
