import { X } from "lucide-react";
import React from "react";
import { Form } from "react-bootstrap";

function Excluded({ items }) {
    if (items.length == 0) {
        return;
    }

    return (
        <Form as="div" className="my-3">
            {items.map((item, index) => (

                <div key={index} className="d-flex align-items-center fs-6 fw-light my-2">
                    <span className="text-danger"><X size={20} /></span>
                    <Form as="p" className="mx-3"
                        dangerouslySetInnerHTML={{
                            __html: item.Value.replaceAll("//", "<br/>"),
                        }}
                    ></Form>
                </div>
            ))}
        </Form>
    );
}

export default Excluded;
