import {Form } from 'react-bootstrap';
import { Frown } from 'lucide-react';
import TopBanner from '../../components/TopBanner';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
    function Failed() {
        const { t } = useTranslation();

      
        return (
            <Form as="section" >
                <TopBanner data={t("failed")}  title={t("payment")} cover="" />
                <Form as="div" className="container">
              <Form as="div" className="hero">
                <Form as="div" className="px-4 py-5 my-5 text-center text-dark">
                  <Form as="div" className="fs-1 fw-bold">
                    <Frown size={50} strokeWidth={1.5} />
                    <Form as="span" className="mx-1">{t("no_results")}</Form>
                  </Form>
                  <Form as="div" className="col-lg-6 mx-auto">
                    <Form as="p" className="lead mb-4">{t("PAYMENT_FAILED")}</Form>
                    <Form as="div" className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                      <NavLink className="btn-orange px-4 p-3" to="/shoppingcart">
                        {t("reset")}
                      </NavLink>
                    </Form>
                  </Form>
                </Form>
              </Form>
            </Form>
            </Form>
    
        );
    }
    
    export default Failed;

 
