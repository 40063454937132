import { Check } from "lucide-react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Descriptions from "./Descriptions";
import { Steps } from 'antd';

function Trip({ item }) {
  const { t } = useTranslation();

  return (
    <div className="px-3">
      <Row className="mt-3">
        <Col lg="3">
          <Form as="div">
            <img
              alt={item.From}
              src={item.Image}
              width="100%"
              height="100%"
            />
          </Form>
        </Col>
        <Col lg="9" className="my-3">
          <>

            <div className="my-3">
              <div className="fs-4 text-dark fw-medium">{item.Type}</div>
              <p>{item.VehicleType} - {item.VehicleCategory} - {item.VehicleName}</p>
            </div>
            <Steps
              progressDot
              direction="vertical"
              current={1}
              items={[
                {
                  title: item.From,
                  description: <div className="fw-light text-secondary">{item.Time}</div>,
                },
                {
                  title: item.To,
                  description: <div className="fw-light text-secondary">{item.Date}</div>,
                }
              ]}
            />
            {item.CheckPickup.Url != '' ?
              <>
                <Form as="p" className="mt-3">
                  {t('don_t_forget_to_visit_hours_before_your_departure').replace("@hours@", item.CheckPickup.HoursBeforeConsulting)}
                </Form>
                <Form as="a" href={`https://${item.CheckPickup.Url}`} target="blank">{item.CheckPickup.Url}</Form>

              </>
              : ''}
          </>
        </Col>
      </Row>
      {item?.TravelCode?.Code && (
        <Form as="div" className="d-flex align-items-center fs-6 fw-light text-dark my-2">
          <Form as="b" className="me-2">{t('travel_number')}</Form>
          <Form as="span" className="mx-2">{item.TravelCode.Code}</Form>
        </Form>
      )}

      <Form as="div">
        {item.TransferInfo.map((info, index) => (
          <Form as="div" className="my-3 fs-6 fw-light" key={index}>
            <span className="text-green"><Check size={20} strokeWidth={1.5} /></span><span className="mx-2">{info.Value}</span>
          </Form>
        ))}
      </Form>

      <div className="mt-3">
        <Descriptions items={item.TransferRemarks} title={t('description')} />
        <Descriptions items={item.SupplierTransferInfo} title={t('supplierTransferInfo')} />
        <Descriptions items={item.CustomerTransferTimeInfo} title={t('sustomerTransferTimeInfo')} />
      </div>
    </div>


  )


}

export default Trip;