import React, { useState } from "react";
import { Plus } from "lucide-react";
import { Modal, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {country} from "../../../data/country"; 
import InputTypeahead from "../../../components/forms/InputTypeahead";
import { config } from "../../../configs/config";
function Guests({ showGuests, handleCloseGuests, showbtnadd,cabin, addRoom, rooming, roomsCount, handleNationalitySelect }) {
  const [nationalitySelections, setNationalitySelections] = useState(country());
  const { t } = useTranslation();

  return (
    <>
      <Modal show={showGuests} onHide={handleCloseGuests} backdrop="static" keyboard={false}>
        <Form as="div"  className="bg-white text-dark rounded-4">
          <Modal.Header closeButton>
            <Modal.Title>
              <Form as="div"  className="d-flex align-items-center">
                <Form as="span" >
                  {t("total_rooms")} : {roomsCount}
                </Form> 
              </Form> 
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form as="div"  className="p-3">
              <Form as="div"  className="mb-3">
              <label class="fw-bold fs-6"> {t("nationality")}</label>
              <InputTypeahead id="nationality_id" labelKey="name" placeholder={t("select_your_country")} options={nationalitySelections} handleClick={handleNationalitySelect} size="lg" className="rbt-input-border" />
              </Form> 
              <Form as="div"  className="mb-3">
                   
                   <select  id={'class_travelers'} defaultValue={cabin}   className='input-blue w-100 p-3 border rounded'>
                   {
                   config("flightClass").map((option,index)=>(
                   <option value={option.code} key={index}>{t(option.title)}</option>
                   ))
                   }
                   </select>


                 
                 </Form> 

              <Form as="div"  className="my-3">{rooming()}</Form> 
            </Form> 
          </Modal.Body>
          <Modal.Footer>
            <Form as="div"  className="mx-3">
              {showbtnadd && (
                <Form as="div"  className="border rounded-5 text-center p-3 px-4 " style={{ cursor: "pointer" }} onClick={addRoom}>
                  <Form as="p" lus size={20} /> {t("add_room")}
                </Form> 
              )}
            </Form> 
            <button className="btn-blue p-3 px-4 rounded-5" onClick={handleCloseGuests}>
              {t("save_changes")}
            </button>
          </Modal.Footer>
        </Form> 
      </Modal>
    </>
  );
}

export default Guests;
