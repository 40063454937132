import BlogDetails from '../../data/blogdata.json'
import { useParams } from "react-router-dom";
import { Row, Col } from 'react-bootstrap'
import Filters from './Filters'
import TopBanner from '../../components/TopBanner'

function Details() {

    const { articletId } = useParams();
    let article = BlogDetails.articles.filter(objet => objet.id === parseInt(articletId))[0];

    return (
        <section>
            <TopBanner data={article.name} cover="https://demoapus1.com/tourio/wp-content/uploads/2023/08/breadcrumbs.jpg" />
            <div className='container'>
                <Row className='my-5'>
                    <Col lg="8">
                        <div>
                            <img alt={`Illusatrtion de l'article - ${article.name}`} className='rounded-4' width="100%" height="100%" src={article.cover} />
                        </div>

                        <div className='my-3 d-flex justify-content-start'>
                            <div className='border p-2 px-3 rounded-5'>
                                {article.type}
                            </div>
                        </div>
                        <h3 className='fs-1 fw-bold text-dark'>{article.name}</h3>
                        <p className='fs-6 mt-3'>{article.description}</p>
                        <div className='my-5'>
                            <div><span className='fs-5 fw-bold text-dark'>Details</span> | <span>{article.date}</span></div>
                            <p className='fs-6 mt-3' dangerouslySetInnerHTML={{ __html: article.details }}></p>
                        </div>
                    </Col>
                    <Col lg="4">
                        <Filters />
                    </Col>
                </Row>
            </div>
        </section>

    );

}

export default Details;
