import React, { lazy, Suspense } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TopBanner from "../../components/TopBanner";

function Home() {
  const { t } = useTranslation();

  const Information = lazy(() => import(`./views/${window.lang == null ? process.env.REACT_APP_LANG : window.lang}`));
  return (
    <Form as="section">
      <TopBanner title={t("terms_and_conditions")} data={t("terms_and_conditions")} cover="" />
      <Form as="div" className="container py-5">
        <Suspense fallback={<Form as="div">Page is Loading...</Form>}>{<Information />}</Suspense>
      </Form>
    </Form>
  );
}

export default Home;
