import { createStore } from "redux";
import rootReducer from "./rootReducer";
import { loadState, saveState } from "./stateLoader";
import { persistStore } from "redux-persist";
export const store = createStore(
    rootReducer,
    loadState(),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(() => {
    saveState(store.getState());
});
export const persistor = persistStore(store);
