import { Col, Form, Row } from "react-bootstrap";
import Input from "../../../components/forms/Input";
import Select from "../../../components/forms/Select";
import Textarea from "../../../components/forms/Textarea";
import { useTranslation } from "react-i18next";

const WriteReview = () => {
  const { t } = useTranslation();

  const createOptions = (label) => [
    { title: t(label), value: "" },
    { title: "1", value: 1 },
    { title: "2", value: 2 },
    { title: "3", value: 3 },
    { title: "4", value: 4 },
    { title: "5", value: 5 },
  ];

  const rateOptions = createOptions("note");
  const locationOptions = createOptions("location");
  const cleanlinessOptions = createOptions("cleanliness");
  const staffOptions = createOptions("staff_and_service");
  const conditionsOptions = createOptions("conditions_of_establishment");
  const comfortOptions = createOptions("comfort");
  const facilitiesOptions = createOptions("facilities");

  return (
    <form>
      <fieldset className="my-3">
        <legend className="text-dark fw-bold fs-4">{t("leave_a_review")}</legend>
        <Form as="p" className="fs-6 fw-light">
          {t("review_text")}
        </Form>
        <Row className="my-3">
          <Col sm="6" className="my-2">
            <Input placeholder={`${t("name")} *`} type="text" />
          </Col>

          <Col sm="6" className="my-2">
            <Input placeholder={`${t("email")} *`} type="email" />
          </Col>

          <Col sm="6" md="3" className="my-2">
            <Select data={rateOptions} />
          </Col>

          <Col sm="6" md="3"className="my-2">
            <Select data={locationOptions} />
          </Col>

          <Col sm="6" md="3"className="my-2">
            <Select data={cleanlinessOptions} />
          </Col>

          <Col sm="6" md="3" className="my-2">
            <Select data={staffOptions} />
          </Col>

          <Col sm="6" md="4" className="my-2">
            <Select data={conditionsOptions} />
          </Col>

          <Col sm="6" md="4" className="my-2">
            <Select data={comfortOptions} />
          </Col>

          <Col sm="12" md="4" className="my-2">
            <Select data={facilitiesOptions} />
          </Col>

          <Col xs="12" className="my-2">
            <Textarea rows={7} placeholder={`${t("content")} *`} />
          </Col>
        </Row>
        <button className="btn-blue p-3 px-5 rounded-5 text-white my-3">{t('send')}</button>
      </fieldset>
    </form>
  );
};

export default WriteReview;
