import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MainForm from "./MainForm";
import { Form } from "react-bootstrap";
function Background({ setComponent }) {
  const { t } = useTranslation();

  const data = [
    { title: t("main_title1"), subtitle: t("main_slogan1") },
    { title: t("main_title2"), subtitle: t("main_slogan2") },
    { title: t("main_title3"), subtitle: t("main_slogan3") },
    { title: t("main_title4"), subtitle: t("main_slogan4") },
    { title: t("main_title5"), subtitle: t("main_slogan5") },
    { title: t("main_title8"), subtitle: t("main_slogan8") },
    { title: t("main_title9"), subtitle: t("main_slogan9") },
  ];


  const images = ["https://images.pexels.com/photos/307006/pexels-photo-307006.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", "https://images.pexels.com/photos/1033729/pexels-photo-1033729.jpeg", "https://cdn.pixabay.com/photo/2022/10/04/23/33/mountains-7499281_1280.jpg", "https://images.unsplash.com/photo-1491800943052-1059ce1e1012?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", "https://images.unsplash.com/photo-1501785888041-af3ef285b470?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", "https://images.unsplash.com/photo-1474496517593-015d8b59450d?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D","https://images.unsplash.com/photo-1548678967-f1aec58f6fb2?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"]

  const [randomItem, setRandomItem] = useState(null);

  const [randomImage, setRandomImage] = useState(null);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * data.length);
    const randomImage = Math.floor(Math.random() * images.length);
    setRandomItem(data[randomIndex]);
    setRandomImage(images[randomImage]);
  }, []);

  return (
    <main>
      <img className="video-background" alt="le fond du site" style={{ filter: "brightness(0.7)" }} src={images[3]} width="100%" height="100%" />

      <Form as="div" className="content">
        <Form as="div" className="text-white">
          <Form as="div" className="container d-flex flex-column align-items-center">
            {randomItem && (
              <>
                <Form as="h1" className="text-center" style={{ fontSize: "500%" }}>
                  {/* {randomItem.title} */} {t("main_title9")}
                </Form>
                <Form as="h2" className="fs-5 my-2 text-center fw-light px-5">
                  {/* {randomItem.subtitle} */} {t("main_slogan9")}
                </Form>
              </>
            )}
          </Form>
        </Form>
        <Form as="div" className="w-100 mt-5">
          <MainForm setComponent={setComponent} />
        </Form>
      </Form>
    </main>
  );
}

export default Background;
