import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { send } from "../../../global/axios";
import { CalendarCheck2, Clock } from "lucide-react";
import Category from "./Category";
import { IModal } from "../../../global/IModal";
import { Col, Form, Row } from "react-bootstrap";

function OthersAvailability(props) {
  const { t } = useTranslation();
  const [avaiablity, setAvaiablity] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    (async () => {
      const Req = {
        service: "activity",
        action: "others_activity",
        data: "",
        urlAppend: [props.searchToken, props.activityCode],
      };
      const res = await send(Req);
      if (res?.data?.status == true) {
        setAvaiablity(res.data.result);
      }
      setIsLoad(true);
    })();
  }, []);
  if (isLoad == false) {
    return <LoadingOrError message="Loading....." />;
  }

  return (
    <Form as="div">
      <Form as="div" className="my-3 fs-3 text-dark fw-medium">
        {t('you_might_like')}
      </Form>
      <Row>
        {avaiablity.avails.map((activity, index) => (
          <Col sm="6" md="4" xl="3" className="my-3">
            <Form as="div" key={index} onClick={() => IModal( <Category activityCode={activity.ActivityCode} searchToken={props.searchToken} />, activity.Name, "fs",0 )} className="text-dark bg-white border recommendations-item h-100">
              <img
                alt={` ${activity.Name}`}
                className="recommendations-item-img"
                width="100%"
                height="100%"
                style={{ objectFit: "cover", maxHeight: "200px", minHeight: "200px" }}
                src={activity.Image}
              />
              <Form as="div" className="d-flex flex-column p-3">
              <Form as="div" className="text-uppercase text-secondary fw-light">{activity.Type}</Form>
              <Form as="div"
                className="title-blue fw-medium my-1 fs-6"
              >
                {activity.Name}
              </Form>
              <Form as="div" className="d-flex align-items-center my-2">
                <Clock strokeWidth={1} size={20} />
                <Form as="span" className="mx-1">{activity.Duration}</Form>
              </Form>
              <Form as="div" className="d-flex align-items-center my-2">
                <CalendarCheck2 strokeWidth={1} size={20} />
                <Form as="span" className="mx-1">{activity.OptionAvailable} {t("option_available")}</Form>
              </Form>
              <Form as="div" className="fs-6 fw-medium my-2">
                {t("from")} : {activity.Currency} {activity.Amount}
              </Form>

            </Form>
            </Form>
          </Col>
        ))}
      </Row>
    </Form>
  );
}

export default OthersAvailability;

function LoadingOrError({ message }) {
  return (
    <Form as="div" className="container p-2">
      <Form as="div" className="hero">
        <Form as="div" className="px-4 py-5 my-5 text-center">
          <h1 className="display-5 fw-bold">{message}</h1>
        </Form>
      </Form>
    </Form>
  );
}
