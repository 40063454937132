import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TopBanner from "../../components/TopBanner";
import { Mail, MapPin, PhoneCall } from "lucide-react";

import FormConcat from "./FormConcat";

function Home() {
  const { t } = useTranslation();

  return (
    <Form as="section">
      <TopBanner title={t("contact")} data={t("contact")} cover="" />

      <Form as="div" className="container py-5 my-5">
        <Row>
          <Col lg="6" className="p-5 mt-1">
            <Form as="span" className="h2 text-dark fw-bold">
              {t("contact_information")}
            </Form>
            <Form as="p" className="my-3 fs-5 fw-light">
              {t("contact_text")}
            </Form>
            <Form as="div" className="mt-5 d-flex align-items-center p-2">
              <MapPin strokeWidth={1.3} color="#3B71FE" />
              <Form as="span" className="ms-3">
                {process.env.REACT_APP_CONTACT_ADRESS}
              </Form>
            </Form>
            <Form as="hr" className="w-75 border-secondary" />
            <Form as="div" className="mt-3 d-flex align-items-center p-2">
              <PhoneCall strokeWidth={1.3} color="#3B71FE" />
              <Form as="span" className="ms-3">
                {process.env.REACT_APP_CONTACT_PHONE}
              </Form>
            </Form>
            <Form as="hr" className="w-75 border-secondary" />
            <Form as="div" className="mt-3 d-flex align-items-center p-2">
              <Mail strokeWidth={1.3} color="#3B71FE" />
              <Form as="span" className="ms-3">
                {process.env.REACT_APP_CONTACT_EMAIL}
              </Form>
            </Form>
          </Col>
          <Col lg="6">
            <FormConcat />
          </Col>
        </Row>
      </Form>
    </Form>
  );
}

export default Home;
