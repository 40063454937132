import React from "react";
import { Form } from "react-bootstrap";

function Descriptions({ items, title }) {
  if (items.length == 0) {
    return;
  }
 

  return (
    <>
      <Form as="div"  className="fs-6 fw-light text-dark">{title}</Form> 
      <Form as="div"  className="mt-2">
        {items.map((item, index) => (
          <Form as="p" 
          key={index}
            className="fw-light"
            dangerouslySetInnerHTML={{ __html: item.Value.replace("\n\n",<div className="spacer"></div>)}}
          ></Form> 
        ))}
      </Form> 
    </>
  );
}

export default Descriptions;
