import { DatePicker } from "antd";
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

const DateInput = (props) => {
  const handleDateChange = (dates) => {
    if (props.onDateChange) {
      props.onDateChange(dates);
    }
  };

  const dateFormat = !props.format ? 'DD/MM/YYYY' : props.format;

  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day');
  };

  return (
    <RangePicker
      className="border input-blue p-3 rounded-0 w-100"
      style={{ height: "1.5cm" }}
      format={dateFormat}
      onChange={handleDateChange}
      placeholder={[props.leftLabel, props.rightLabel]}
      defaultValue={[
        props.checkin ? dayjs(props.checkin, dateFormat) : null,
        props.checkout ? dayjs(props.checkout, dateFormat) : null,
      ]}
      disabledDate={disabledDate}
    />
  );
};

export default DateInput;
