
export const setToken = (value) => {
    return {
        type: "TOKEN",
        payload: value,
    };
};

export const setLogedType = (value) => {
    return {
        type: "LOGGEDTYPE",
        payload: value,
    };
};


export const setIsLogged = (value) => {
    return {
        type: "LOGGED",
        payload: value,
    };
};
export const setLang = () => {
    return {
        type: "LANG",
    };
}; 



export const logOut = () => {
    return {
        type: "LOGOUT",
    };
};

export const setCurrency = (value) => {
    return {
        type: "CURRENCY",
        payload: value,
    };
};
export const setLoggedAt = () => {
    return {
        type: "LOGGED_AT",
    };
};