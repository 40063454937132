import {  Frown } from 'lucide-react'
import React from 'react' 
import { useTranslation } from 'react-i18next';
 
const NoAvailability = () => {
    const { t } = useTranslation(); 

    return (
        <div className="container p-5 ">
        <div className="hero">
        <div className="text-center text-dark">
          <div className="fs-1 fw-bold">
            <Frown size={50} strokeWidth={1.5} />
            <span className="mx-1">{t("no_results")}</span>
          </div>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">{t("no_results_match")}</p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
             
            </div>
          </div>
        </div>
      </div>
      </div>
    )
}

export default NoAvailability