import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import Meteo from "../../components/meteo";
import Availability from "./forms/Availability";
import { useTranslation } from "react-i18next";
import OthersAvailability from './views/OthersAvailability'
import Descriptions from './views/Descriptions'
import Loader from "../../components/Loader";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { getdetail } from "./Actions";
import Album from "./views/Album";
import Policy from "./views/Policy";
import Inclus from "./views/Inclus";
import Excluded from "./views/Excluded";
import ActivityNav from './views/ActivityNav'
import Category from "./views/Category";
import DetailsFaq from "../../components/DetailsFaq";

const queryClient = new QueryClient();

function Details() {

  return (
    <QueryClientProvider client={queryClient}>
      <LoadDetails />
    </QueryClientProvider>
  )

}
function LoadError() {

}
function LoadDetails() {

  const myRefs = {
    overview: useRef(null),
    availability: useRef(null),
    included: useRef(null),
    instructions: useRef(null),
    preference: useRef(null),
    faq: useRef(null),
  };

  const activityCode = useParams().activityCode;
  const activityName = useParams().activityName;
  const searchToken = useParams().searchToken;
  const rateKey = useParams().rateKey;
  const [isBook, setIsBook] = useState(false);
  const { t } = useTranslation();
  const { isLoading, error, data } = useQuery("repoData", () => getdetail(activityCode, searchToken, rateKey));

  if (isLoading) return <Loader />;
  if (error) return <LoadError />;

  if (data?.data?.status != true) {
    return <LoadError />;
  }

  const item = data?.data?.result;
  const activity = item.activity

  const onCheckBook = ({ target: { checked } }) => {
    setIsBook(checked);
  };

  const getFormBooking = () => {
    var url = ['/activities', 'book', activity.Name.replaceAll('/', ' '), activity.ActivityCode, searchToken, rateKey]
    window.location = url.join('/')

  }


  return (
    <Form as="section" >
      <div className="bg-dark-blue py-3">
        <Availability />
      </div>
      <Form as="div" className="container-lg py-5">
        <ActivityNav t={t} myRefs={myRefs} />
        <Form as="div" className="d-flex justify-content-between align-items-center my-3">
          <div>

            <h1 className="text-dark text-capitalize fs-2">{activity.Name}</h1>
            <Form as="div" className="text-uppercase text-secondary fw-light">{activity.Type}</Form>
          </div>


          <div className="text-center">
            <Form as="div" className="text-white bg-danger p-2 rounded">
              {t('Likely_to_sell_out')}
            </Form>

            <Form as="div" className="fs-5 fw-medium text-dark mt-1">{item.totalAmount} {window.currency}</Form>
          </div>

        </Form>

        <div id="overview" ref={myRefs.overview}>
          <Album activity={activity} images={item.images} />
        </div>

        <Row className="justify-content-between">
          <Col lg="7" xl="7">
            <Descriptions title={t('about_this_activity')} items={item.descriptions} />
          </Col>

          <Col className="d-none d-lg-block" lg="5" xl="4">
            <Meteo city={activity.City} />
          </Col>
        </Row>

        <hr className="border-secondary my-5" />

        <div id="availability" ref={myRefs.availability}>
          <Category searchToken={searchToken} activityCode={activityCode} getFormBooking={getFormBooking} />
        </div>
        <hr className="border-secondary my-5" />

        <Form as="div" className="my-3" id="included" ref={myRefs.included}>
          <Form as="div"
            className="fs-3 text-dark fw-medium"
          >
            {t('whats_included')}

          </Form>
          <Inclus items={item.extraIncluded} />
          <Excluded items={item.extraExcluded} />
        </Form>
        <hr className="border-secondary my-5" />

        <div id="instructions" ref={myRefs.instructions}>
          <Policy items={item.rateComment} />
        </div>
        <hr className="border-secondary my-5" />

        <Form as="div" id="continue"  >
          <input onChange={onCheckBook} type="checkbox" id="accepte_condition_rate" className="p-3" />
          <label htmlFor="accepte_condition_rate" className="p-3 fs-6 fw-medium text-dark"> {t('accepte_condition_rate')}</label>
          <br />

          {!isBook && (
            <button className="btn-outline-blue px-5 p-3 rounded-5" disabled type="button">{t('reserve')}</button>

          )}


          {isBook && (
            <button className="btn-blue px-5 p-3 rounded-5" onClick={getFormBooking} type="button">{t('reserve')}</button>

          )}

        </Form>

        <hr className="border-secondary my-5" />

        <div id="preference" ref={myRefs.preference}>
        <OthersAvailability activityCode={activityCode} searchToken={searchToken} />
        </div>

     

        <hr className="border-secondary my-5" />

        <div id="faq" ref={myRefs.faq}>
          <DetailsFaq t={t} value="6" />
        </div>

     
      </Form>
    </Form>
  );
}


export default Details;
