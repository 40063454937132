
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { setIsLogged, setToken, logOut, setCurrency,setLoggedAt } from './action'
import {auth} from '../global/authIws'
 
export function Session() {
        const profile = useSelector((state) =>  state.profileIdhtours);
        const dispatch = useDispatch();
     // dispatch(logOut()); 
        if (!profile.isloged) {      
            dispatch(setIsLogged(true));
            dispatch(setToken(uuidv4()));
            dispatch(setCurrency('EUR')); 
         }
        window.currency     = profile.currency
        window.lang         = localStorage.getItem("i18nLng");
        window.logedType    = profile.logedType
        window.sessionToken = profile.sessionToken
        if (window.currency == '') {
        window.currency =process.env.REACT_APP_CURRENCY 
        }    
        if (!window.lang) {
            window.lang = process.env.REACT_APP_LANG
            }   
     /**
     * verification si la session est toujours en cours
     */

     const sessionExpired = () => {
        if (profile?.logged_at) {
            const diffTime = Math.floor((Date.now() - new Date(profile?.logged_at).getTime()) / 60000);
           
            if (diffTime > 60) {
                ////ouvrirr la connextion vers iws
                getAuth(dispatch);            

              }
        }else{
            ////ouvrirr la connextion vers iws
            getAuth(dispatch);
            
        }
    };
    useEffect(() => {
          sessionExpired();
    });
    
} 



const getAuth = async (dispatch) => {
 
    const res = await auth();
    if (res?.data?.status == true) {
        dispatch(setLoggedAt(true));
      }
    

}