import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink } from 'react-router-dom';
import { ChevronRight } from 'lucide-react'
import { useTranslation } from 'react-i18next';
const Aside = ({ show, handleClose,navigate }) => {

    const { t } = useTranslation();
    var items=[];
    Object.entries(navigate).forEach(([key, value]) => {
        items.push( <li key={'rub-asside'+key} className='p-3 border-bottom d-flex justify-content-between align-items-center '><NavLink  key={'rub-asside-link'+key}  to={value['url']}>{t(value['name']).toUpperCase()}</NavLink><ChevronRight strokeWidth={1.5} size={15} /></li>)
            
      });
    return (
        <aside>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body >
                    <div onClick={handleClose}>
                        <nav >
                            <ul className='left-menu-list list-unstyled fs-5 fw-bold'>
                              {items}
                                <li className='p-3 border-bottom d-flex justify-content-between align-items-center'  key={'rub-asside-contact'} ><NavLink key={'rub-asside-link-contact'}  to="/contact">CONTACT</NavLink><ChevronRight strokeWidth={1.5} size={15} /></li>
                                <li className='p-3 border-bottom d-flex justify-content-between align-items-center'  key={'rub-asside-booking'} ><NavLink key={'rub-asside-link-booking'} to="/booking/wishlist">WISHLIST</NavLink><ChevronRight strokeWidth={1.5} size={15} /></li>
                                <li className='p-3 border-bottom d-flex justify-content-between align-items-center'key={'rub-asside-about'} ><NavLink key={'rub-asside-link-about'}  to="/about">ABOUT</NavLink><ChevronRight strokeWidth={1.5} size={15} /></li>
                            </ul>
                        </nav>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </aside>
    )
}

export default Aside