import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Policy({ items }) {
    const { t } = useTranslation();
    if (items.length == 0) {
        return;
    }

    return (
        <Form as="div" className="my-3" >
            <Form as="div"
                className="fs-3 text-dark fw-medium"
            >
                {t('instructions')}
             
            </Form>
            <Form as="div" className="my-3 fs-6 fw-light">
                {items.map((item, index) => (

                    <Form as="p"
                        dangerouslySetInnerHTML={{
                            __html: item.Value.replaceAll("//", "<br/>"),
                        }}
                    ></Form>
                ))}
            </Form>
        </Form>
    );
}

export default Policy;
