import React from "react";
import { Form } from "react-bootstrap";
import Trip from "./Trip";

function Detaills({ transfer }) {
  return <>
    {transfer.Trip.map((trip, index) => (
      <div
        key={index}
        className="p-3 bg-white "

      >
        <Form as="div">
          <Trip item={trip} />
        </Form>
      </div>
    ))}

  </>

}

export default Detaills;
