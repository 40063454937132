import Detail from './Detail'

function Main(props) {
    const composite = {
        detail: <Detail />,
    };

    return (
        <>{composite[props.component]}</>

    );

}
export default Main;