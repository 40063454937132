import { useState } from "react";
import ResultAvailabilityWithMap from "../../components/model/ResultAvailabilityWithMap";
import Availability from "./forms/Availability";
import { configSearch, getList } from "./Actions";
import { useSelector } from "react-redux";
 
function SearchWithMap() { 
  const [component, setComponent] = useState(<></> );
  const hotel = useSelector((state) => state.hotelIdhtours28062024); 
return (
  <ResultAvailabilityWithMap  dataPost={hotel.dataPost} forms={<Availability setComponent={setComponent}/>} getList={getList} configSearch={configSearch}/>
          
)

 }
export default SearchWithMap;
