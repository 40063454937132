import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TopBanner from '../../../components/TopBanner';
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';
import Textarea from '../../../components/forms/Textarea'
import { country } from "../../../data/country";
import { Toggle } from 'rsuite';
import InputTypeahead from '../../../components/forms/InputTypeahead';
import { useState } from 'react';
import { useForm } from "react-hook-form";
import IController from "../../../components/forms/IController";
import { NotificationManager } from 'react-notifications'
import { expEmail, expUperCase, expSpecialChars } from '../../../configs/constants';


import { ArrowRight } from 'lucide-react';
function Suppliers() {
    const { t } = useTranslation();
    const [nationalitySelections, setNationalitySelections] = useState(country());

    const [loading, setLoading] = useState(false);
    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({});

    const onSubmit = async (data) => {
        setLoading(true);
        // const res = await send({ service: "suppliers", action: "rigister", data: {data}, urlAppend: []  });

        /* if (res?.data?.status == true) {
             NotificationManager.success(res?.data?.result.message);
         } */
        NotificationManager.success(t('success_send_mail'))
        setLoading(false);
    }

    var item = {
        type: "text",
        className: "input-blue w-100 input-passenger border rounded",
        size: "md",
        rules: {
            required: t("mandatory"),
        },
    };
    var itemPassword = {
        type: "password",
        className: "input-blue w-100 input-passenger border rounded",
        size: "md",
        rules: {
            required: t('mandatory'),
            minLength: {
                value: 8,
                message: t('the_password_must_have_at_least_8_characters'),
            },
            maxLength: {
                value: 30,
                message: t('the_password_must_not_exceed_30_characters'),
            },

            validate: {
                uppercaseLetters: (value) =>
                    expUperCase.test(value) ||
                    t('must_contain_at_least_one_capital_letter'),
                specialChars: (value) =>
                    expSpecialChars.test(value) ||
                    t('must_contain_at_least_one_special_character'),
            }
        },
    };

    var itemConfirmPassword = {
        type: "password",
        className: "input-blue w-100 input-passenger border rounded",
        size: "md",
        rules: {
            required: t('mandatory'),
            validate: {
                match: (value) =>
                    value === document.getElementById("password").value ||
                    t('passwords_do_not_match'),
            },
        },
    };
    var itemEmail = {
        type: "email",
        className: "input-blue w-100 input-passenger border rounded",
        size: "md",
        rules: {
            required: t('mandatory'),
            pattern: {
                value: expEmail,
                message: t('invalid_format'),
            }
        },
    };
    return (
        <>
            <Form as="section">
                <TopBanner title={t('suppliers')} data={t('register')} cover="" />

                <Form as="div" className='my-5 py- container'>
                    <Form as="div" className='p-3'>
                        <Form as="span" className='fs-2 fw-bold text-dark'>{t('register')}</Form>
                        <Form as="p">{t('enter_your_details_below_to_create_your_account_and_get_started')}</Form>
                    </Form>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col md="4" className='my-3'>

                                <Form as="label" className="fs-6 fw-light"  >
                                    {t("full_name_or_company_name")}
                                </Form>
                                <Form as="div" className="mt-1">

                                    <IController
                                        elment={`full_name`}
                                        item={item}
                                        control={control}
                                        errors={errors}
                                        placeholder={t('full_name_or_company_name')}
                                        register={register}
                                    />


                                    <Form as="small" className="erreur-input invalid-feedback">
                                        {errors?.full_name && errors.full_name.message}
                                    </Form>
                                </Form>
                            </Col>
                            <Col md="4" className='my-3'>
                                <Form as="label" className="fs-6 fw-light"  >
                                    {t("country")}
                                </Form>
                                <Form as="div" className="mt-1">
                                    <InputTypeahead id="nationality_id" labelKey="name" placeholder={""} options={nationalitySelections} handleClick={''} size="lg" className="rbt-input-border" />
                                </Form>
                            </Col>
                            <Col md="4" className='my-3'>
                                <Form as="label" className="fs-6 fw-light"  >
                                    {t("email")}
                                </Form>
                                <Form as="div" className="mt-1">

                                    <IController
                                        elment={`email`}
                                        item={itemEmail}
                                        control={control}
                                        errors={errors}
                                        placeholder={t('email')}
                                        register={register}
                                    />


                                    <Form as="small" className="erreur-input invalid-feedback">
                                        {errors?.email && errors.email.message}
                                    </Form>
                                </Form>

                            </Col>
                        </Row>





                        <Row>
                            <Col xl="6" className='my-3'>
                                <Textarea rows={12} placeholder={t('brief_description_of_you_and_your_business')} />
                            </Col>
                            <Col xl="6">
                                <Row>
                                    <Col sm="6" xl="12" className='my-3'>


                                        <Form as="label" className="fs-6 fw-light"  >
                                            {t("password")}
                                        </Form>
                                        <Form as="div" className="mt-1">

                                            <IController
                                                elment={`password`}
                                                item={itemPassword}
                                                control={control}
                                                errors={errors}
                                                placeholder={t('password')}
                                                register={register}
                                            />


                                            <Form as="small" className="erreur-input invalid-feedback">
                                                {errors?.password && errors.password.message}
                                            </Form>
                                        </Form>
                                    </Col>
                                    <Col sm="6" xl="12" className='my-3'>


                                        <Form as="label" className="fs-6 fw-light"  >
                                            {t("confirm_password")}
                                        </Form>
                                        <Form as="div" className="mt-1">

                                            <IController
                                                elment={`confirm_password`}
                                                item={itemConfirmPassword}
                                                control={control}
                                                errors={errors}
                                                placeholder={t('confirm_password')}
                                                register={register}
                                            />


                                            <Form as="small" className="erreur-input invalid-feedback">
                                                {errors?.confirm_password && errors.confirm_password.message}
                                            </Form>
                                        </Form>
                                    </Col>
                                </Row>
                                <Form as="div" className='d-flex align-items-center'>
                                    <Toggle id='terms' checkedChildren={<CheckIcon />} unCheckedChildren={<CloseIcon />} />
                                    <Form as="label" htmlFor='terms' className='mx-3'>{t('i_accept_terms_and_conditions')}</Form>
                                </Form>


                                <Form as="button" type="submit" className="btn-blue p-3 px-5 rounded-5 mt-3" >
                                    {loading && (
                                        <Form as="span" className="spinner-border spinner-border-sm"></Form>
                                    )}
                                    {t('send')} <ArrowRight className='ms-2' size={20} />
                                </Form>
                            </Col>
                        </Row>

                    </Form>

                </Form>
            </Form>
        </>

    );

}

export default Suppliers;