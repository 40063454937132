import Home from './Home' 
function Main(props) {
    const composite = {
        home: <Home /> 
    };
    return (
        <>{composite[props.component]}</>     

    );

}
export default Main;