import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import Meteo from "../../components/meteo";
import WriteReview from "./forms/WriteReview";
import { useTranslation } from "react-i18next";
import Availability from './forms/Availability'
import Album from "./views/Album";
import Amenities from "./views/Amenities";
import Descriptif from "./views/Descriptif";
import RoomsAvail from "./views/RoomsAvail";
import Reviews from "./views/Reviews";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { getDetail } from "./Actions";
import Loader from "../../components/Loader";
import HotelNav from "./views/HotelNav";
import { MapPin, Star } from "lucide-react";
import Policy from './views/Policy'
import TripAdvisor from "./views/TripAdvisor";
import { IModal } from "../../global/IModal";
import DetailsFaq from '../../components/DetailsFaq'

const queryClient = new QueryClient();

var hotel = [];
var hotelId, hotelName, searchToken;
function Details() {
  hotelId = useParams().hotelId;
  hotelName = useParams().hotelName;
  searchToken = useParams().searchToken;
  return (
    <QueryClientProvider client={queryClient}>
      <DetailHotel />
    </QueryClientProvider>
  );
}
function LoadError() { }
function DetailHotel() {

  const myRefs = {
    overview: useRef(null),
    amenties: useRef(null),
    rooms: useRef(null),
    policy: useRef(null),
    reviews: useRef(null),
    faq:  useRef(null)
  };

  const { t } = useTranslation();
  const [minPrice, setMinPrice] = useState();
  const { isLoading, error, data } = useQuery("repoData", () => getDetail(hotelId));
  if (isLoading) return <Loader />;
  if (error) return <LoadError />;
  if (data?.data?.status != true) {
    return <LoadError />;
  }

  hotel = data.data.result;

  return (
    <Form as="section">
      <div className="bg-dark-blue py-3">
        <Availability />
      </div>
      <div className="container-lg py-5">
        <HotelNav t={t} myRefs={myRefs} />
        <Form as="div" className="d-flex justify-content-between align-items-center my-3">
          <div>
            <div>
              {[...Array(hotel.category)].map((_, index) => (
                <Star size={20} color="#F8D448" fill="#F8D448" />
              ))}
            </div>

            <h1 className="text-dark text-capitalize fs-2">{hotelName}</h1>
            <div className="d-flex align-items-center fs-6 fw-light">
              <span className="text-blue"><MapPin size={20} strokeWidth={1.5} /></span> <p className="mx-1">{hotel.address}</p>
            </div>
          </div>

          <div>
            <Col sm="4" lg="12">
              <Form as="div" role="button" onClick={() => IModal(<TripAdvisor url={hotel.trip_advisor_reviews} />, hotel.name, "fs", 0)}>
                <img width={103} height={14} src={hotel.trip_advisor_rating_image} alt="Trip advisor rating" />
                <Form as="div" className="fw-medium text-dark my-2">
                  {t("excellent")}
                </Form>
                <Form as="div" className="text-blue">
                  ({hotel.trip_advisor_review_count} {t("reviews")})
                </Form>
              </Form>

            </Col>
          </div>
        </Form>

        <div id="overview" ref={myRefs.overview}>
          <Album hotel={hotel} />
        </div>
        <Row className="justify-content-between">
          <Col lg="7" xl="7">
            <Descriptif hotelName={hotelName} descriptions={hotel.descriptions} phone={hotel.phone} />
          </Col>

          <Col className="d-none d-lg-block" lg="5" xl="4">
              <Meteo city={hotel.city} />
          </Col>
        </Row>

        <hr className="my-5 border-secondary" />

        <Form as="div" id="rooms" ref={myRefs.rooms}>
          <RoomsAvail city={hotel.city} amenities={hotel.amenities} images={hotel.images} name={hotel.name} hotelid={hotelId} searchToken={searchToken} setMinPrice={setMinPrice} />
        </Form>

        <hr className="my-5 border-secondary" />

        <div id="amenties" ref={myRefs.amenties}>
          <Amenities list={hotel.amenities} />
        </div>
        <hr className="my-5 border-secondary" />
        <div id="policy" ref={myRefs.policy}>
          <Policy hotel={hotel} />
        </div>
        <hr className="my-5 border-secondary" />


        <div id="reviews" ref={myRefs.reviews}>
          <Reviews hotelid={hotelId} />
        </div>

        <Form as="div" className="my-2">
          <WriteReview />
        </Form>

        <hr className="my-5 border-secondary" />

        <div id="faq" ref={myRefs.faq}>
          <DetailsFaq t={t} value="5" />
        </div>
      </div>
    </Form>
  );
}


export default Details;
