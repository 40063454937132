import { ArrowRight } from "lucide-react";
import React, { useState } from "react";
import { Col, Row, Form, Button, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import IController from "../../../components/forms/IController";
import { getChekRate } from "../Actions";
var list = [];
function TravelNumber({ transfer, searchToken }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [errorCheck, setErrorCheck] = useState('');
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({});

  var item = {
    type: "text",
    className: "input-blue w-100 input-passenger border rounded uppercase",
    size: "md",
    rules: {
      required: t("mandatory"),
    },
  };

  const onSubmit = async (data) => {
    const res = await getChekRate(data, searchToken, transfer.RateKey)


    if (res?.data?.status == true && res?.data?.result?.totalAmount > 0) {
      var url = ["/transfers", "detail", searchToken, transfer.RateKey];
      window.history.pushState(null, "Title", url.join("/"));
      window.location = url.join('/')
    } else {
      setErrorCheck(
        <Alert variant={"danger"}>
          <Form as="p" className="m-2">
            {t('no_update_please_try_again_later')}
          </Form>
        </Alert>
      )
    }

  };

  const tarjet = {
    DEPARTURE: t('departure_transfer'),
    RETURN: t('arrival_transfer'),
  }
  const text = {
    DEPARTURE: t('please_provide_arrival_travel_number'),
    RETURN: t('please_provide_departure_travel_number'),
  }

  return (
    <>
      <Form as="div" className="py-3">
        <Form as="ol">
          <Form as="li"  >{t('please_provide_the_following_informations_informations_required_for_confirmation_of_your_transfer')}</Form>
          <Form as="li"  className="mt-2">{t('if_the_informations_entered_are_incorrect_the_supplier_can_not_in_any_case_be_held_responsible_for_the_provision_of_the_service')}</Form>
        </Form>
      </Form>

      <Form onSubmit={handleSubmit(onSubmit)}>
        {transfer.Trip.map((trip, index) => (
          <Form as="div"
            key={index}
            className="p-3 bg-white "

          >
            <Form as="label" className="fs-6 fw-light"  >
              {text[trip.Type]} <Form as="span" className="text-danger">*</Form>
            </Form>
            <Form as="div" className="mt-1">
              <IController elment={index + `.Code`} item={item} control={control} errors={errors} register={register} />
              <Error index={index} attr="Code" title={text[trip.Type]} errors={errors} />
            </Form>

          </Form>
        ))}

        <Form as="div" className="m-3">
          {errorCheck}
        </Form>

        <Form as="div" className="m-3">



          <Button className="btn-blue p-3 px-4 rounded mt-3 fs-6 text-white w-100" type="submit">

            {loading ? <Form as="span" className="spinner-border spinner-border-sm m-1"></Form> : t("validate")}
          </Button>
        </Form>
      </Form>

    </>
  );
}

export default TravelNumber;
function Error({ index, attr, title, errors }) {
  const { t } = useTranslation();
  var txt = "";
  var c = "";
  if (errors[index]) {
    var e = errors[index];
    if (e[attr]) {
      txt = t(title) + " : " + t(e[attr]["message"]);
      c = " show";
    }
  }

  return (
    <Form as="span" className={"p-2 invalid-feedback" + c}>
      {txt}
    </Form>
  );
}