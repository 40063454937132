import Availability from '../../pages/hotels/views/availability'
import AvailabilityActivity from '../../pages/activities/views/availability'
import AvailabilityFlight from '../../pages/flights/views/availability'
import AvailabilityTransfer from '../../pages/transfers/views/availability'
function PrintAvailability(props) {
    const composite = {
        hotel: <Availability items={props.items} dataPost={props.dataPost} searchToken={props.searchToken} activeDisplay={props.activeDisplay}/>,
        activity: <AvailabilityActivity items={props.items} dataPost={props.dataPost} searchToken={props.searchToken} activeDisplay={props.activeDisplay}/>,
        flight: <AvailabilityFlight items={props.items} dataPost={props.dataPost} searchToken={props.searchToken} activeDisplay={props.activeDisplay}/>,
        transfer: <AvailabilityTransfer items={props.items} dataPost={props.dataPost} searchToken={props.searchToken} activeDisplay={props.activeDisplay}/>,
       
    };
    return (
        <>{composite[props.component]} </>

    );
}
export default PrintAvailability;