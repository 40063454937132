import { Form } from "react-bootstrap";

function FormAvailability(props) {
  return (
    <>
      <Form as="section">

        <Form as="div" className="py-3 bg-dark-blue">
          {props.forms}
        </Form>

        <Form as="div" className="container-xl py-5" id="container-avail">
          <Form as="div" className="container-avail-child">
            {props.component}
          </Form>

        </Form>
      </Form>
    </>
  );
}

export default FormAvailability;
