import { Col, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Recap({ flight }) {

  const { t } = useTranslation();
  
  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Form as="div" className="bg-white rounded-4 border">
      <Row className="align-items-center p-3">
        <Col xs="12">
          <Form as="div" className="d-flex align-items-center">
            <Form
              as="span"
              className={"airline-image airline-" + flight.carrierCode}
            ></Form>
            <Form
              as="span"
              className="fs-6 fw-bold text-dark text-capitalize mx-2"
            >
              {flight.airline} - {flight.carrierCode}
            </Form>
          </Form>
        </Col>
      </Row>
      <Form as="hr" className="my-4 border-secondary" />
      {flight.prices.map((price, index) => (
        <Form
          as="div"
          className="d-flex justify-content-between fs-6 my-3 px-3"
          key={index}
        >
          <Form as="span">
            {t(price.Type)} x {t(price.Quantity)}
          </Form>
          <Form as="span">
            {price.Amount} {window.currency}
          </Form>
        </Form>
      ))}
        <Form as="hr" className="my-4 border-secondary" />

        <Form as="div" className="my-3  px-3">
            <Form as="div" className="d-flex justify-content-between fs-6 my-3">
                <Form as="span">{t("subtotal")}</Form>
                <Form as="span">
                    {flight.totalAmount} {window.currency}
                </Form>
            </Form>
        </Form>

        <Form as="hr" className="my-2 border-secondary" />
        <Form
            as="div"
            className="d-flex justify-content-between fs-5 fw-bold text-dark my-3 px-3"
            >
            <Form as="span">{t("pay_amount")}</Form>
            <Form as="span">
                {flight.totalAmount} {window.currency}
            </Form>
        </Form>


        <Form as="hr" className="my-2 border-secondary" />

        <Form as="div" className="my-3 d-flex justify-content-center">
            <Form
                as="button"
                onClick={() => scrollToSection("continue")}
                className="btn-blue p-3 px-5 rounded-5 text-white"
            >
                {t("reserve")}
            </Form>
        </Form>
    </Form>
  );
}

export default Recap;
