import Flight from './Flight';
import Transfer from './Transfer';
 import Activity from './Activity';
import Hotel from './Hotel';
 
 
function Home({item,views}) {
  const composite = {
    activity: <Activity  item={item} /> , 
    hotel: <Hotel  item={item} />  ,
    flight: <Flight  item={item} /> , 
    transfer: <Transfer  item={item} />  
};
return (
    <>{composite[views]}</>     

);

 
}

export default Home;
