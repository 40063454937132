import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TopBanner from "../../components/TopBanner";
import { Headphones, Play } from "lucide-react";
import VideoContainer from "../../components/VideoContainer";
import teams from "../../assets/teams.webp";
import { BiSupport } from "react-icons/bi";
import { PiPhoneCall } from "react-icons/pi";
import { FaBusinessTime } from "react-icons/fa";
import { PiGlobeHemisphereWestThin } from "react-icons/pi";
import { PiGlobeHemisphereWest } from "react-icons/pi";



function Home() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showModal]);

  const data = [
    { icon: <BiSupport size={25} />, title: t('help_and_support'), content: t('help_and_support_text'), value: process.env.REACT_APP_CONTACT_EMAIL },
    { icon: <PiPhoneCall size={25} />, title: t('call_us'), content: t('call_us_text'), value: process.env.REACT_APP_CONTACT_PHONE },
    { icon: <FaBusinessTime size={25} />, title: t('working_hours'), content: t('working_hours_text'), value: t('working_hours_caption') },
    { icon: <PiGlobeHemisphereWest size={25} />, title: t('global_branch'), content: t('global_branch_text'), value: "@onlinetravelstores" },
  ]

  return (
    <>
      {showModal ? <VideoContainer closeModal={closeModal} /> : ""}
      <Form as="section">
        <TopBanner title={t("about")} data={t("about")} cover="" />
        <Form as="div" className="container py-5">
          <Form as="div" className="position-relative">
            <Form as="img" className="rounded-5" width="100%" height="100%" src={teams} style={{ minHeight: "450px", objectFit: "cover" }} alt={t("seo.")} loading="lazy" />
            <Form as="div" className="w-100 h-100 position-absolute d-flex justify-content-center align-items-center" style={{ top: "0", left: "0" }}>
              <Form as="div" title="Play video" onClick={openModal} role="button" className="p-4 rounded-circle bg-white">
                <Play color="#0D6EFD" fill="#0D6EFD" />
              </Form>
            </Form>
          </Form>
          <Form as="div">
            <Form as="div" className="my-5">
              <Form as="span" className="fs-2 fw-bold text-dark">
                {t("about_us")}
              </Form>
              <Form as="p" className="my-3 fs-6">
                {t("about_text")}
              </Form>
            </Form>
            <Form as="div" className="my-5">
              <Form as="span" className="fs-5 fw-bold text-dark">
                {t("our_mission")}
              </Form>
              <Row>
                <Col lg="6">
                  <Form as="p" className="my-3 fs-6" dangerouslySetInnerHTML={{ __html: t("ourmission_text1") }}></Form>
                </Col>
                <Col lg="6">
                  <Form as="p" className="my-3 fs-6" dangerouslySetInnerHTML={{ __html: t("ourmission_text2") }}></Form>
                </Col>
              </Row>
            </Form>
          </Form>
          <Row>
            {data.map(item => (
              <Col md="6" xl="3" key={item} className="my-3">
                <div className="border p-4 d-flex flex-column align-items-start rounded-4 h-100">
                  <div className="text-blue border-1 rounded border-dark p-2">{item.icon}</div>
                  <div className="text-dark fs-5 fw-medium  mt-4">{item.title}</div>
                  <p className="my-2 fw-light">{item.content}</p>
                  <div className="mt-3 text-dark">{item.value}</div>
                </div>
              </Col>
            ))}
          </Row>
        </Form>

      </Form>
    </>
  );
}

export default Home;
