import { NavDropdown, Nav, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { config } from "../../../configs/config";
import Guests from "./Guests";

const FlightHeader = ({ handleChangeFlightTypeClick, flightTypeActif, ClasseValue, handleClasseValue, adultCount, childCount, infantCount, incrementAdultCount, decrementAdultCount, incrementChildCount, decrementChildCount, incrementInfantCount, decrementInfantCount }) => {
  const { t } = useTranslation();
  const GuestsCount = parseInt(adultCount) + parseInt(childCount) + parseInt(infantCount);
  return (
    <Nav className="d-flex fw-light">
      <NavDropdown
        id="nav-flight-type"
        className="me-3"
        title={
          <Form as="span" className="text-dark me-2">
            {t(flightTypeActif)}
          </Form>
        }
        menuVariant="light">
        {config("flightType").map((item, index) => (
          <NavDropdown.Item onClick={() => handleChangeFlightTypeClick(item)} index={"tf-" + index} key={"tf-" + index}>
            {" "}
            {t(item.title)}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
      <NavDropdown
        id="nav-flight-class"
        className="me-3"
        title={
          <Form as="span" className="text-dark me-2">
            {t(ClasseValue)}
          </Form>
        }
        menuVariant="light">
        {config("flightClass").map((item, index) => (
          <NavDropdown.Item index={"fc-" + index} key={"fc-" + index} onClick={() => handleClasseValue(item)}>
            {" "}
            {t(item.title)}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
      <NavDropdown
        title={
          <Form as="span" className="text-dark me-2">
            {" "}
            {GuestsCount} {t("travelers")}
          </Form>
        }
        className="me-3">
        <Guests adultCount={adultCount} childCount={childCount} infantCount={infantCount} incrementAdultCount={incrementAdultCount} decrementAdultCount={decrementAdultCount} incrementChildCount={incrementChildCount} decrementChildCount={decrementChildCount} incrementInfantCount={incrementInfantCount} decrementInfantCount={decrementInfantCount} />
      </NavDropdown>
    </Nav>
  );
};

export default FlightHeader;
