import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { ShoppingCart, Menu, ChevronDown, User2 } from "lucide-react";
import logo from "../assets/logodark.svg";
import { NavLink } from "react-router-dom";
import Aside from "./Aside";
import { Badge } from "rsuite";
import navigate from "../configs/navigate.json";
import i18n from "../lang/i18n";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Dropdown } from "rsuite";
import { changeLanguage } from "../lang/i18n";
import { send } from "../global/axios";
import { QueryClientProvider, useQuery } from "react-query";
import DropdownSeparator from "rsuite/esm/Dropdown/DropdownSeparator";
import { iqueryClient } from '../global/global';
import Nav from "./Nav";

function Header() {
  const currentLanguage = i18n.language;
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const location = useLocation();

  const handleLanguageChange = (lng) => {
    changeLanguage(lng);
  };

  const lang = [
    { title: "English", country: "Usa", value: "en" },
    { title: "Français", country: "France", value: "fr" },
    { title: "Español", country: "España", value: "es" },
  ];

  const renderIconButton = (props, ref) => {
    return (
      <div {...props} ref={ref} role="button" className="d-flex justify-content-center align-items-center fs-6 fw-light">
        <img src={require(`../assets/flags/${currentLanguage}.png`)} alt={`${currentLanguage} Flag `} />
        <div className="mx-2">{currentLanguage}</div>
      </div>
    );
  };

  const renderOption = (props, ref) => {
    return (
      <div role="button" {...props} ref={ref} className="d-flex align-items-center fw-light">
        {window.currency} <span className="mx-1"><ChevronDown size={15} /></span>
      </div>
    );
  };

  const renderCurrency = (props, ref) => {
    return (
      <div role="button" {...props} ref={ref}>
        <User2 size={20} strokeWidth={1.5} />
      </div>
    );
  };

  const currencies = ["EUR", "USD", "DZD", "TND"]

  return (
    <header className="w-100 bg-white text-dark p-3">
      <div className="container">
        <Row className="align-items-center justify-content-between">
          <Col xs="1" className="d-lg-none">
            <Menu id="left-menu-btn" onClick={handleShow} />
          </Col>

          <Col xs="2" lg="1">
            <div className="d-flex align-items-center">
            <img alt="Logo de idhtours - Site de réservations de voyages en ligne" width="100%" height="100%" src={logo} /><span className="mx-2 text-center fw-light">{t('beta_version')}</span>
            </div>
          </Col>
          <Col lg="8" xl="7" className="d-none d-lg-block">
            <Nav/>
          </Col>

          <Col xs="6" sm="5" md="4" lg="3" xl="2" className="d-none d-sm-block">
            <div className="d-flex align-items-center justify-content-around">
              <Dropdown renderToggle={renderOption} className="mx-2">
                {currencies.map(item => (
                  <div className="py-2 px-5">
                    <div>{item}</div>
                  </div>
                ))}
              </Dropdown>
              <Dropdown renderToggle={renderIconButton} className="mx-2">
                <div className="px-3">
                  {lang.map((lang, index) => (
                    <a key={index} className="d-flex align-items-center p-3 text-dark lang-item rounded" href={location.pathname} onClick={() => handleLanguageChange(lang.value)}>
                      <img src={require(`../assets/flags/${lang.value}.png`)} width="20%" height="20%" alt={`${lang.country} Flag `} />
                      <span className="mx-2 fs-6">{lang.title}</span>
                    </a>
                  ))}
                </div>
              </Dropdown>

              <div className="mx-2">
              <Badge
                content={
                  <QueryClientProvider client={iqueryClient()}>
                    <ShoppingCartCount />
                  </QueryClientProvider>
                }>
                <NavLink to="/shoppingcart" className="text-dark py-1 pe-1">
                  <ShoppingCart size={20} strokeWidth={1.5} />
                </NavLink>
              </Badge>
              </div>

              <Dropdown renderToggle={renderCurrency} className="mx-2">

                <a href="/travel/secure/mytriplogin" className="d-flex align-items-center p-3 text-dark lang-item rounded">
                  <span className="mx-2 fs-6 fw-light">{t('bookings')}</span>
                </a>
                <DropdownSeparator />
                <a href="/travel/settings" className="d-flex align-items-center p-3 text-dark lang-item rounded">
                  <span className="mx-2 fs-6 fw-light">{t('settings')}</span>
                </a>

              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
      <Aside show={show} handleClose={handleClose} handleShow={handleShow} navigate={navigate} />
    </header>
  );
}

export default Header;

function ShoppingCartCount() {
  const { isLoading, error, data } = useQuery("repoData", () => send({ service: "shoppingcart", action: "count", data: {} }));
  if (isLoading) return "0";
  return <>{data?.data?.result?.count}</>;
}
