import React, { useState } from 'react';
import { Row, Col, Offcanvas } from 'react-bootstrap';
import Filters from './Filters'
import GridDisplay from './GridDisplay'
import FlexDisplay from './FlexDisplay'
import { SlidersHorizontal, LayoutGrid, StretchHorizontal } from 'lucide-react'
import BlogItem from '../../data/blogdata.json'
import TopBanner from '../../components/TopBanner'

function Search() {

    const [ActiveDisplay, setActiveDisplay] = useState('grid');

    const handleClick = (bouton) => {
        setActiveDisplay(bouton);
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <section className='bg-body-tertiary'>
                <TopBanner data="Blog" cover="https://demoapus1.com/tourio/wp-content/uploads/2023/08/breadcrumbs.jpg" />

                <div className="container-xl py-5">
                    <Offcanvas className="w-100" show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Filters />
                        </Offcanvas.Body>
                    </Offcanvas>
                    <Row>
                        <Col className='d-none d-xl-block' xl="4">
                            <Filters />
                        </Col>
                        <Col xl="8">
                            <div className='d-flex justify-content-between align-items-center'>
                                <span onClick={handleShow} className='d-xl-none btn btn-light border rounded-5 d-flex align-items-center'><SlidersHorizontal className='me-1' strokeWidth={1} size={15} />
                                    Filters
                                </span>

                                <span style={{ fontSize: "16px" }} className='fw-light d-none d-lg-block fs-6 text-blue'>{BlogItem.articles.length} articles found</span>

                                <div className='d-lg-inline d-none'>
                                    <span title="List view"><StretchHorizontal onClick={() => handleClick('flex')} style={{ cursor: "pointer", color: ActiveDisplay === 'flex' ? '#3B71FE' : '' }} size={20} strokeWidth={1} className="mx-1" /> </span>
                                    <span title="Grid view"><LayoutGrid onClick={() => handleClick('grid')} style={{ cursor: "pointer", color: ActiveDisplay === 'grid' ? '#3B71FE' : '' }} size={20} strokeWidth={1} className="mx-1" /></span>
                                </div>
                            </div>
                            <div>
                                {ActiveDisplay === 'flex' && <FlexDisplay />}
                                {ActiveDisplay === 'grid' && <GridDisplay />}
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>

    );

}

export default Search;