import { BiMap } from 'react-icons/bi'
import { LuPlane } from 'react-icons/lu'
 
function Flight({item}) {
 
    return (
        <div className="d-flex align-items-center">
              <div className='pe-2 d-flex justify-content-center'>
                {item.type==='City'?<BiMap  size={23}/>:<LuPlane  size={23}/>}
              </div>
              <div className="d-flex flex-column pl-2">
                <span className='title-blue text-start fw-bold my-2'>{item?.name}</span>
                <span className='mx-1 fs-13'>  {item.type==='City'?'':item?.city_name+' , '} {item.country_name}</span>
              </div>
            </div>
      )
}

export default Flight;
