import { useState } from "react";
import Login from "./Login";
import Register from "./Register";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";


function LoginRegister() {
    const { t } = useTranslation();
    const [boutonActif, setBoutonActif] = useState('sign');

  

    const handleClick = (bouton) => {
        setBoutonActif(bouton);
    };
    
     var btl = boutonActif === 'sign' ? ' active' : '' 
     var btr = boutonActif === 'register' ? ' active' : ''
    return (
        <Form as="div" className="">
        <Form as="div" className='w-100 d-flex justify-content-center'>
        <Form as="ul" className="nav nav-pills mb-3"  >
        <Form as="li" className="nav-item nav-item2 text-center">
        <Form as="a" className= {`nav-link nav-link2 btl${btl}`}  onClick={() => handleClick('sign')}>{t('login')}</Form>
        </Form>
        <Form as="li" className="nav-item nav-item2 text-center">
        <Form as="a" className= {`nav-link nav-link2 btr${btr}`}    onClick={() => handleClick('register')}  >{t('signup')}</Form>
        </Form>
        </Form>           
        </Form>
        <Form as="div">
        {boutonActif === 'sign' && <Login />}
        {boutonActif === 'register' && <Register />}
        </Form>
        </Form>
      )
      }


 export default LoginRegister