import Home from './Home'
import Voucher from './views/Voucher';

function Main(props) {
    const composite = {
        home: <Home />,
        voucher: <Voucher />,
    };

    return (
        <>{composite[props.component]}</>

    );

}
export default Main;