import { useTranslation } from "react-i18next";
 
import { Alert, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Error } from "../../../global/global";
import IController from "../../../components/forms/IController";
import { useState } from "react";
import { getLogin } from "../Actions";
import { expEmail } from "../../../configs/constants";

 

const Login = () => {
    const { t } = useTranslation();
    const [errorCheck, setErrorCheck] = useState('');
    const [loading, setLoading] = useState(false);
    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
      } = useForm({});
    
      const onLogin = async (data) => {
        setLoading(true)
        const res = await getLogin(data)
       if (res?.data?.status == true  ) {
          var url = ["/account", "account"];
          window.history.pushState(null, "Title", url.join("/"));
            window.location=url.join('/')
        } else {

          
          setErrorCheck(
            <Alert   variant={"danger"}>
              <Form as="p" className="m-2">
                {t('invalid_email_or_password')}
              </Form>
            </Alert>
          )
        } 
        setLoading(false)
    };

    var itemEmail = {
      type: "text",
      placeholder:t('email'),
      className: "input-blue w-100 input-passenger border rounded",
      size: "md",
      rules: {
          required: t('mandatory'),
          pattern: {
              value: expEmail,
              message: t('invalid_format'),
          }
      },
    };

    var item = {
        type: "password",
        placeholder:t('password'),
        className: "input-blue w-100 input-passenger border rounded uppercase",
        size: "md",
        rules: {
          required: t("mandatory"),
        },
      };
      
    return (

        <Form onSubmit={handleSubmit(onLogin)}>
        <Form as="fieldset" className="p-3 d-flex flex-column">
            <Form as="legend" className="fw-bold text-dark">{t('sign_in_to_your_account')}</Form>
            <Form as="div" className="mt-3">
                <Form as="div" className="my-3">
                    
                    <IController elment={`email`} item={itemEmail} control={control} errors={errors} register={register} />
                    <Error index={'email'} attr="email" title='email' errors={errors}/>
                </Form>
                <Form as="div" className="my-3">
              
                    <IController elment={`password`} item={item} control={control} errors={errors} register={register} />
                    <Error index={'password'} attr="password" title='password' errors={errors}/>
                </Form>
            </Form>
            <Form as="button" type="submit" className="btn-blue p-4 rounded-5 fw-bold">
            {loading ? <Form as="span" className="spinner-border spinner-border-sm m-1"></Form> : t('log_in')  }  
                   
             
              
              </Form>
            <Form as="div" className="d-flex align-items-center justify-content-between mt-4">
                <Form as="div" className="d-flex align-items-center">
                    <input id="SignInCheck" type="checkbox" />
                    <Form as="label" htmlFor="SignInCheck" className="mx-3 fs-6 text-secondary">{t('remember_me')}</Form>
                </Form>
                <Form as="a" href="/fortgot-password">{t('fortgot_password')}</Form>
            </Form>

            <Form as="div" className="m-3">
          {errorCheck}
            </Form>
        </Form>
    </Form>
    )
}

export default Login