import React, { useState } from 'react';
import QuestionAnswers from '../pages/faq/QuestionAnswers';
import { useQuery } from 'react-query';
import { send } from '../global/axios';
import { Form } from 'react-bootstrap';
import { Frown } from 'lucide-react';

const Faq = ({ t,value }) => {
    const [searchQuery] = useState("");

    const { isLoading: faqIsLoading, error: faqError, data: faqData } = useQuery("faqData", () =>
        send({ service: "faq", action: "list", data: "", urlAppend: [] })
    );

    if (faqIsLoading) return "Loading........";
    if (faqError) return <FaqError t={t} />;

    if (faqData?.data?.status !== true) {
        return <FaqError t={t} />;
    }

    const list = faqData.data.result.list;

    return (
        <div>
            <div className='fs-3 text-dark fw-medium'>{t('faq_hotel')}</div>
            <div className='my-3'>
                <QuestionAnswers items={list[value]} searchQuery={searchQuery} />
            </div>
        </div>
    );
}

function FaqError({ t }) {
    return (
        <Form as="div" className="hero">
            <Form as="div" className="px-4 py-5 my-5 text-center text-dark">
                <Form as="div" className="fs-1 fw-bold">
                    <Frown size={50} strokeWidth={1.5} />
                    <Form as="span" className="mx-1">
                        {t('no_results')}
                    </Form>
                </Form>
            </Form>
        </Form>
    );
}

export default Faq;
