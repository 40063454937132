import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import OrderSummary from "./views/OrderSummary"; 
import { ShoppingCart } from "lucide-react";
import CancelPolicy from "../../components/model/CancelPolicy";
import BookingForm from './BookingForm' 
import { addToPanier, getdetail } from "./Actions";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import Availability from "./forms/Availability";

const queryClient = new QueryClient();


function Book() {

  return (
    <QueryClientProvider client={queryClient}>
      <LoadDetails  />
      </QueryClientProvider>
 )


}
function LoadError() {



}
function LoadDetails() {
     
    const activityCode = useParams().activityCode;
    const activityName = useParams().activityName; 
    const searchToken = useParams().searchToken;
    const rateKey = useParams().rateKey;
    const [isBook, setIsBook] = useState(false);   
    const { t } = useTranslation();

    const {
      control,
      register,
      formState: { errors },
      handleSubmit,
    } = useForm({});
  


    const { isLoading, error, data } = useQuery("repoData", () => getdetail(activityCode,searchToken,rateKey)); 
    if (isLoading) return <Loader />;
    if (error) return <LoadError />;
 
    if (data?.data?.status != true) {
    return <LoadError />;
      }
 const item =  data?.data?.result;
 const activity = item.activity
      
 const onSubmit = async (data) => {
  await addToPanier(data,activityCode,searchToken,rateKey)
 
};



const ctrl = {
  control: control,
  register: register,
  errors: errors,
};
//
 return (
  <Form as="section" >
      <div className="bg-dark-blue py-3">
        <Availability />
      </div>      
      <Form as="div"  className='my-5 container-xl px-lg-5'>             
        <Row className="justify-content-between">
        <Col md="7" xl="8" className='my-2'>
        <fieldset>
        <Form onSubmit={handleSubmit(onSubmit)}>
        <BookingForm paxes={item.paxes} questions={item.questions} ctrl={ctrl} activityName={activityName} />
        <CancelPolicy items={item.cancellationPolicy} />
        <Form as="div" className="mb-3">
                <Button className="btn-blue p-3 px-4 rounded-5 mt-3 fs-6 text-white" type="submit">
                  <ShoppingCart /> {t("add_to_panier")}
                </Button>
              </Form>      
        </Form>
        </fieldset>         
        </Col>
        <Col md="5" xl="4" className='my-2'>
          <OrderSummary item={item}/>
        </Col>
        </Row>
      </Form>      
    </Form>
  );
}

 
export default Book;
