import Background from "./views/Background";
import Offers from "./views/Offers";
import Reasons from "./views/Reasons";
import { Form } from "react-bootstrap";
import PromoHome from "../promotions/PromoHome";
import { useState } from "react";
import TopDestinations from "./views/TopDestinations";
import Reviews from "./views/Reviews";

function Home() {
  const [component, setComponent] = useState(<PromoLoad />);
  return (
    <Form as="div" className="w-100">
      <Background setComponent={setComponent} />
      <Form as="div" className="bg-body-tertiary" >
        <Form as="div" className="container py-5">
          <Form as="div" id="container-avail">
            <Form as="div" className="container-avail-child">
              {component}
            </Form>
          </Form>
        </Form>
      </Form>

      <div className="bg-white">
        <Reviews />
        <Reasons />
      </div>
    </Form>
  );
}

export default Home;


function PromoLoad() {
  return (
    <>
      <Offers />
      <TopDestinations />
      <PromoHome />
    </>
  )

}
