import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import i18n from "../../../lang/i18n";
import { AvatarGroup, Avatar } from 'rsuite';
import { useTranslation } from "react-i18next";

function RandomUsers() {

    const { t } = useTranslation();
    const [shuffledData, setShuffledData] = useState([]);
    const currentLanguage = i18n.language;

    const data = [
        {
            name: "Kathryn Fuller",
            city: "Berlin, Germany",
            review: {
                fr: "Mon mari et moi utilisons cette agence de voyage depuis des années et ils ne nous ont jamais déçus.",
                en: "My husband and I have been using this travel agency for years and they have never disappointed us.",
                es: "Mi esposo y yo hemos estado usando esta agencia de viajes durante años y nunca nos han decepcionado."
            },
            img: "https://randomuser.me/api/portraits/women/3.jpg"
        },
        {
            name: "Anuradha Rodijk",
            city: "Goudswaard, Netherlands",
            review: {
                fr: "Cette agence a surpassé toutes nos attentes pour notre voyage. Leur attention aux détails a vraiment fait la différence.",
                en: "This agency exceeded all our expectations for our trip. Their attention to detail really made a difference.",
                es: "Esta agencia superó todas nuestras expectativas para nuestro viaje. Su atención al detalle realmente marcó la diferencia."
            },
            img: "https://randomuser.me/api/portraits/women/49.jpg"
        },
        {
            name: "Mathias Dumont",
            city: "Toulouse, France",
            review: {
                fr: "J'ai récemment réservé un voyage en Thaïlande avec cette agence de voyage et je n'aurais pas pu être plus heureux de mon expérience.",
                en: "I recently booked a trip to Thailand with this travel agency and I couldn't have been happier with my experience.",
                es: "Recientemente reservé un viaje a Tailandia con esta agencia de viajes y no podría haber estado más feliz con mi experiencia."
            },
            img: "https://randomuser.me/api/portraits/men/46.jpg"
        },
        {
            name: "Marcos Soto",
            city: "Cincinnati, Spain",
            review: {
                fr: "L'équipe a fourni un service exceptionnel tout au long de notre voyage. Leur professionnalisme a rendu notre expérience très agréable.",
                en: "The team provided exceptional service throughout our trip. Their professionalism made our experience very enjoyable.",
                es: "El equipo brindó un servicio excepcional durante todo nuestro viaje. Su profesionalismo hizo que nuestra experiencia fuera muy agradable."
            },
            img: "https://randomuser.me/api/portraits/men/23.jpg"
        },
        {
            name: "Mahaan soultani",
            city: "Tabriz, Iran",
            review: {
                fr: "Le service était au-delà de nos espérances. Ils ont pris en compte chaque demande et ont personnalisé notre voyage selon nos souhaits.",
                en: "The service was beyond our expectations. They took every request into account and customized our trip according to our wishes.",
                es: "El servicio estuvo más allá de nuestras expectativas. Tomaron en cuenta cada solicitud y personalizaron nuestro viaje según nuestros deseos."
            },
            img: "https://randomuser.me/api/portraits/men/87.jpg"
        },
        {
            name: "Jeton Bourgeois",
            city: "Lavigny, Switzerland",
            review: {
                fr: "Nous avons eu une expérience inoubliable grâce à leurs équipe. Ils ont géré tous les détails avec une grande efficacité.",
                en: "We had an unforgettable experience thanks to this team. They handled all the details with great efficiency.",
                es: "Tuvimos una experiencia inolvidable gracias a esta equipo. Manejarons todos los detalles con gran eficiencia."
            },
            img: "https://randomuser.me/api/portraits/men/88.jpg"
        },
        {
            name: "Ahmet Yorulmaz",
            city: "Elazığ, Turkey",
            review: {
                fr: "Chaque aspect de notre voyage a été soigneusement planifié et exécuté. L'agence a fait preuve d'un excellent service client.",
                en: "Every aspect of our trip was carefully planned and executed. The agency showed excellent customer service.",
                es: "Cada aspecto de nuestro viaje fue cuidadosamente planificado y ejecutado. La agencia mostró un excelente servicio al cliente."
            },
            img: "https://randomuser.me/api/portraits/men/86.jpg"
        },
        {
            name: "Armando Gaytán",
            city: "Elazığ, Turkey",
            review: {
                fr: "Nous avons été ravis de l'itinéraire proposé et des recommandations locales. L'agence a vraiment su répondre à nos besoins.",
                en: "We were delighted with the proposed itinerary and local recommendations. The agency really met our needs.",
                es: "Nos encantó el itinerario propuesto y las recomendaciones locales. La agencia realmente atendió nuestras necesidades."
            },
            img: "https://randomuser.me/api/portraits/men/12.jpg"
        },
        {
            name: "Sean Bradley",
            city: "Mackay, Australia",
            review: {
                fr: "Une expérience de voyage exceptionnelle avec une attention personnalisée. Nous recommandons vivement leurs services.",
                en: "An exceptional travel experience with personalized attention. We highly recommend their services.",
                es: "Una experiencia de viaje excepcional con atención personalizada. Recomendamos encarecidamente sus servicios."
            },
            img: "https://randomuser.me/api/portraits/men/99.jpg"
        },
        {
            name: "Andreas Kaul",
            city: "Frankfurt, Germany",
            review: {
                fr: "Le voyage organisé par cette agence a été sans accroc. Leur expertise a rendu notre voyage très agréable.",
                en: "The trip organized by this agency went off without a hitch. Their expertise made our trip very enjoyable.",
                es: "El viaje organizado por esta agencia salió sin contratiempos. Su experiencia hizo que nuestro viaje fuera muy agradable."
            },
            img: "https://randomuser.me/api/portraits/men/41.jpg"
        },
        {
            name: "Basten Brock",
            city: "Eerde, Netherlands",
            review: {
                fr: "Cette agence a été extrêmement réactive à toutes nos demandes et a assuré un voyage sans stress.",
                en: "This agency was extremely responsive to all our requests and ensured a stress-free trip.",
                es: "Esta agencia fue extremadamente receptiva a todas nuestras solicitudes y aseguró un viaje sin estrés."
            },
            img: "https://randomuser.me/api/portraits/men/63.jpg"
        },
        {
            name: "Léon Bertrand",
            city: "Mulhouse, France",
            review: {
                fr: "Le voyage était parfaitement organisé avec des recommandations locales excellentes. Nous avons eu une expérience fabuleuse.",
                en: "The trip was perfectly organized with excellent local recommendations. We had a fabulous experience.",
                es: "El viaje estuvo perfectamente organizado con excelentes recomendaciones locales. Tuvimos una experiencia fabulosa."
            },
            img: "https://randomuser.me/api/portraits/men/75.jpg"
        },
        {
            name: "Coşkun Çevik",
            city: "Amasya, Turkey",
            review: {
                fr: "Tout au long de notre voyage, l'agence a fourni un service impeccable et a répondu à toutes nos attentes.",
                en: "Throughout our trip, the agency provided impeccable service and met all our expectations.",
                es: "A lo largo de nuestro viaje, la agencia proporcionó un servicio impecable y cumplió con todas nuestras expectativas."
            },
            img: "https://randomuser.me/api/portraits/men/35.jpg"
        },
        {
            name: "Kyle Newman",
            city: "Greensboro, United States",
            review: {
                fr: "Nous avons été très satisfaits de l'organisation de notre voyage. L'agence a fait un excellent travail en répondant à nos besoins.",
                en: "We were very satisfied with the organization of our trip. The agency did an excellent job in meeting our needs.",
                es: "Estamos muy satisfechos con la organización de nuestro viaje. La agencia hizo un excelente trabajo en satisfacer nuestras necesidades."
            },
            img: "https://randomuser.me/api/portraits/men/34.jpg"
        },
        {
            name: "Abigail Lam",
            city: "Delta, Canada",
            review: {
                fr: "Le service était de premier ordre et chaque détail a été pris en compte. Je recommanderais certainement cette agence.",
                en: "The service was top-notch and every detail was taken care of. I would definitely recommend this agency.",
                es: "El servicio fue de primera categoría y cada detalle fue atendido. Definitivamente recomendaría esta agencia."
            },
            img: "https://randomuser.me/api/portraits/women/16.jpg"
        },
        {
            name: "Kira Wang Li",
            city: "Pékin, Chine",
            review: {
                fr: "L'agence a offert une expérience de voyage exceptionnelle avec une attention personnalisée. Leur expertise est inégalée.",
                en: "The agency provided an exceptional travel experience with personalized attention. Their expertise is unmatched.",
                es: "La agencia ofreció una experiencia de viaje excepcional con atención personalizada. Su experiencia es inigualable."
            },
            img: "https://randomuser.me/api/portraits/women/2.jpg"
        }
    ];

    useEffect(() => {
        const shuffled = [...data].sort(() => Math.random() - 0.5);
        setShuffledData(shuffled.slice(0, 2));
    }, []);

    const max = 4;

    return (
        <div className='bg-dark-blue py-5'>
            <div className='container my-5 py-5'>
                <Row className='justify-content-between align-items-center'>
                    <Col lg="6">
                        <AvatarGroup stack>
                            {data
                                .filter((user, i) => i < max)
                                .map(user => (
                                    <Avatar bordered circle key={user.name} src={user.img} alt={user.name} />
                                ))}
                            <Avatar bordered circle style={{ background: '#111' }}>
                                +{data.length - max}
                            </Avatar>
                        </AvatarGroup>
                        <h1 className='fs-1 fw-bold text-dark my-3' dangerouslySetInnerHTML={{ __html: t('reviews_title') }}></h1>
                        <p className='fs-5 my-3'>{t('review_caption')}</p>
                    </Col>
                    <Col lg="2" className='d-none d-lg-block'>
                        <img className='rounded-circle' src="https://travel.nicdark.com/city-tour/wp-content/uploads/sites/3/2023/04/avatar-01.jpg" width="100%" height="100%" alt="hahahaha" />
                    </Col>
                </Row>

                <Row className='mt-5'>
                    {shuffledData.map((user, index) => (
                        <Col key={index} lg="6" className='my-3'>
                            <div className='bg-white border rounded-4 p-5 h-100'>
                                <p className='fs-4 fw-light'>{user.review[currentLanguage]}</p>
                                <div className='d-flex align-items-center mt-3'>
                                    <img src={user.img} alt="User" width="60px" height="60px" className='rounded-circle' />
                                    <span className='fs-6 mx-2'>
                                        <div className='fw-medium text-dark'>{user.name}</div>
                                        <div className='fw-light'>{user.city}</div>
                                    </span>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
}

export default RandomUsers;
